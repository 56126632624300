// @flow
import { getDiscussions, updateDiscussion, querySelector } from 'store/reducers/discussions'
import { connect } from 'react-redux'
import Discussions from 'views/Discussions'
import withPagination from 'shared/decorators/withPagination'
import withSorting from 'shared/decorators/withSorting'
import withSearch from 'shared/decorators/withSearch'
import showSavingErrors from 'utils/showSavingErrors'

import { compose, withPropsOnChange } from 'recompose'
import preload from 'decorators/preload'

export default compose(
  withPagination({ per_page: 50 }),
  withSorting({ default: 'created_at' }),
  withSearch,
  withPropsOnChange(
    ['page', 'per_page', 'sortBy', 'sortReversed', 'search'],
    ({ page, per_page, sortBy, sortReversed, search }) => ({
      query: {
        page,
        per_page,
        sort_by: sortBy,
        sort_reversed: sortReversed,
        search,
      },
    }),
  ),
  connect(
    (state, props) => ({
      discussions: querySelector(state, props.query),
    }),
    (dispatch, props) => ({
      getDiscussions: () => dispatch(getDiscussions(props.query)),
      setBanned: (id: string, banned: boolean) => dispatch(updateDiscussion(id, { banned })).catch(showSavingErrors),
      setPinned: (id: string, pinned: boolean) => dispatch(updateDiscussion(id, { pinned })).catch(showSavingErrors),
      setTheme: (id: string, theme: string) => dispatch(updateDiscussion(id, { theme })).catch(showSavingErrors),
      setPublished: (id: string, published: boolean) =>
        dispatch(updateDiscussion(id, { published })).catch(showSavingErrors),
    }),
  ),
  preload({
    discussions: {
      load: 'getDiscussions',
      replaceWithData: false,
      optional: true,
    },
  }),
  withPropsOnChange(['discussions'], ({ discussions }) => ({
    discussions: discussions.data,
    pages: discussions.pages,
    discussionsLoading: discussions.loading,
  })),
)(Discussions)
