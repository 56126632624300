// @flow
import { isString, mergeWith } from "lodash"

type Styles = {
  [string]: string,
}

const customizer = (a, b) => {
  if (isString(a) && isString(b)) {
    return [a, b].join(" ")
  }
}

export default (a: Styles, b?: Styles) => mergeWith(a, b, customizer)
