// @flow
import type { ReportData } from 'store/reducers/reports'
import { type LocationShape } from 'react-router-dom'
import * as React from 'react'
import Helmet from 'react-helmet'
import memoize from 'shared/utils/memoize'

import Panel from 'components/Panel'
import Button from 'components/Button'
import User from 'components/User'
import SearchInput from 'components/SearchInput'
import DataTable, { bindSortableHeaderToProps, renderers } from 'components/DataTable'
import Pagination from 'components/Pagination'

type Props = {
  reports: ?(ReportData[]),
  reportsLoading: boolean,
  page: number,
  pages: ?number,
  getPageLink: (number) => LocationShape,
  sortBy: string,
  sortReversed: string,
  getSortLink: (string, ?boolean) => LocationShape,
  search?: string,
  setSearch: (string) => any,
  hideComment: (string) => any,
  children?: React.Node,
  setResolved: (number, boolean) => Promise<any>,
}

type ExtraProps = {}

const FRONTEND_URL = process.env.FRONTEND_URL || 'https://zindi.africa'

const ResolvedButton = ({ row, setResolved }: { row: ReportData, setResolved: (number, boolean) => Promise<*> }) => {
  const onClick = React.useCallback(() => {
    const newResolved = !row.resolved

    return setResolved(row.id, newResolved)
  }, [row.resolved, setResolved])

  return (
    <Button size="small" kind={row.resolved ? 'primary' : 'danger'} onClick={onClick} outline promised>
      {row.resolved ? 'Yes' : 'No'}
    </Button>
  )
}
const HideButton = ({ row, setHidden }) => {
  const [actioned, setActioned] = React.useState(false)
  const onClick = React.useCallback(() => {
    const commentId = row.report_link.split('#')[1].replace("comment", "")
    setActioned(true)
    setHidden(commentId)
  }, [row.resolved, setHidden])

  if(actioned) return null

  return (
    <Button size="small" kind={row.resolved ? 'primary' : 'danger'} onClick={onClick} outline promised>
      {row.report_link === null && ""}
      {row.report_link !== null && (row.reportable_type === "Comment" ? "Hide" : "Can't hide a discussion")}
    </Button>
  )
}
const COLUMNS = [
  {
    key: 'user',
    label: 'reporter',
    render: (user) =>
      user && (
        <a href={`${FRONTEND_URL}/users/${user.username}`} target="_blank" rel="noopener noreferrer">
          <User username={user.username} avatar={user.avatar} />
        </a>
      ),
    width: 150,
    flexible: false,
    sortable: true,
  },
  {
    key: 'reasons',
    width: 50,
    flexible: true,
    render: (reasons) => <div>{reasons.join(', ')}</div>,
    sortable: false,
  },
  {
    key: 'description',
    width: 50,
    flexible: true,
    render: (description) => <div>{description}</div>,
    sortable: false,
  },
  {
    key: 'report_link',
    render: (report_link) => (
      (report_link && <a href={`${report_link}`} target="_blank" rel="noopener noreferrer" title={report_link}>
        Click to open
      </a>)
    ),
    width: 100,
    flexible: true,
    sortable: false,
  },
  {
    key: 'violator',
    render: (violator) =>
      violator && (
        <a href={`${FRONTEND_URL}/users/${violator.username}`} target="_blank" rel="noopener noreferrer">
          <User username={violator.username} avatar={violator.avatar} />
        </a>
      ),
    width: 100,
    flexible: false,
    sortable: false,
  },
  {
    key: 'created_at',
    label: 'date reported',
    render: renderers.date,
    width: 150,
  },
  {
    key: 'resolved',
    label: 'resolved',
    width: 80,
    align: 'right',
    render: (_, row, extraProps) => <ResolvedButton row={row} setResolved={extraProps.setResolved} />,
  },
  {
    key: 'hide',
    label: 'hide',
    width: 80,
    align: 'right',
    render: (_, row, extraProps) => <HideButton row={row} setHidden={extraProps.hideComment} />,
  },
]

export default class Reports extends React.PureComponent<Props> {
  cacheExtraProps = memoize(1)((props) => props)

  render() {
    const {
      reports,
      reportsLoading,
      page,
      pages,
      getPageLink,
      sortBy,
      sortReversed,
      getSortLink,
      search,
      setSearch,
      children,
      setResolved,
      hideComment,
    } = this.props

    const SortableHeader = bindSortableHeaderToProps({
      sortBy,
      sortReversed,
      getSortLink,
    })

    const extraProps: ExtraProps = this.cacheExtraProps({ setResolved, hideComment })

    return (
      <div>
        <Helmet title="Reports" />
        <Panel nopadding>
          <SearchInput value={search} setValue={setSearch} />
        </Panel>
        <Panel nopadding>
          <DataTable
            keyColumn="id"
            data={reports}
            columns={COLUMNS}
            headerComponent={SortableHeader}
            extraProps={extraProps}
            loading={reportsLoading}
          />
        </Panel>
        <Pagination page={page} pages={pages} getPageLink={getPageLink} />
        {children}
      </div>
    )
  }
}
