import { withPropsOnChange } from "recompose"

const HACKATHON_REGEX = /^\/hackathons/

export default withPropsOnChange(["location"], ({ location: { pathname } }) => ({
  competitionKind: HACKATHON_REGEX.test(pathname) ? "hackathon" : "competition",
}))

export const getCompetitionTitleFromKind = (kind: string, plural = true) =>
  `${kind === "hackathon" ? "Hackathon" : "Competition"}${plural ? "s" : ""}`

export const getCompetitionPathFromKind = (kind: string, plural = true) =>
  `${kind === "hackathon" ? "hackathon" : "competition"}${plural ? "s" : ""}`
