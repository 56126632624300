// @flow
import { getDiscussion, discussionSelector } from 'store/reducers/fullDiscussions'
import { connect } from 'react-redux'
import { withPropsOnChange, compose } from 'recompose'

import preload from 'decorators/preload'

import Discussion from 'views/DiscussionPreview'

export default compose(
  withPropsOnChange(['match'], ({ match: { params: { id } } }) => ({
    discussionId: id,
  })),
  connect(
    (state, props) => ({
      discussion: discussionSelector(state, props.discussionId),
    }),
    (dispatch, props) => ({
      getDiscussion: () => dispatch(getDiscussion(props.discussionId)),
    }),
  ),
  preload({
    discussion: {
      load: 'getDiscussion',
      optional: false,
    },
  }),
)(Discussion)
