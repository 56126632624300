// @flow
import * as React from "react"
import { List } from "immutable"
import { compose, withPropsOnChange } from "recompose"
import { map, compact, flatten } from "lodash"
import TagsInput from "react-tagsinput"

require("./TextArray.scss")

const KEYS = {
  tab: 9,
  enter: 13,
  comma: 188,
  semicolon: 186,
  space: 32,
}
const getKeyCode = (key) => KEYS[key]

type Props = {
  value?: string[],
  disabled?: boolean,
  invalid?: boolean,
  autoFocus?: boolean,
  className?: string,
  onChange: (?(string[])) => any,
}

class TextArrayInput extends React.PureComponent<Props> {
  input: ?HTMLInputElement

  static defaultProps = {
    type: "text",
    value: [],
    addKeys: map(["tab", "enter", "semicolon", "comma"], getKeyCode),
    onlyUnique: true,
    addOnBlur: true,
    inputProps: {
      className: "react-tagsinput-input",
      placeholder: null,
    },
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  focus = () => {
    if (this.input) {
      this.input.focus()
    }
  }

  onChange = (value: ?(string[])) => {
    this.props.onChange(List(value))
  }

  render() {
    const { invalid, className, ...otherProps } = this.props

    return (
      <TagsInput
        ref={(ref) => {
          this.input = ref
        }}
        {...otherProps}
        onChange={this.onChange}
      />
    )
  }
}

export default compose(
  withPropsOnChange(["value"], ({ value }) => ({
    value: compact(flatten([value && value.toJSON()])),
  })),
  withPropsOnChange(["splitOn"], ({ splitOn }) => ({
    addKeys: splitOn && map(splitOn, getKeyCode),
  }))
)(TextArrayInput)
