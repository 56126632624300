// @flow
import * as React from 'react'
import c from 'classnames'
import s from './Html.scss'

type Props = {
  className?: string,
  html?: string,
  children?: React.Node,
}

export default function Html({ className, html, children }: Props) {
  const props: any = {
    className: c(s.container, className),
  }

  if (children) {
    props.children = children
  } else if (html) {
    props.dangerouslySetInnerHTML = { __html: html }
  }

  return <div {...props} />
}
