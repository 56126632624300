// @flow
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { map } from 'lodash'
import c from 'classnames'

import SignOut from './Header/SignOut'

import s from './Header.scss'

const ITEMS = [
  ['/competitions', 'Competitions'],
  ['/blog', 'Blog'],
  ['/users', 'Users'],
  ['/admins', 'Admins'],
  ['/jobs', 'Jobs'],
  ['/discussions', 'Discussions'],
  ['/reports', 'Reports'],
]

type Props = {
  signOut: () => Promise<any>,
}

export default class Header extends React.Component<Props> {
  signOut = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (e && e.preventDefault) e.preventDefault()

    this.props.signOut()
  }

  render() {
    const { signOut } = this.props

    return (
      <div className={s.container}>
        <div className={s.top}>
          {map(ITEMS, ([to, label]) => (
            <NavLink key={to} to={to} className={s.link} activeClassName={s.activeLink}>
              <span className={s.linkInner}>{label}</span>
            </NavLink>
          ))}
        </div>
        <div className={s.bottom}>
          <small>
            <a href={process.env.ADMIN_UI_URL}>Use the new Admin UI</a>
          </small>
          <SignOut className={c(s.link, s.signOut)} signOut={signOut} />
        </div>
      </div>
    )
  }
}
