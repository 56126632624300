// @flow
import * as React from "react"
import c from "classnames"

import Svg from "./Svg"
import s from "./Spinner.scss"
import spinner from "../assets/feather/refresh-cw.svg"

type Props = {|
  className?: string,
  color: "white" | "black",
|}

const Spinner = ({ className, color }: Props) => <Svg icon={spinner} className={c(s.spinner, s[color], className)} />

Spinner.defaultProps = {
  color: "white",
}

export default Spinner
