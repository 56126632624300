import { useQuery } from 'react-query'

const ORIGIN          = (new URL(process.env.API_ENDPOINT)).origin
const PUBLIC_BASE_URL = `${ORIGIN}/v1`

const queryKey       = () => ['autosuggest', 'country']
const fetchCountries = () => fetch(`${PUBLIC_BASE_URL}/${queryKey().join('/')}`).then(res => res.json()).then(res => res.data)

export default function useCountriesQuery() {
  return useQuery(queryKey(), fetchCountries)
}
