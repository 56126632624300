// @flow
import { handleActions } from "redux-actions"
import { includes } from "lodash"
import * as API from "api"
import type { Action } from "shared/store/types"
import { loadable, loadableSelector } from "store/enhancers/loadable"
import { GET_JOBS, CREATE_JOB, UPDATE_JOB, DELETE_JOB } from "store/actionTypes"

export type JobData = {
  +id: number,
  +title: string,
  +company: string,
  +published: boolean,
  +publish_time: boolean,
  +country: string,
  +city: string,
}

export type Query = {
  page: number,
  per_page: number,
  sort_by: string,
  sort_reversed: boolean,
  search: ?string,
}

const CLEAR_ON = [CREATE_JOB.SUCCESS, UPDATE_JOB.SUCCESS, DELETE_JOB.SUCCESS]

export default loadable(GET_JOBS, {
  clearOn: (action) => includes(CLEAR_ON, action.type),
})(handleActions({}, {}))

export const getJobs = (query: Query): Action => ({
  types: GET_JOBS,
  api: API.getJobs(query),
  payload: { query },
})

export const querySelector = loadableSelector("jobs")
