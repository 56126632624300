import * as React from "react"
import c from "classnames"
import { MESSAGES } from "shared/utils/validate"
import s from "./Field.scss"

type Props = {
  invalid: boolean,
  touched: boolean,
  error?: string,
  label?: React.Node,
  children: React.Node,
  shouldShowError?: (string, props: Props) => boolean,
  wide?: boolean,
  relative?: boolean,
  id: string,
  checkbox?: boolean,
  className?: string,
  note: React.Node,
  fullWidth: boolean,
}

const REQUIRED_MESSAGE = MESSAGES.required()
const defaultShowError = (error) => error !== REQUIRED_MESSAGE

export default class Field extends React.Component<Props> {
  static defaultProps = {
    shouldShowError: defaultShowError,
  }

  render() {
    const {
      touched,
      error,
      invalid,
      children,
      label,
      shouldShowError,
      wide,
      relative,
      id,
      checkbox,
      className,
      note,
      fullWidth,
    } = this.props

    const showError = touched && error && shouldShowError(error)

    return (
      <div
        className={c(
          s.container,
          relative && s.relative,
          fullWidth && s.fullWidth,
          touched && invalid && s.invalid,
          checkbox && s.checkbox,
          className
        )}
      >
        {label && (
          <label htmlFor={id} className={s.label}>
            {label}
          </label>
        )}
        <div className={s.inputContainer}>
          <div className={c(s.input, wide && s.wide)}>{children}</div>
          {showError && <div className={s.error}>{error}</div>}
          {note && <div className={s.note}>{note}</div>}
        </div>
      </div>
    )
  }
}
