// @flow
import { reducer as form } from 'redux-form/immutable'
import admins from './admins'
import competitions from './competitions'
import fullCompetitions from './fullCompetitions'
import fullJobFunnels from './fullJobFunnels'
import cookie from './cookie'
import me from './me'
import users from './users'
import blogPosts from './blogPosts'
import fullBlogPosts from './fullBlogPosts'
import submissions from './submissions'
import participations from './participations'
import jobs from './jobs'
import fullJobs from './fullJobs'
import jobFunnels from './jobFunnels'
import discussions from './discussions'
import fullDiscussions from './fullDiscussions'
import reports from './reports'

export default {
  form,
  admins,
  competitions,
  fullCompetitions,
  fullJobFunnels,
  cookie,
  me,
  users,
  blogPosts,
  fullBlogPosts,
  submissions,
  participations,
  jobs,
  fullJobs,
  jobFunnels,
  discussions,
  fullDiscussions,
  reports,
}
