// @flow
import * as React from "react"
import c from "classnames"

import s from "./Sticky.scss"

type Props = {
  children: React.Node,
  className?: string,
  stickyClassName?: string,
  enabled: boolean,
  containerId?: string,
}

type State = {
  sticky: boolean,
  height: number,
  width: ?number,
}

const getOffsetTop = (node: HTMLElement, container: HTMLElement) => {
  let current: any = node
  let offset = node.offsetTop

  do {
    current = current.offsetParent

    if (current === container || current === document.body) current = null

    if (current && typeof current.offsetTop === "number") {
      offset += current.offsetTop
    }
  } while (current)

  return offset
}

export default class Sticky extends React.PureComponent<Props, State> {
  inner: ?HTMLDivElement
  node: ?HTMLDivElement

  static defaultProps = {
    enabled: true,
  }

  state = {
    sticky: false,
    height: 0,
    width: undefined,
  }

  getContainer() {
    const { containerId } = this.props

    if (containerId) {
      const container = document.getElementById(containerId)

      if (!container) {
        throw new Error(`Can't find container with #${containerId}`)
      }

      return container
    }

    return window
  }

  enable() {
    if (process.env.SERVER) {
      return
    }

    const container = this.getContainer()

    container.addEventListener("scroll", this.updateSticky)
    container.addEventListener("resize", this.updateSticky)

    this.updateSticky()
  }

  disable() {
    if (process.env.SERVER) {
      return
    }

    const container = this.getContainer()

    container.removeEventListener("scroll", this.updateSticky)
    container.removeEventListener("resize", this.updateSticky)

    this.setState({ sticky: false })
  }

  UNSAFE_componentDidUpdate(nextProps: Props) {
    if (!this.props.enabled && nextProps.enabled) this.enable()
    else if (this.props.enabled && !nextProps.enabled) this.disable()
  }

  componentDidMount() {
    if (this.props.enabled) this.enable()
  }
  componentWillUnmount() {
    this.disable()
  }

  updateSticky = () => {
    const container = this.getContainer()

    if (!this.node || !this.inner) {
      return
    }

    const scrollTop: number = container === window ? window.pageYOffset : container.scrollTop
    const nodeHeight = this.inner.offsetHeight
    const nodeTop = getOffsetTop(this.node, container)

    const sticky = nodeTop <= scrollTop

    this.setState(({ width }) => ({
      sticky,
      height: nodeHeight,
      width: sticky && this.node ? this.node.offsetWidth : width,
    }))
  }

  render() {
    const { enabled, children, className, stickyClassName } = this.props
    const { height, sticky, width } = this.state

    return (
      <div
        ref={(ref) => {
          this.node = ref
        }}
        className={s.container}
        style={{ height: enabled ? height : null }}
      >
        <div
          className={c(s.inner, className, sticky && s.sticky, sticky && stickyClassName)}
          style={{ width }}
          ref={(ref) => {
            this.inner = ref
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}
