// @flow
import { type SelectionState, EditorState } from "draft-js"
import { each } from "lodash"

import addBlock from "./addBlock"

const insertBlock = (editorState, selection, type, text) => {
  const newContentState = addBlock(editorState, selection, type, {
    text,
    mutability: "MUTABLE",
  })

  const newState = EditorState.push(editorState, newContentState, "insert-fragment")

  return EditorState.forceSelection(newState, newContentState.getSelectionAfter())
}

const isCode = (file) => file.type.indexOf("text/") === 0 || file.type === "application/json"

const isImage = (file) => file.type.indexOf("image/") === 0

const readFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      resolve({
        lastModifiedDate: file.lastModifiedDate,
        lastModified: file.lastModified,
        name: file.name,
        size: file.size,
        type: file.type,
        src: event.target.result,
      })
    }

    if (isCode(file)) {
      reader.readAsText(file)
    } else {
      reject(new Error("Not a text file"))
    }
  })

export default ({
  uploadImage,
  addImage,
}: {
  uploadImage: (File) => Promise<*>,
  addImage: (EditorState, string) => EditorState,
} = {}) => ({
  handleDroppedFiles: (
    selection: SelectionState,
    files: File[],
    {
      getEditorState,
      setEditorState,
    }: {
      getEditorState: () => EditorState,
      setEditorState: (EditorState) => any,
    }
  ) => {
    let editorState = getEditorState()

    each(files, (file) => {
      if (isCode(file)) {
        readFile(file).then((content) => {
          editorState = insertBlock(editorState, selection, "code-block", content.src)
          setEditorState(editorState)
        })
      } else if (isImage(file)) {
        uploadImage(file).then(({ data: { url } }) => {
          editorState = addImage(editorState, url)
          setEditorState(editorState)
        })
      }
    })
  },
})
