// @flow
import { handleActions } from 'redux-actions'
import * as API from 'api'
import { pick, map, each } from 'lodash'
import { createSelector } from 'reselect'

import { GET_COMPETITIONS, CREATE_COMPETITION, UPDATE_COMPETITION, DELETE_COMPETITION } from 'store/actionTypes'

import type { ApiError, Action, ApiResponse } from 'shared/store/types'

export type CompetitionData = {
  +id: number,
  +title: string,
  +url_title: string,
  +reward: string,
  +start_time: number,
  +end_time: number,
  +end_time: number,
  +created_at: number,
  +published: boolean,
  +tags: string[],
  +kind: string,
}
const stripFullCompetition = (competition) =>
  pick(competition, [
    'id',
    'title',
    'url_title',
    'reward',
    'start_time',
    'end_time',
    'end_time',
    'created_at',
    'created_at',
    'published',
  ])

export type NewCompetitionData = {
  +title?: string,
  +url_title?: string,
  +subtitle?: string,
  +reward?: string,
  +start_time?: number,
  +end_time?: number,
  +end_minutes_string?: number,
  +published?: boolean,
  +image?: File,
  +tags: string[],
}

export type UpdateCompetitionData = NewCompetitionData

type State = {
  competitions: {
    [string]: CompetitionData,
  },
  queries: {
    [string]: {
      +data: ?(number[]),
      +loading: boolean,
      +error: ApiError | null,
    },
  },
  totalCount?: number,
}

export type Query = {
  page: number,
  per_page: number,
  sort_by: string,
  sort_reversed: boolean,
  search: ?string,
}

type ApiResult = ApiResponse<CompetitionData[], { total_count: number }, { query: Query }>

export const defaultPageData = {
  data: undefined,
  loading: false,
  error: null,
}

const initialState: State = {
  competitions: {},
  queries: {},
  totalCount: undefined,
}

const normalize = (
  competitions: CompetitionData[],
): {
  ids: string[],
  data: { [string]: CompetitionData },
} => {
  const ids = []
  const data = {}

  each(competitions, (competition) => {
    ids.push(competition.id)
    data[competition.id] = competition
  })

  return { ids, data }
}

const queryHash = ({ page, per_page, sort_by, sort_reversed, search }: Query) =>
  [page, per_page, sort_by, sort_reversed.toString(), search].join('%!%')

export default handleActions(
  {
    [GET_COMPETITIONS.START]: (state: State, { payload: { query } }): State => ({
      ...state,
      queries: {
        ...state.queries,
        [queryHash(query)]: {
          ...defaultPageData,
          loading: true,
        },
      },
    }),
    [GET_COMPETITIONS.FAILURE]: (state: State, { payload: { query, error } }): State => ({
      ...state,
      queries: {
        ...state.queries,
        [queryHash(query)]: {
          loading: false,
          error,
        },
      },
    }),
    [GET_COMPETITIONS.SUCCESS]: (
      state: State,
      {
        payload: {
          query,
          result: {
            data: competitions,
            meta: { total_count },
          },
        },
      }: ApiResult,
    ): State => {
      const { ids, data } = normalize(competitions)

      return {
        competitions: {
          ...state.competitions,
          ...data,
        },
        queries: {
          ...state.queries,
          [queryHash(query)]: {
            data: ids,
            loading: false,
          },
        },
        totalCount: total_count,
      }
    },

    [CREATE_COMPETITION.SUCCESS]: (): State => ({
      competitions: {},
      queries: {},
      totalCount: undefined,
    }),

    [UPDATE_COMPETITION.SUCCESS]: (
      state: State,
      {
        payload: {
          result: { data },
        },
      }: { payload: { result: { data: CompetitionData } } },
    ): State => ({
      ...state,
      competitions: {
        ...state.competitions,
        [data.id]: stripFullCompetition(data),
      },
    }),

    [DELETE_COMPETITION.SUCCESS]: (): State => ({
      competitions: {},
      queries: {},
      totalCount: undefined,
    }),
  },
  initialState,
)

export const getCompetitions = (query: Query): Action => ({
  types: GET_COMPETITIONS,
  api: API.getCompetitions(query),
  payload: { query },
})

export const createCompetition = (data: NewCompetitionData): Action => ({
  types: CREATE_COMPETITION,
  api: API.createCompetition(data),
  payload: { data },
})

export const querySelector = createSelector(
  [
    (state) => state.competitions,
    (_, { page, per_page, sortBy, sortReversed, search }) => ({
      page,
      per_page,
      sort_by: sortBy,
      sort_reversed: sortReversed,
      search,
    }),
  ],
  ({ competitions, queries, totalCount }, query) => {
    const hash = queryHash(query)
    let pageData = queries[hash] || defaultPageData

    if (pageData.data) {
      pageData = {
        ...pageData,
        data: map(pageData.data, (id) => competitions[id]),
      }
    }

    return {
      pages: totalCount === undefined ? undefined : Math.ceil(totalCount / query.per_page),
      competitions: pageData,
    }
  },
)
