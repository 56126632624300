// @flow
import {
  createDatafile,
  updateDatafile,
  deleteDatafile,
  updateCompetition,
  getCompetitionBenchmarkScores,
} from 'store/reducers/fullCompetitions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { map } from 'lodash'

import Data from 'views/Competition/Data'
import showSavingErrors from 'utils/showSavingErrors'

export default compose(
  connect(
    () => ({}),
    (dispatch, props) => ({
      updateCompetition: (values) => dispatch(updateCompetition(props.competition.id, values)).catch(showSavingErrors),
      fetchBenchmarkScores: () => dispatch(getCompetitionBenchmarkScores(props.competition.id)),
      updateFilename: (id, value) => dispatch(updateDatafile(props.competition.id, id, { filename: value })),
      updateDescription: (id, value) => dispatch(updateDatafile(props.competition.id, id, { description: value })),
      deleteDatafile: (id) => dispatch(deleteDatafile(props.competition.id, id)),
      uploadDatafiles: (files: File[]) =>
        Promise.all(map(files, (file) => dispatch(createDatafile(props.competition.id, { file })))),
    }),
  ),
)(Data)
