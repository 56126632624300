// @flow
import * as React from "react"
import Spinner from "components/Spinner"
import c from "classnames"

import s from "./Loading.scss"

export default class Loading extends React.PureComponent<
  {
    timeout: number,
    className?: string,
  },
  {
    show: boolean,
  }
> {
  static defaultProps = {
    timeout: 1000,
  }

  mount: boolean

  state = {
    show: false,
  }

  componentDidMount() {
    this.mount = true

    setTimeout(this.show, this.props.timeout)
  }

  componentWillUnmount() {
    this.mount = false
  }

  show = () => {
    if (this.mount) {
      this.setState(() => ({ show: true }))
    }
  }

  render() {
    const { className } = this.props
    const { show } = this.state

    return (
      <div className={c(s.container, className, show && s.visible)}>
        <Spinner className={s.spinner} />
      </div>
    )
  }
}
