// @flow
import * as React from "react"
import TextareaAutosize from "react-textarea-autosize"
import c from "classnames"
import s from "./Textarea.scss"

type Props = {
  value?: string,
  type?: string,
  className?: string,
  disabled?: boolean,
  invalid?: boolean,
  autoFocus?: boolean,
  autosize?: boolean,
  minRows?: number,
}

export default class Textarea extends React.PureComponent<Props> {
  input: ?HTMLInputElement

  static defaultProps = {
    type: "text",
    autosize: true,
  }

  componentDidMount() {
    if (this.props.autoFocus && this.input) {
      this.input.focus()
    }
  }

  render() {
    const { invalid, autosize, className, ...otherProps } = this.props
    const Component = autosize ? TextareaAutosize : "textarea"

    return (
      <Component
        ref={(ref) => {
          this.input = ref
        }}
        {...otherProps}
        className={c(s.base, invalid && s.invalid, className)}
      />
    )
  }
}
