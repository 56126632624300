const SIZES = ["B", "KB", "MB", "GB", "TB"]

export default (bytes: number) => {
  if (bytes === 0) {
    return `0 ${SIZES[0]}`
  }

  const i = Number.parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  return [Math.round((bytes * 10) / 1024 ** i) / 10, SIZES[i]].join(" ")
}
