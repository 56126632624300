// @flow
import type { LocationShape } from "react-router-dom"

import * as React from "react"
import c from "classnames"
import { Link, withRouter } from "react-router-dom"
import { map, range } from "lodash"

import s from "./Pagination.scss"

type Props = {
  page: number,
  pages: ?number,
  getPageLink: (number) => LocationShape,
}

const MaybeLink = ({ className, to, disabled, ...props }) => {
  if (disabled) {
    return <span {...props} className={c(className, s.disabled)} to={to} />
  }

  return <Link {...props} className={className} to={to} />
}

class Pagination extends React.Component<Props> {
  render() {
    const { pages, page, getPageLink } = this.props

    if (pages === undefined || pages === null) return null
    if (pages < 2) return null

    return (
      <div className={s.container}>
        {map(range(0, pages), (p) => (
          <MaybeLink key={p} className={s.page} to={getPageLink(p + 1)} disabled={p === page}>
            {p + 1}
          </MaybeLink>
        ))}
      </div>
    )
  }
}

export default withRouter(Pagination)
