// @flow
import * as React from "react"
import { filter } from "lodash"
import Spinner from "components/Spinner"

import Base, { type Suggestion } from "./Base"
import s from "./Async.scss"

type Props = {
  onChange: (?string) => any,
  value: ?string,
  getSuggestions: () => Promise<Suggestion[]>,
  hideFullMatch: boolean,
  showAllWhenEmpty: boolean,
  shouldRenderSuggestions: (?string) => any,
}

const alwaysTrue = () => true

type State = {
  loading: boolean,
  suggestions: ?(Suggestion[]),
  filteredSuggestions: ?(Suggestion[]),
}

const filterSuggestions = (
  suggestions: ?(Suggestion[]),
  value: ?string,
  hideFullMatch: boolean,
  showAllWhenEmpty: boolean
) => {
  if (!suggestions) return []
  const trimmedValue = value && value.trim().toLowerCase()

  if (!trimmedValue) {
    if (showAllWhenEmpty) {
      return suggestions
    }

    return []
  }

  const valueLength = trimmedValue.length

  return filter(suggestions, (suggestion) => {
    const trimmedSuggestion = suggestion.value.toLowerCase()

    return (
      trimmedSuggestion.slice(0, valueLength) === trimmedValue &&
      (hideFullMatch ? trimmedSuggestion !== trimmedValue : true)
    )
  })
}

export default class PreloadedAutosuggest extends React.Component<Props, State> {
  static defaultProps = {
    hideFullMatch: true,
  }

  state = {
    suggestions: [],
    filteredSuggestions: [],
    loading: false,
  }

  baseRef = React.createRef()

  componentDidMount() {
    this.loadSuggestions()
  }

  loadSuggestions = () => {
    this.props.getSuggestions().then(this.onLoadSuccess).catch(this.onLoadError)
  }

  onLoadSuccess = (suggestions: Suggestion[]) => {
    this.setState({ loading: false, suggestions })
  }

  onLoadError = () => {
    this.setState({ loading: false, suggestions: [] })
  }

  filterSuggestions = (query: ?string) =>
    this.setState(({ suggestions }) => ({
      filteredSuggestions: filterSuggestions(suggestions, query, this.props.hideFullMatch, this.props.showAllWhenEmpty),
    }))

  onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    if (value) {
      this.filterSuggestions(value)
    } else {
      this.onSuggestionsClearRequested()
    }
  }

  onSuggestionsClearRequested = () => {
    this.filterSuggestions(null)
  }

  renderSuggestionsContainer = ({ containerProps, children }: any) => {
    const showLoading = !!(this.state.loading && children)

    return (
      <div {...containerProps} className={containerProps.className}>
        {children}
        {showLoading && (
          <div className={s.loading}>
            <Spinner className={s.loadingSpinner} />
          </div>
        )}
      </div>
    )
  }

  focus = () => {
    if (this.baseRef.current) {
      this.baseRef.current.focus()
    }
  }

  render() {
    const { filteredSuggestions } = this.state
    const { getSuggestions, ...props } = this.props

    const shouldRenderSuggestions = props.shouldRenderSuggestions || (props.showAllWhenEmpty ? alwaysTrue : undefined)

    return (
      <Base
        {...props}
        ref={this.baseRef}
        suggestions={filteredSuggestions}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
    )
  }
}
