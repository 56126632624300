// @flow
import { handleActions } from 'redux-actions'
import * as API from 'api'
import { pick, map, each } from 'lodash'
import { createSelector } from 'reselect'

import { GET_BLOG_POSTS, CREATE_BLOG_POST, UPDATE_BLOG_POST, DELETE_BLOG_POST } from 'store/actionTypes'

import type { ApiError, Action, ApiResponse } from 'shared/store/types'

export type BlogPostData = {
  +id: number,
  +title: string,
  +url_title: string,
  +published: boolean,
  +published_at: number,
}
const stripFullBlogPost = (blogPost) =>
  pick(blogPost, ['id', 'title', 'url_title', 'published', 'published_at', 'theme'])

export type CreateBlogPostData = {
  +title: string,
  +url_title: string,
}

type State = {
  blogPosts: {
    [string]: BlogPostData,
  },
  queries: {
    [string]: {
      +data: ?(number[]),
      +loading: boolean,
      +error: ApiError | null,
    },
  },
  totalCount?: number,
}

export type Query = {
  page: number,
  per_page: number,
  sort_by: string,
  sort_reversed: boolean,
  search: ?string,
}

type ApiResult = ApiResponse<BlogPostData[], { total_count: number }, { query: Query }>

export const defaultPageData = {
  data: undefined,
  loading: false,
  error: null,
}

const initialState: State = {
  blogPosts: {},
  queries: {},
  totalCount: undefined,
}

const normalize = (
  blogPosts: BlogPostData[],
): {
  ids: string[],
  data: { [string]: BlogPostData },
} => {
  const ids = []
  const data = {}

  each(blogPosts, (blogPost) => {
    ids.push(blogPost.id)
    data[blogPost.id] = blogPost
  })

  return { ids, data }
}

const queryHash = ({ page, per_page, sort_by, sort_reversed, search }: Query) =>
  [page, per_page, sort_by, sort_reversed.toString(), search].join('%!%')

export default handleActions(
  {
    [GET_BLOG_POSTS.START]: (state: State, { payload: { query } }): State => ({
      ...state,
      queries: {
        ...state.queries,
        [queryHash(query)]: {
          ...defaultPageData,
          loading: true,
        },
      },
    }),
    [GET_BLOG_POSTS.FAILURE]: (state: State, { payload: { query, error } }): State => ({
      ...state,
      queries: {
        ...state.queries,
        [queryHash(query)]: {
          loading: false,
          error,
        },
      },
    }),
    [GET_BLOG_POSTS.SUCCESS]: (
      state: State,
      {
        payload: {
          query,
          result: {
            data: blogPosts,
            meta: { total_count },
          },
        },
      }: ApiResult,
    ): State => {
      const { ids, data } = normalize(blogPosts)

      return {
        blogPosts: {
          ...state.blogPosts,
          ...data,
        },
        queries: {
          ...state.queries,
          [queryHash(query)]: {
            data: ids,
            loading: false,
          },
        },
        totalCount: total_count,
      }
    },

    [CREATE_BLOG_POST.SUCCESS]: (): State => ({
      blogPosts: {},
      queries: {},
      totalCount: undefined,
    }),

    [UPDATE_BLOG_POST.SUCCESS]: (
      state: State,
      {
        payload: {
          result: { data },
        },
      }: { payload: { result: { data: BlogPostData } } },
    ): State => ({
      ...state,
      blogPosts: {
        ...state.blogPosts,
        [data.id]: stripFullBlogPost(data),
      },
    }),

    [DELETE_BLOG_POST.SUCCESS]: (): State => ({
      blogPosts: {},
      queries: {},
      totalCount: undefined,
    }),
  },
  initialState,
)

export const getBlogPosts = (query: Query): Action => ({
  types: GET_BLOG_POSTS,
  api: API.getBlogPosts(query),
  payload: { query },
})

export const createBlogPost = (data: CreateBlogPostData): Action => ({
  types: CREATE_BLOG_POST,
  api: API.createBlogPost(data),
  payload: { data },
})

export const querySelector = createSelector(
  [
    (state) => state.blogPosts,
    (_, { page, per_page, sortBy, sortReversed, search }) => ({
      page,
      per_page,
      sort_by: sortBy,
      sort_reversed: sortReversed,
      search,
    }),
  ],
  ({ blogPosts, queries, totalCount }, query) => {
    const hash = queryHash(query)
    let pageData = queries[hash] || defaultPageData

    if (pageData.data) {
      pageData = {
        ...pageData,
        data: map(pageData.data, (id) => blogPosts[id]),
      }
    }

    return {
      pages: totalCount === undefined ? undefined : Math.ceil(totalCount / query.per_page),
      blogPosts: pageData,
    }
  },
)
