// @flow
import * as React from "react"
import c from "classnames"

import s from "./Columns.scss"

type ColumnsProps = {
  children: React.Node,
}
export class Columns extends React.Component<ColumnsProps> {
  render() {
    return <div className={s.container}>{this.props.children}</div>
  }
}

type ColumnProps = {
  rigid: boolean,
  children?: React.Node,
  className?: string,
  centered?: boolean,
  style: { [string]: any },
  grow?: number | string,
}
export class Column extends React.Component<ColumnProps> {
  static defaultProps = {
    rigid: false,
    style: {},
  }

  render() {
    const { rigid, centered, children, className, grow, ...props } = this.props

    const style = { ...this.props.style }

    if (grow) {
      style.flexGrow = grow
    }

    return (
      <div {...props} className={c(s.column, rigid && s.rigid, centered && s.centered, className)} style={style}>
        {children}
      </div>
    )
  }
}
