// @flow
import * as React from "react"
import { connect } from "react-redux"
import { getMe } from "store/reducers/me"
import { compose } from "recompose"
import preload from "decorators/preload"

import { Provider as PromptsProvider } from "react-prompts"
import Prompts from "views/Prompts"

import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

class App extends React.PureComponent<{| children: React.Node |}> {
  render() {
    return (
      <React.Fragment>
        <QueryClientProvider client={queryClient}>
          {this.props.children}
          <PromptsProvider>{(props) => <Prompts {...props} />}</PromptsProvider>
        </QueryClientProvider>
      </React.Fragment>
    )
  }
}

export default compose(
  connect(
    ({ me }) => ({ me }),
    (dispatch) => ({
      getMe: () => dispatch(getMe()),
    })
  ),
  preload({
    me: {
      load: "getMe",
      optional: true,
    },
  })
)(App)
