// @flow
import * as React from "react"
import { Field } from "redux-form/immutable"
import Helmet from "react-helmet"

import Button from "components/Button"
import { TextField } from "components/fields"

type Props = {
  handleSubmit: (any) => any,
  submitting: boolean,
  invalid: boolean,
}

export default class PasswordResetForm extends React.Component<Props> {
  render() {
    const { invalid, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Helmet title="Reset password" />
        <h1>Reset password</h1>
        <Field label="New password" name="password" type="password" component={TextField} autoFocus />
        <Button type="submit" disabled={submitting || invalid} loading={submitting}>
          Save
        </Button>
      </form>
    )
  }
}
