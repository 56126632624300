// @flow
import { compose, createStore, applyMiddleware, combineReducers } from "redux"
import ReduxThunkMiddleware from "redux-thunk"
import { persistStore, persistCombineReducers } from "redux-persist"
import storage from "redux-persist/es/storage"
import { api, promise } from "redux-connection/lib/middlewares"

import { type Reducers } from "./types"

type Params = {
  initialState: { [string]: any },
  callback?: () => any,
  persist?: boolean,
}

export default ({ reducers }: { reducers: Reducers }) => (
  { initialState, callback, persist: _persist }: Params = { initialState: {}, callback: undefined, persist: true }
) => {
  const persist = !(_persist === false)

  let middleware = compose(applyMiddleware(ReduxThunkMiddleware, api, promise))

  if (!process.env.SERVER && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    middleware = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(middleware)
  }

  let reducer
  if (persist) {
    reducer = persistCombineReducers(
      {
        key: "primary",
        storage,
        whitelist: ["cookie"],
      },
      reducers
    )
  } else {
    reducer = combineReducers(reducers)
  }

  const store = createStore(reducer, initialState, middleware)

  let persistor
  if (persist) {
    persistor = persistStore(store, undefined, callback)
  }

  return { store, persistor }
}
