// @flow
import * as React from "react"
import { Link } from "react-router-dom"
import { Field } from "redux-form/immutable"
import Helmet from "react-helmet"

import Panel from "components/Panel"
import Button from "components/Button"
import { TextField } from "components/fields"

type Props = {
  handleSubmit: (any) => any,
  submitting: boolean,
  invalid: boolean,
}

export default class SignInForm extends React.Component<Props> {
  render() {
    const { invalid, handleSubmit, submitting, error } = this.props

    return (
      <Panel width="small">
        <form onSubmit={handleSubmit}>
          <Helmet title="Sign In" />
          <h1>Hey</h1>
          <Field label="Email" name="email" component={TextField} autoFocus />
          <Field label="Password" name="password" component={TextField} type="password" />
          <small>{error}</small>
          <p>
            <Link to="/auth/passwords/request">Forgot password?</Link>
          </p>
          <p>
            <a href={process.env.ADMIN_UI_URL}>Use the new Admin UI</a>
          </p>
          <Button type="submit" disabled={submitting || invalid} loading={submitting}>
            Submit
          </Button>
        </form>
      </Panel>
    )
  }
}
