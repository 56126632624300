// @flow
import { type LocationShape } from "react-router-dom"
import { withPropsOnChange } from "recompose"
import { parse, stringify } from "qs"
import memoize from "../utils/memoize"

export const getPageLink = memoize(1)((currentQuery: {}) => (page: number): LocationShape => {
  const newQuery = {
    ...currentQuery,
    page: page === 1 ? undefined : page,
  }

  return {
    search: `?${stringify(newQuery)}`,
  }
})

const getPage = (per_page) => ({ location, per_page: propsPerPage }) => {
  const perPage = per_page || propsPerPage
  const query = location.search ? parse(location.search.replace(/^\?/, "")) : {}
  let page = parseInt(query.page, 10)

  if (Number.isNaN(page)) page = 0
  else page -= 1

  return {
    page: Math.max(0, page),
    per_page: perPage,
    getPageLink: getPageLink(query),
  }
}

export default ({ per_page }: { per_page: ?number } = { per_page: 10 }) =>
  withPropsOnChange(["location", "per_page"], getPage(per_page))
