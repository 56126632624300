// @flow
import * as React from "react"
import { Route, type Location, type RouterHistory } from "react-router-dom"
import Modal from "components/Modal"

type Props = {
  component: React.ComponentType<*>,
  closeLink?: string,
  location?: Location,
  history?: RouterHistory,
}

const getCloseLink = (
  {
    location,
    history,
  }: {
    location: Location,
    history: RouterHistory,
  },
  closeLink: string
) => () =>
  location &&
  history &&
  history.push({
    pathname: closeLink,
    search: location.search,
  })

const ModalRoute = (props: Props) => {
  const { component: Component, closeLink, ...rest } = props

  return (
    <Route
      {...rest}
      render={(renderProps) => (
        <Modal isOpen onRequestClose={closeLink ? getCloseLink(renderProps, closeLink) : undefined}>
          <Component {...renderProps} />
        </Modal>
      )}
    />
  )
}

export default ModalRoute
