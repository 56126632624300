// @flow
import { createPage } from "store/reducers/fullCompetitions"
import { reduxForm } from "redux-form/immutable"
import NewPageForm from "views/NewPageForm"

import { compose } from "recompose"
import validate from "shared/utils/validate"
import handleFormErrors from "shared/utils/handleFormErrors"

export default compose(
  reduxForm({
    form: "newPage",
    validate: validate({
      email: ["required", "email"],
    }),
    onSubmit: (values, dispatch, props) =>
      dispatch(createPage(props.competition.id, values.toJSON())).catch(handleFormErrors),
    onSubmitSuccess: (result, _dispatch, props) => props.close(result.data.id),
  })
)(NewPageForm)
