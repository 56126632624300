// @flow
import { handleActions } from 'redux-actions'
import * as API from 'api'
import { map, each } from 'lodash'
import { createSelector } from 'reselect'

import { GET_USERS, UPDATE_USER } from 'store/actionTypes'

import type { ApiError, Action, ApiResponse } from 'shared/store/types'

export type UserData = {
  +id: number,
  +email: string,
  +username: string,
  +first_name: string,
  +last_name: string,
  +avatar: string,
  +bio: string,
  +score: number,
  +rank: number,
  +created_at: number,
  +confirmed_at: number,
  +banned: boolean,
}

export type UpdateUserData = {
  +banned: boolean,
}

type State = {
  users: {
    [string]: UserData,
  },
  queries: {
    [string]: {
      +data: ?(number[]),
      +loading: boolean,
      +error: ApiError | null,
    },
  },
  totalCount?: number,
}

export type Query = {
  page: number,
  per_page: number,
  sort_by: string,
  sort_reversed: boolean,
  search: ?string,
}

type ApiResult = ApiResponse<UserData[], { total_count: number }, { query: Query }>

export const defaultPageData = {
  data: undefined,
  loading: false,
  error: null,
}

const initialState: State = {
  users: {},
  queries: {},
  totalCount: undefined,
}

const normalize = (users: UserData[]): { ids: string[], data: { [string]: UserData } } => {
  const ids = []
  const data = {}

  each(users, (user) => {
    ids.push(user.id)
    data[user.id] = user
  })

  return { ids, data }
}

const queryHash = ({ page, per_page, sort_by, sort_reversed, search }: Query) =>
  [page, per_page, sort_by, sort_reversed.toString(), search].join('%!%')

export default handleActions(
  {
    [GET_USERS.START]: (state: State, { payload: { query } }): State => ({
      ...state,
      queries: {
        ...state.queries,
        [queryHash(query)]: {
          ...defaultPageData,
          loading: true,
        },
      },
    }),
    [GET_USERS.FAILURE]: (state: State, { payload: { query, error } }): State => ({
      ...state,
      queries: {
        ...state.queries,
        [queryHash(query)]: {
          loading: false,
          error,
        },
      },
    }),
    [GET_USERS.SUCCESS]: (
      state: State,
      {
        payload: {
          query,
          result: {
            data: users,
            meta: { total_count },
          },
        },
      }: ApiResult,
    ): State => {
      const { ids, data } = normalize(users)

      return {
        users: {
          ...state.users,
          ...data,
        },
        queries: {
          ...state.queries,
          [queryHash(query)]: {
            data: ids,
            loading: false,
          },
        },
        totalCount: total_count,
      }
    },

    [UPDATE_USER.SUCCESS]: (
      state: State,
      {
        payload: {
          result: { data },
        },
      }: { payload: { result: { data: UserData } } },
    ): State => ({
      ...state,
      users: {
        ...state.users,
        [data.id]: data,
      },
    }),
  },
  initialState,
)

export const getUsers = (query: Query): Action => ({
  types: GET_USERS,
  api: API.getUsers(query),
  payload: { query },
})

export const updateUser = (id: number, data: UpdateUserData): Action => ({
  types: UPDATE_USER,
  api: API.updateUser(id, data),
  payload: { id, data },
})

export const querySelector = createSelector(
  [
    (state) => state.users,
    (_, { page, per_page, sortBy, sortReversed, search }) => ({
      page,
      per_page,
      sort_by: sortBy,
      sort_reversed: sortReversed,
      search,
    }),
  ],
  ({ users, queries, totalCount }, query) => {
    const hash = queryHash(query)
    let pageData = queries[hash] || defaultPageData

    if (pageData.data) {
      pageData = {
        ...pageData,
        data: map(pageData.data, (id) => users[id]),
      }
    }

    return {
      pages: totalCount === undefined ? undefined : Math.ceil(totalCount / query.per_page),
      users: pageData,
    }
  },
)
