// @flow
import * as React from "react"
import { compose } from "recompose"
import loadingDataState from "shared/decorators/loadingDataState"

import s from "./withLoadingState.scss"

const renderWithLoading = (BaseComponent) => (props) => {
  return (
    <div className={s.container}>
      <BaseComponent {...props} />
      {props.loading && <div className={s.overlay} />}
    </div>
  )
}

export default compose(loadingDataState({ dataKey: "data", loadingKey: "loading" }), renderWithLoading)
