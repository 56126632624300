// @flow
import * as React from "react"
import { get, mapValues, map, findKey, every } from "lodash"
import { Consumer } from "./index"

type Load = string
type Options = {|
  load: Load,
  optional?: boolean,
  replaceWithData?: boolean,
|}
type PlainConfig = {
  [string]: Load | Options,
}
type DynamicConfig = (any) => PlainConfig

const defaultOptions = {
  optional: false,
  replaceWithData: true,
}

type AdditionalOptions = {
  components: {
    loading: React.ComponentType<*>,
    error: React.ComponentType<*>,
  },
  track?: (Promise<*>) => any,
}

export type Config = PlainConfig | DynamicConfig

export default (config: Config, { components }: AdditionalOptions) => (Component: React.ComponentType<any>) => {
  const getConfig = (props) => {
    let result

    if (typeof config === "function") {
      result = config(props)
    } else {
      result = config
    }

    return mapValues(result, (entity) => {
      if (typeof entity === "string") {
        return { ...defaultOptions, load: entity }
      }

      return { ...defaultOptions, ...entity }
    })
  }

  const { loading: Loading, error: LoadingError } = components

  class LoadingComponent extends React.Component<
    {
      __track__preload: (Promise<*>) => void,
    },
    { config: Config }
  > {
    state = {
      config: getConfig(this.props),
    }

    constructor(props) {
      super(props)

      this.loadIfNecessary()
    }

    UNSAFE_componentWillReceiveProps(nextProps: {}) {
      this.setState({ config: getConfig(nextProps) })
    }

    componentDidUpdate() {
      this.loadIfNecessary()
    }

    loading() {
      return !!findKey(this.state.config, (__, key) => get(this.props[key], "loading"))
    }

    error() {
      const entity = findKey(this.state.config, (__, key) => get(this.props[key], "error"))

      return entity ? get(this.props[entity], "error") : null
    }

    isLoaded() {
      return every(this.state.config, (options, key) => options.optional || get(this.props[key], "data") !== undefined)
    }

    load = (ifNecessary: boolean = false) => {
      const { __track__preload: track } = this.props
      // global.counter = global.counter || 0
      // global.counter += 1

      // if (global.counter > 10) return

      map(this.state.config, (options, key) => {
        const entity = this.props[key]

        if (get(entity, "loading")) {
          return
        }

        if (ifNecessary) {
          if (get(entity, "data") !== undefined) {
            return
          }
          if (get(entity, "error")) {
            return
          }
        }

        const promise = this.props[options.load]()
        if (track) track(promise)
      })
    }
    loadIfNecessary = () => this.load(true)

    render() {
      const error = this.error()

      if (error) {
        return <LoadingError error={error} retry={this.load} />
      } else if (this.isLoaded()) {
        const data = mapValues(this.state.config, (options, key) => {
          if (options.replaceWithData) {
            return get(this.props[key], "data")
          }

          return this.props[key]
        })

        return <Component {...this.props} {...data} />
      } else if (this.loading()) {
        return <Loading />
      }

      return null
    }
  }

  return (props: {}) => <Consumer>{({ track }) => <LoadingComponent {...props} __track__preload={track} />}</Consumer>
}
