// @flow
import { getReports, updateReport, hideComment, querySelector } from 'store/reducers/reports'
import { connect } from 'react-redux'
import Reports from 'views/Reports'
import withPagination from 'shared/decorators/withPagination'
import withSorting from 'shared/decorators/withSorting'
import withSearch from 'shared/decorators/withSearch'
import showSavingErrors from 'utils/showSavingErrors'

import { compose, withPropsOnChange } from 'recompose'
import preload from 'decorators/preload'

export default compose(
  withPagination({ per_page: 50 }),
  withSorting({ default: 'created_at' }),
  withSearch,
  withPropsOnChange(
    ['page', 'per_page', 'sortBy', 'sortReversed', 'search'],
    ({ page, per_page, sortBy, sortReversed, search }) => ({
      query: {
        page,
        per_page,
        sort_by: sortBy,
        sort_reversed: sortReversed,
        search,
      },
    }),
  ),
  connect(
    (state, props) => ({
      reports: querySelector(state, props.query),
    }),
    (dispatch, props) => ({
      getReports: () => dispatch(getReports(props.query)),
      setResolved: (id: string, resolved: boolean) => dispatch(updateReport(id, { resolved })).catch(showSavingErrors),
      hideComment: (id: string) => dispatch(hideComment(id)).then(dispatch(getReports(props.query))).catch(showSavingErrors),
    }),
  ),
  preload({
    reports: {
      load: 'getReports',
      replaceWithData: false,
      optional: true,
    },
  }),
  withPropsOnChange(['reports'], ({ reports }) => ({
    reports: reports.data,
    pages: reports.pages,
    reportsLoading: reports.loading,
  })),
)(Reports)
