// @flow
import { getJobs, querySelector } from 'store/reducers/jobs'
import { connect } from 'react-redux'
import withPagination from 'shared/decorators/withPagination'
import withSorting from 'shared/decorators/withSorting'
import withSearch from 'shared/decorators/withSearch'

import { compose, withPropsOnChange } from 'recompose'
import preload from 'decorators/preload'

import Jobs from 'views/Jobs'

export default compose(
  withPagination({ per_page: 50 }),
  withSorting({ default: 'created_at' }),
  withSearch,
  withPropsOnChange(
    ['page', 'per_page', 'sortBy', 'sortReversed', 'search'],
    ({ page, per_page, sortBy, sortReversed, search }) => ({
      query: {
        page,
        per_page,
        sort_by: sortBy,
        sort_reversed: sortReversed,
        search,
      },
    }),
  ),
  connect(
    (state, props) => ({
      me: state.me.data,
      jobs: querySelector(state, props.query),
    }),
    (dispatch, props) => ({
      getJobs: () => dispatch(getJobs(props.query)),
    }),
  ),
  preload({
    jobs: {
      load: 'getJobs',
      optional: true,
      replaceWithData: false,
    },
  }),
  withPropsOnChange(['jobs'], ({ jobs }) => ({
    jobs: jobs.data,
    pages: jobs.pages,
    jobsloading: jobs.loading,
  })),
)(Jobs)
