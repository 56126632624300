/* eslint-disable react/no-array-index-key */
import React from "react"
import { debounce, map } from "lodash"
import DraftOffsetKey from "draft-js/lib/DraftOffsetKey"

export default class Toolbar extends React.Component {
  state = {
    newBlockKey: null,
    top: 0,
  }

  componentWillMount() {
    this.props.store.subscribeToItem("newBlockKey", this.onNewBlockKeyChanged)
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
    this.props.store.unsubscribeFromItem("newBlockKey", this.onNewBlockKeyChanged)
  }

  onNewBlockKeyChanged = debounce((newBlockKey) => {
    if (!this.mounted) return

    const newState = { newBlockKey }

    if (newBlockKey) {
      const offsetKey = DraftOffsetKey.encode(newBlockKey, 0, 0)
      const blockNode = document.querySelector(`[data-offset-key="${offsetKey}"]`)

      newState.top = blockNode.offsetTop
    }

    this.setState(() => newState)
  })

  render() {
    const { theme, store, structure } = this.props
    const { newBlockKey, top } = this.state

    const childrenProps = {
      className: theme.button,
      getEditorState: store.getItem("getEditorState"),
      setEditorState: store.getItem("setEditorState"),
    }

    if (!newBlockKey) return null

    return (
      <div className={theme.toolbar} style={{ top }}>
        {map(structure, (Component, index) => (
          <Component key={index} {...childrenProps} />
        ))}
      </div>
    )
  }
}
