// @flow
import createClient from "shared/client"
import createStore from "store/createStore"
import ClientRoot from "./ClientRoot"

const render = createClient({ createStore })

render(ClientRoot)

if (module.hot && typeof module.hot.accept === "function") {
  module.hot.accept("routes", () => {
    render(ClientRoot)
  })
}
