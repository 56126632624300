// @flow
import * as React from "react"
import type { Prompt as PromptData } from "react-prompts/src/types"
import { open } from "react-prompts"
import { map } from "lodash"
import Alert from "./Prompts/Alert"
import Confirm from "./Prompts/Confirm"

export const alert = (content) =>
  open({
    content,
    type: "alert",
  })

export const confirm = (payload) =>
  open({
    ...payload,
    type: "confirm",
  })

const Prompt = (props) =>
  props.prompt.type === "alert" ? <Alert {...props} /> : props.prompt.type === "confirm" ? <Confirm {...props} /> : null

export default class Prompts extends React.Component<{
  prompts: PromptData[],
}> {
  render() {
    const { prompts } = this.props

    return map(prompts, (modal) => <Prompt key={modal.id} prompt={modal} isOpen />)
  }
}
