import { type JobFunnelData } from 'store/reducers/jobFunnels'
import * as React from 'react'
import c from 'classnames'
import { isEmpty, map, compact, transform, get, find, sortBy, reverse } from 'lodash'

import { Columns, Column } from 'components/Columns'
import Html from 'components/Html'
import MONTHS from 'utils/months'

import s from './Application.scss'

const AFFILIATION_KINDS = {
  university: 'University',
  workplace: 'Work',
}

const Affiliation = ({ affiliation }) => (
  <div className={s.affiliation}>
    <div className={s.dates}>
      {[
        compact([MONTHS[affiliation.start_month], affiliation.start_year]).join(' '),
        compact([MONTHS[affiliation.end_month], affiliation.end_year]).join(' ') || 'present',
      ].join('–')}
    </div>
    <div className={s.title}>{compact([affiliation.title, affiliation.specialization]).join(', ')}</div>
    <div className={s.subtitle}>{AFFILIATION_KINDS[affiliation.kind]}</div>
    {affiliation.comment && <div className={s.comment}>{affiliation.comment}</div>}
  </div>
)

const Participation = ({ participation }) => (
  <dl className={s.participation}>
    <dt>
      {participation.private_rank !== null ? `ranked #${participation.private_rank}` : 'no rank'}
      {participation.in_team ? ' (team) ' : ' '}
      in
    </dt>
    <dd>
      <a href={participation.competition_link} target="_blank" rel="noopener noreferrer">
        {participation.competition_title}
      </a>
    </dd>
  </dl>
)

export default function Application({ careerQuestionnaireQuestions, jobFunnel }: { jobFunnel: JobFunnelData }) {
  const {
    cv_url,
    cv_filename,
    applicant_notes,
    user: {
      avatar,
      username,
      first_name,
      last_name,
      city,
      country,
      spoken_languages,
      rank,
      programming_languages,
      data_science_specializations,
      gender,
      email,
      bio,
      career_questionnaire,
      affiliations: _affiliations,
    },
    competition_participations: _competition_participations,
  } = jobFunnel

  const name = React.useMemo(() => compact([first_name, last_name]).join(' '), [first_name, last_name])
  const location = React.useMemo(() => compact([city, country]).join(', '), [city, country])

  const affiliations = React.useMemo(
    () =>
      reverse(
        sortBy(
          _affiliations,
          ({ year, start_year, start_month, month }) => (year || start_year || -1) * 12 + (start_month || month || 1),
        ),
      ),
    [_affiliations],
  )

  const competition_participations = React.useMemo(
    () =>
      sortBy(_competition_participations, ({ private_rank }) =>
        private_rank === null ? Number.MAX_VALUE : private_rank,
      ),
    [_competition_participations],
  )

  const careerQuestionnaire = React.useMemo(
    () =>
      transform(
        career_questionnaire,
        (result, answerId, questionId) => {
          const question = find(careerQuestionnaireQuestions, ({ id }) => id === questionId)

          if (question) {
            const answer = find(question.answers, ({ id }) => id === answerId)

            result.push([question.description, get(answer, ['description'], answerId)])
          }
        },
        [],
      ),
    [career_questionnaire, careerQuestionnaireQuestions],
  )

  return (
    <div className={s.container}>
      <div className={s.block}>
        <Columns>
          <Column rigid className={s.avatar}>
            <img src={avatar} alt="" />
          </Column>
          <Column>
            <div className={s.names}>
              <span className={s.username}>{username}</span>
              <span className={s.fullname}>{name}</span>
              <span className={s.gender}>{gender === 'female' ? '♀' : gender === 'male' ? '♂' : gender}</span>
            </div>
            <div>{location}</div>
            <dl>
              <dt>email</dt>
              <dd>{email}</dd>
            </dl>
            <dl>
              <dt>speaks</dt>
              <dd>{spoken_languages && spoken_languages.join(', ')}</dd>
            </dl>
            <dl>
              <dt>ranked</dt>
              <dd>#{rank}</dd>
            </dl>
            <dl>
              <dt>programming languages</dt>
              <dd>{programming_languages && programming_languages.join(', ')}</dd>
            </dl>
            <dl>
              <dt>data science specializations</dt>
              <dd>{data_science_specializations && data_science_specializations.join(', ')}</dd>
            </dl>
          </Column>
          <Column rigid={!cv_filename}>
            <div className={c(s.block, !!cv_filename && s.application)}>
              {cv_filename ? (
                <>
                  <dl>
                    <dt>CV:</dt>
                    <dd>
                      <a href={cv_url} target="_blank" rel="noopener noreferrer">
                        {cv_filename}
                      </a>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Notes:</dt>
                    <dd className={s.big}>{applicant_notes}</dd>
                  </dl>
                </>
              ) : (
                <span>Just&nbsp;viewed</span>
              )}
            </div>
          </Column>
        </Columns>
      </div>
      <Columns>
        <Column>
          <div className={s.block}>
            {map(affiliations, (affiliation) => (
              <Affiliation affiliation={affiliation} key={affiliation.id} />
            ))}
          </div>
        </Column>
        <Column>
          {competition_participations.length > 0 && (
            <div className={s.block}>
              <div className={s.blockTitle}>Competitions</div>
              {map(competition_participations, (participation) => (
                <Participation participation={participation} key={participation.id} />
              ))}
            </div>
          )}
        </Column>
      </Columns>
      {!isEmpty(careerQuestionnaire) && (
        <div className={s.block}>
          <div className={s.blockTitle}>Career Questionnaire</div>
          {map(careerQuestionnaire, ([question, answer]) => (
            <dl key={question}>
              <dt>{question}</dt>
              <dd>{answer}</dd>
            </dl>
          ))}
        </div>
      )}
      <div className={s.block}>
        <div className={s.blockTitle}>Bio</div>
        <Html html={bio} />
      </div>
    </div>
  )
}
