// @flow
import * as React from "react"
import { reduce, every, get } from "lodash"

export default (...props: string[]) => (Component: React.ComponentType<any>) => {
  class WaitForComponent extends React.Component<{}, {}> {
    isLoaded() {
      return every(props, (prop) => get(this.props[prop], "data") !== undefined)
    }

    render() {
      if (!this.isLoaded()) {
        return null
      }

      const nextProps = reduce(
        props,
        (result, prop) => {
          result[prop] = this.props[prop].data // eslint-disable-line no-param-reassign

          return result
        },
        {}
      )

      return <Component {...this.props} {...nextProps} />
    }
  }

  return WaitForComponent
}
