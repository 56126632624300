// @flow
import * as React from "react"
import s from "./User.scss"

export default class User extends React.PureComponent<{|
  username: string,
  avatar?: ?string,
|}> {
  render() {
    const { username, avatar } = this.props

    return (
      <div className={s.container}>
        {avatar && <img src={avatar} alt="" className={s.avatar} />}
        <span className={s.username}>{username}</span>
      </div>
    )
  }
}
