// @flow
import { submitOTP } from "store/reducers/me"
import { reduxForm } from "redux-form/immutable"
import OTP from "views/OTP"

import { compose } from "recompose"
import validate from "shared/utils/validate"
import handleFormErrors from "shared/utils/handleFormErrors"

export default compose(
  reduxForm({
    form: "otp",
    validate: validate({
      otp: ["required"],
    }),
    onSubmit: (values, dispatch) =>
      dispatch(submitOTP(values.get("otp"))).catch(handleFormErrors),
    onSubmitSuccess: (_result, _dispatch, props) => props.history.push("/"),
  })
)(OTP)
