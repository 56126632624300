// @flow
import * as React from "react"
import { each } from "lodash"

import type { GetConfiguration } from "./types"
import Configuration from "./configuration"

const configure = (getConfig, allProps) => {
  const config = getConfig(allProps)
  const { include } = config

  const configuration = new Configuration()

  each(include, (plug) => plug(configuration))

  return configuration.toJS()
}

export default (getConfig: GetConfiguration) => (BaseComponent: React.ComponentType<*>): React.ComponentType<*> =>
  class ConfiguredRichEditor extends React.Component<
    *,
    {
      configuration: {},
    }
  > {
    state = { configuration: configure(getConfig, this.props) }

    render() {
      const { forwardedRef, ...props } = this.props

      return <BaseComponent {...props} configuration={this.state.configuration} />
    }
  }
