// @flow
import { handleActions } from "redux-actions"
import { includes } from "lodash"
import * as API from "api"
import type { Action } from "shared/store/types"
import {
  GET_PARTICIPATIONS,
  UPDATE_PARTICIPATION,
  UPDATE_PARTICIPATION_BANNED,
  UPDATE_SUBMISSION,
} from "store/actionTypes"
import { loadable, loadableSelector } from "store/enhancers/loadable"

export type ParticipationData = {
  +id: number,
  +username: string,
  +best_public_score: ?number,
  +best_private_score: ?number,
  +best_public_submission_id: ?string,
  +best_private_submission_id: ?string,
  +private_rank: ?number,
  +public_rank: ?number,
  +created_at: Date,
  +banned: boolean,
  +successful_submission_count: ?number,
  +admin_comment: ?string,
  +best_private_score_manual_override: ?number,
  +best_public_score_manual_override: ?number,
}

export type UpdateParticipationData = {
  banned?: boolean,
}

export type Query = {
  competitionId: string,
  page?: number,
  per_page?: number,
  sort_by?: string,
  sort_reversed?: boolean,
  search?: string,
}

const CLEAR_ON = [UPDATE_PARTICIPATION_BANNED.SUCCESS, UPDATE_SUBMISSION.SUCCESS]

export default loadable(GET_PARTICIPATIONS, {
  clearOn: (action) => includes(CLEAR_ON, action.type),
})(
  handleActions(
    {
      [UPDATE_PARTICIPATION.SUCCESS]: (
        state,
        {
          payload: {
            id,
            result: { data },
          },
        }
      ) => ({
        ...state,
        data: {
          ...state.data,
          [id]: data,
        },
      }),
    },
    {}
  )
)

export const getParticipations = (query: Query): Action => ({
  types: GET_PARTICIPATIONS,
  api: API.getParticipations(query),
  payload: { query },
})

export const updateParticipation = (id: string, data: UpdateParticipationData): Action => ({
  types: UPDATE_PARTICIPATION,
  api: API.updateParticipation(id, data),
  payload: { id },
})

export const updateParticipationBanned = (id: string, banned: boolean): Action => ({
  types: UPDATE_PARTICIPATION_BANNED,
  api: API.updateParticipation(id, { banned }),
  payload: { id },
})

export const participationsSelector = loadableSelector("participations")
