// @flow
import * as React from "react"
import { Field } from "redux-form/immutable"
import Helmet from "react-helmet"
import _parameterize from "parameterize"
import memoize from "shared/utils/memoize"

import Button from "components/Button"
import Panel from "components/Panel"
import { TextField, TextFieldWithPrefix } from "components/fields"

const parameterize = memoize(20)(_parameterize)
const normalize = (value) => parameterize(value)

type Props = {
  handleSubmit: (any) => any,
  submitting: boolean,
  invalid: boolean,
  change: (string, any) => any,
}

export default class BlogPost extends React.Component<Props> {
  onTitleChange = (_event: any, value: string) => {
    this.props.change("url_title", parameterize(value))
  }

  render() {
    const { invalid, handleSubmit, submitting } = this.props

    return (
      <Panel nopadding title="New Post" width="medium">
        <Helmet title="New Post" />
        <form onSubmit={handleSubmit}>
          <Field label="Title" name="title" nomargin component={TextField} autoFocus onChange={this.onTitleChange}>
            <Field
              name="url_title"
              component={TextFieldWithPrefix}
              prefix="https://zindi.africa/blog/"
              normalize={normalize}
              size="small"
              noborder
            />
          </Field>
          <Button type="submit" disabled={submitting || invalid} loading={submitting}>
            Next →
          </Button>
        </form>
      </Panel>
    )
  }
}
