// @flow
import { type SubmissionData } from 'store/reducers/submissions'
import type { FullCompetitionData } from 'store/reducers/fullCompetitions'
import { type LocationShape } from 'react-router-dom'
import * as React from 'react'
import Helmet from 'react-helmet'

import memoize from 'shared/utils/memoize'
import Button from 'components/Button'
import Panel from 'components/Panel'
import SearchInput from 'components/SearchInput'
import DataTable, { bindSortableHeaderToProps, renderers } from 'components/DataTable'
import Pagination from 'components/Pagination'

import s from './Submissions.scss'

const FRONTEND_URL = process.env.FRONTEND_URL || ''

type Props = {
  competition: FullCompetitionData,
  competitionId: number,
  submissions: ?(SubmissionData[]),
  submissionsLoading: boolean,
  page: number,
  pages: ?number,
  getPageLink: (number) => LocationShape,
  sortBy: string,
  sortReversed: string,
  getSortLink: (string, ?boolean) => LocationShape,
  search?: string,
  setSearch: (string) => any,
  children?: React.Node,
  setBanned: (number, boolean) => Promise<*>,
}

type ExtraProps = {
  setBanned: (number, boolean) => Promise<*>,
}

class Buttons extends React.PureComponent<{
  row: SubmissionData,
  extraProps: ExtraProps,
}> {
  render() {
    const {
      row: { banned },
    } = this.props

    return (
      <React.Fragment>
        <Button size="small" kind={banned ? 'primary' : 'danger'} onClick={this.onClick} outline promised>
          {banned ? 'Restore' : 'Disqualify'}
        </Button>
      </React.Fragment>
    )
  }

  onClick = () => {
    const { row } = this.props
    const newBanned = !row.banned

    return this.props.extraProps.setBanned(row.id, newBanned)
  }
}

const COLUMNS = [
  {
    key: 'id',
    width: 66,
    render: (id) => <span className={s.id}>{id}</span>,
  },
  {
    key: 'chosen',
    title: 'selected',
    width: 100,
    render: (chosen) => <input type="checkbox" disabled checked={chosen} />,
  },
  {
    key: 'date',
    width: 100,
    render: (_, { created_at }) => renderers.date(created_at),
  },
  {
    key: 'username',
    width: 100,
    flexible: true,
    sortable: false,
    render: (username, row, extraProps) => {
      if (row.participant_type === 'User') {
        return (
          <a href={`${FRONTEND_URL}/users/${username}`} target="_blank" rel="noopener noreferrer">
            {row.user.username}
          </a>
        )
      }

      if (row.participant_type === 'Team') {
        return (
          <a
            href={[
              `${FRONTEND_URL}`,
              `competitions`,
              `${extraProps.competitionUrlTitle}`,
              'leaderboard/teams',
              `${row.team.url_title}`,
            ].join('/')}
            target="_blank"
            rel="noopener noreferrer"
          >
            TEAM {row.team.title}
          </a>
        )
      }

      return username
    },
  },
  {
    key: 'status',
    width: 100,
    render: (status, { status_description, banned }) => {
      if (banned) {
        return <span className={s.failure}>Disqualified</span>
      } else if (status === 'failure') {
        return <span className={s.failure}>{status_description}</span>
      }

      return status
    },
  },
  {
    key: 'public_score',
    width: 100,
    render: (public_score) => <span title={public_score}>{public_score}</span>,
  },
  {
    key: 'private_score',
    width: 100,
    render: (private_score) => <span title={private_score}>{private_score}</span>,
  },
  {
    key: 'rescoring_public_score',
    width: 160,
    render: (rescoring_public_score) => <span title={rescoring_public_score}>{rescoring_public_score}</span>,
  },
  {
    key: 'rescoring_private_score',
    width: 160,
    render: (rescoring_private_score) => <span title={rescoring_private_score}>{rescoring_private_score}</span>,
  },
  {
    key: 'comment',
    sortable: false,
    width: 150,
  },
  {
    key: 'file',
    sortable: false,
    width: 100,
    render: (file) => <a href={file}>Download</a>,
  },
  {
    key: 'banned',
    label: 'disqualified',
    width: 150,
    align: 'right',
    render: (_, row, extraProps) => <Buttons row={row} extraProps={extraProps} />,
  },
]

export default class Submissions extends React.PureComponent<Props> {
  cacheExtraProps = memoize(1)((props) => props)

  render() {
    const {
      submissions,
      submissionsLoading,
      page,
      pages,
      getPageLink,
      sortBy,
      sortReversed,
      getSortLink,
      search,
      setSearch,
      children,
      setBanned,
      competitionId,
      competition,
    } = this.props

    const SortableHeader = bindSortableHeaderToProps({
      sortBy,
      sortReversed,
      getSortLink,
    })

    const extraProps: ExtraProps = this.cacheExtraProps({
      setBanned,
      competitionId,
      competitionUrlTitle: competition.url_title,
    })

    return (
      <div>
        <Helmet title="Competitions" />
        <Panel nopadding>
          <SearchInput value={search} setValue={setSearch} />
        </Panel>
        <Panel nopadding>
          <DataTable
            keyColumn="id"
            data={submissions}
            columns={COLUMNS}
            headerComponent={SortableHeader}
            loading={submissionsLoading}
            extraProps={extraProps}
            bodyClassName={s.body}
          />
        </Panel>
        <Pagination page={page} pages={pages} getPageLink={getPageLink} />
        {children}
      </div>
    )
  }
}
