// @flow
import { handleActions, combineActions } from "redux-actions"

import { SIGNIN, SIGNUP, SIGNOUT, RESET_PASSWORD, AUTH_ERROR, OTP } from "store/actionTypes"

const initialState = {
  auth_token: null,
}

export default handleActions(
  {
    [combineActions(SIGNIN.SUCCESS, SIGNUP.SUCCESS, RESET_PASSWORD.SUCCESS)]: (
      state,
      {
        payload: {
          result: {
            data: { auth_token, admin },
          },
        },
      }
    ) => ({ ...state, auth_token, signed_in: !admin.otp_enabled }),

    [OTP.SUCCESS]: (state, {
      payload: {
        result: {
          data: { auth_token },
        },
      },
    }) => ({ ...state, auth_token, signed_in: true }),

    [AUTH_ERROR]: (state) => ({ ...state, auth_token: null }),

    [SIGNOUT.SUCCESS]: (state) => ({ ...state, auth_token: null }),
  },
  initialState
)
