// @flow
import { compose, withState, withPropsOnChange } from "recompose"

export default ({ dataKey, loadingKey }: { dataKey: string, loadingKey: string }) => {
  const cacheKey = `${dataKey}Cached`
  const updateKey = `updateCached${dataKey}`

  return compose(
    withState(cacheKey, updateKey, (props) => props[dataKey]),
    withPropsOnChange([dataKey, loadingKey, cacheKey], (props) => {
      const data = props[dataKey]
      const loading = props[loadingKey]
      const cached = props[cacheKey]
      const update = props[updateKey]

      if (data !== undefined && !loading) {
        update(data)
      }

      return { [dataKey]: cached }
    })
  )
}
