// @flow
import {
  getParticipations,
  participationsSelector,
  updateParticipation,
  updateParticipationBanned,
} from "store/reducers/participations"
import { connect } from "react-redux"
import Leaderboard from "views/Competition/Leaderboard"
import withPagination from "shared/decorators/withPagination"
import withSorting from "shared/decorators/withSorting"
import withSearch from "shared/decorators/withSearch"

import { compose, withPropsOnChange } from "recompose"
import preload from "decorators/preload"

export default compose(
  withPagination({ per_page: 50 }),
  withSorting({ default: "private_rank" }),
  withSearch,
  withPropsOnChange(
    ["competitionId", "page", "per_page", "sortBy", "sortReversed", "search"],
    ({ competitionId, page, per_page, sortBy, sortReversed, search }) => ({
      query: {
        competitionId,
        page,
        per_page,
        search,
        sort_by: sortBy,
        sort_reversed: sortReversed,
      },
    })
  ),
  connect(
    (state, props) => ({
      participations: participationsSelector(state, props.query),
    }),
    (dispatch, props) => ({
      getParticipations: () => dispatch(getParticipations(props.query)),
      setBanned: (id: string, banned: boolean) => dispatch(updateParticipationBanned(id, banned)),
      updateComment: (id: string, value: ?string) => dispatch(updateParticipation(id, { admin_comment: value })),
      updatePublicScoreManualOverride: (id: string, value: ?number) =>
        dispatch(updateParticipation(id, { best_public_score_manual_override: value })),
      updatePrivateScoreManualOverride: (id: string, value: ?number) =>
        dispatch(updateParticipation(id, { best_private_score_manual_override: value })),
    })
  ),
  preload({
    participations: {
      load: "getParticipations",
      optional: true,
      replaceWithData: false,
    },
  }),
  withPropsOnChange(["participations"], ({ participations }) => ({
    pages: participations.pages,
    participations: participations.data,
    participationsLoading: participations.loading,
  }))
)(Leaderboard)
