// @flow
import * as React from "react"
import c from "classnames"
import s from "./Checkbox.scss"

type Props = {
  value?: string,
  disabled?: boolean,
  className?: string,
  id?: string,
  invalid?: boolean,
  autoFocus?: boolean,
  onChange: (any) => void,
  onBlur: (any) => void,
  onFocus: (any) => void,
}

export default class TextInput extends React.PureComponent<Props> {
  input: ?HTMLInputElement

  componentDidMount() {
    if (this.props.autoFocus && this.input) {
      this.input.focus()
    }
  }

  onChange = () => {
    if (this.input) this.props.onChange(this.input.checked)
  }

  onBlur = () => {
    if (this.input) this.props.onBlur(this.input.checked)
  }

  onFocus = () => {
    if (this.input) this.props.onFocus(this.input.checked)
  }

  render() {
    const { id, value, className, disabled, invalid } = this.props

    return (
      <input
        type="checkbox"
        id={id}
        ref={(ref) => {
          this.input = ref
        }}
        disabled={disabled}
        value={undefined}
        checked={value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        className={c(s.base, invalid && s.invalid, className)}
      />
    )
  }
}
