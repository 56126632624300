// @flow
import * as React from "react"

import type { Column } from "./index"
import Cell from "./Cell"

export default class DataCell<T: {}, E: {}> extends React.PureComponent<{
  data: T,
  column: Column<T, E>,
  extraProps: E,
}> {
  render() {
    const { data, column, extraProps } = this.props
    const { key, render } = column

    let value = key && data ? data[key] : null

    if (render) {
      value = render(value, data, extraProps)
    } else if (value === undefined || value === null) {
      value = "—"
    }

    return <Cell column={column}>{value}</Cell>
  }
}
