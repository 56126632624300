// @flow
import * as React from 'react'
import { rescoreLeaderboard, publishRescoringResults } from 'store/reducers/fullCompetitions'
import { useDispatch } from 'react-redux'
import Button from 'components/Button'

export default function Misc({ competition }) {
  const [loadingRequestRescore, setLoadingRequestRescore] = React.useState(false)
  const [loadingPublishRescore, setLoadingPublishRescore] = React.useState(false)
  const dispatch = useDispatch()

  React.useEffect(() => {
    setLoadingRequestRescore(false)
    setLoadingPublishRescore(false)
  }, [competition])

  const requestRescore = React.useCallback(() => {
    setLoadingRequestRescore(true)
    dispatch(rescoreLeaderboard(competition.id)).then(
      () => setLoadingRequestRescore(false),
      () => setLoadingRequestRescore(false),
    )
  }, [dispatch, rescoreLeaderboard, competition.id])

  const publishRescore = React.useCallback(() => {
    setLoadingPublishRescore(true)
    dispatch(publishRescoringResults(competition.id)).then(
      () => setLoadingPublishRescore(false),
      () => setLoadingPublishRescore(false),
    )
  }, [dispatch, publishRescoringResults, competition.id])

  const updatingRequestRescore = loadingRequestRescore || !competition.can_request_leaderboard_rescoring
  const updatingPublishRescore = loadingPublishRescore || !competition.can_request_rescoring_results_publishing

  return (
    <>
      <Button kind="primary" disabled={updatingRequestRescore} onClick={requestRescore}>
        {updatingRequestRescore ? 'Rescoring ...' : 'Rescore without publishing to users'}
      </Button>
      <br />
      <br />
      <Button kind="primary" disabled={updatingPublishRescore} onClick={publishRescore}>
        {loadingPublishRescore ? 'Publishing ...' : 'Publish rescoring results'}
      </Button>
    </>
  )
}
