// @flow
import * as React from "react"
import c from "classnames"

import s from "./File.scss"

type Props = {
  children: React.Node,
  onChange: (files: File[]) => any,
  multiple?: boolean,
  accept?: string,
  className?: string,
  onDragEnter?: () => any,
  onDragLeave?: () => any,
}

type State = {
  dragOver: boolean,
}

const toArray = (files) => {
  const array = []

  for (let i = 0; i < files.length; i += 1) {
    array.push(files[i])
  }

  return array
}

const hasParent = (element: any, parent) => {
  let current = element

  while (current) {
    if (current === parent) {
      return true
    }
    current = current.parentNode
  }

  return false
}

export default class FileInput extends React.Component<Props, State> {
  input: ?HTMLInputElement
  label: ?HTMLLabelElement

  state = { dragOver: false }

  componentDidMount() {
    window.addEventListener("dragenter", this.onDragEnter)
  }

  componentWillUnmount() {
    window.removeEventListener("dragenter", this.onDragEnter)
  }

  setDragOver = (status: boolean) =>
    this.setState(({ dragOver }) => {
      const { onDragEnter, onDragLeave } = this.props

      if (status !== dragOver) {
        if (status && onDragEnter) onDragEnter()
        if (!status && onDragLeave) onDragLeave()
      }

      return { dragOver: status }
    })

  onDragEnter = (e: SyntheticDragEvent<*>) => this.setDragOver(hasParent(e.target, this.label))

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget

    this.setDragOver(false)
    this.props.onChange(toArray(files))
  }

  clearValue = () => {
    if (this.input) {
      this.input.value = ""
    }
  }

  render() {
    const { children, className, ...props } = this.props
    const { dragOver } = this.state

    return (
      <label
        className={c(s.container, dragOver && s.dragOver, className)}
        ref={(ref) => {
          this.label = ref
        }}
      >
        <input
          {...props}
          className={s.input}
          type="file"
          onChange={this.handleChange}
          onClick={this.clearValue}
          ref={(ref) => {
            this.input = ref
          }}
        />
        {children}
      </label>
    )
  }
}

// onClick={ () => { this._reactFileReaderInput.value = null; } }
