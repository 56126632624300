// @flow
import * as React from "react"
import { Link, type LocationShape } from "react-router-dom"

type Props = {
  to: string | LocationShape,
}

export default (props: Props) => {
  const { to } = props

  const newTo: LocationShape = typeof to === "string" ? { pathname: to } : to

  newTo.search = window.location.search

  return <Link {...props} to={newTo} />
}
