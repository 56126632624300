import decorateComponentWithProps from "decorate-component-with-props"
// TODO
// import createStore from 'draft-js-inline-toolbar-plugin/lib/utils/createStore'
// ↓ until https://github.com/draft-js-plugins/draft-js-plugins/pull/1209 is merged
import createStore from "../draft-js-inline-toolbar-plugin/utils/createStore"

import Toolbar from "./components/Toolbar"

const getNewBlockKey = (editorState) => {
  const selection = editorState.getSelection()

  if (!selection.isCollapsed()) {
    return null
  }
  if (!selection.getHasFocus()) {
    return null
  }

  const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey())

  if (block.get("text").replace(/\s/g, "")) {
    return null
  }
  if (block.get("type") !== "unstyled") {
    return null
  }

  return block.getKey()
}

export default (config = {}) => {
  const store = createStore({
    newBlockKey: false,
  })

  const { theme, structure = [] } = config

  const toolbarProps = {
    store,
    structure,
    theme,
  }

  return {
    initialize: ({ getEditorState, setEditorState }) => {
      store.updateItem("getEditorState", getEditorState)
      store.updateItem("setEditorState", setEditorState)
    },
    // Re-Render the text-toolbar on selection change
    onChange: (editorState) => {
      store.updateItem("newBlockKey", getNewBlockKey(editorState))

      return editorState
    },
    NewBlockToolbar: decorateComponentWithProps(Toolbar, toolbarProps),
  }
}
