// @flow
import type { ApiResponse, Action } from "shared/store/types"
import { handleActions, combineActions } from "redux-actions"
import { loadable, loadableSelector } from "store/enhancers/loadable"
import * as API from "api"
import { GET_JOB, GET_COUNTRIES, CREATE_JOB, UPDATE_JOB, DELETE_JOB } from "store/actionTypes"

export type FullJobData = {
  +id: number,
  +title: string,
  +company: string,
  +published: boolean,
  +publish_time: Date,
  +country: string,
  +city: string,
  +remote: true,
  +allow_online_applications: true,
  +closing_time: Date,
  +experience: string,
  +employment_type: string,
  +duration: string,
  +company_size: string,
  +industry: string[],
  +data_science_functions: string[],
  +data_type: string[],
  +type_of_problem: string[],
}

export type NewJobData = {
  +title?: string,
  +company?: string,
  +company_url?: string,
  +publish_time?: boolean,
  +published?: boolean,
  +country?: string,
  +city?: string,
  +remote?: boolean,
  +allow_online_applications?: boolean,
  +content?: {},
  +closing_time?: Date,
  +experience?: string,
  +employment_type?: string,
  +duration?: string,
  +company_size?: string,
  +industry?: string[],
  +data_science_functions?: string[],
  +data_type?: string[],
  +type_of_problem?: string[],
  +image?: File,
}

export type UpdateJobData = NewJobData

export type Query = {
  page: number,
  per_page: number,
  sort_by: string,
  sort_reversed: boolean,
  search: ?string,
}

export default loadable(GET_JOB)(
  handleActions(
    {
      [combineActions(CREATE_JOB.SUCCESS, UPDATE_JOB.SUCCESS)]: (
        state: any,
        {
          payload: {
            result: { data },
          },
        }: ApiResponse<JobData>
      ) => ({
        queries: {
          ...state.queries,
          [data.id]: {
            data: data.id,
            loading: false,
            error: null,
            total: undefined,
          },
        },
        data: {
          [data.id]: data,
        },
      }),
      [DELETE_JOB.SUCCESS]: (state: any, id) => ({
        queries: {
          ...state.queries,
          [id]: undefined,
        },
        data: {
          [id]: undefined,
        },
      }),
    },
    {}
  )
)

export const getJob = (query: string): Action => ({
  types: GET_JOB,
  api: API.getJob(query),
  payload: { query },
})

export const getCountries = (): Action => ({
  types: GET_COUNTRIES,
  api:   API.getCountries(),
})

export const createJob = (data: NewJobData): Action => ({
  types: CREATE_JOB,
  api: API.createJob(data),
  payload: { data },
})

export const updateJob = (id: string, data: UpdateJobData): Action => ({
  types: UPDATE_JOB,
  api: API.updateJob(id, data),
  payload: { id, data },
})

export const deleteJob = (id: string): Action => ({
  types: DELETE_JOB,
  api: API.deleteJob(id),
  payload: { id },
})

export const jobSelector = loadableSelector("fullJobs")
