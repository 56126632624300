// @flow
import * as React from "react"
import { composeDecorators } from "draft-js-plugins-editor"
import createImagePlugin from "draft-js-image-plugin"
import createFocusPlugin from "draft-js-focus-plugin"
import createBlockDndPlugin from "draft-js-drag-n-drop-plugin"

import dropFilesPlugin from "../plugins/dropFilesPlugin"
import { buttonWithProps } from "../plugins/newBlockToolbar/components/Button"
import Configuration from "../configuration"

import focusStyles from "../styles/focus.scss"
import imageStyles from "../styles/image.scss"
import mergeStyles from "../utils/mergeStyles"

type Config = {
  upload: ({}) => Promise<*>,
  styles?: {},
  button: React.Node,
}

export default (config: Config) => ({ addPlugin, addNewBlockButton }: Configuration) => {
  if (!config.upload) {
    throw new Error("Please provide upload function")
  }

  const focusPlugin = createFocusPlugin({
    theme: mergeStyles(focusStyles, config.styles),
  })

  const blockDndPlugin = createBlockDndPlugin()
  const decorator = composeDecorators(focusPlugin.decorator, blockDndPlugin.decorator)
  const imagePlugin = createImagePlugin({
    decorator,
    theme: mergeStyles(imageStyles, config.styles),
  })

  addPlugin(blockDndPlugin)
  addPlugin(focusPlugin)
  addPlugin(imagePlugin)
  addPlugin(
    dropFilesPlugin({
      uploadImage: config.upload,
      addImage: imagePlugin.addImage,
    })
  )

  addNewBlockButton(
    buttonWithProps({
      children: config.button,
      onClick: () => {
        global.alert("Just drop images into the editor")
      },
    })
  )
}
