// @flow
import { decorator, type Config } from "shared/preloads"

import Loading from "components/Loading"
import LoadingError from "components/LoadingError"

const options = {
  components: {
    loading: Loading,
    error: LoadingError,
  },
}

export default (config: Config) => decorator(config, options)
