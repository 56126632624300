// @flow
import Layout from "views/Layout"
import { connect } from "react-redux"
import { getMe, signOut } from "store/reducers/me"
import { compose } from "recompose"
import waitFor from "shared/decorators/waitFor"

export default compose(
  connect(
    ({ me }) => ({ me }),
    (dispatch) => ({
      getMe: () => dispatch(getMe()),
      signOut: () => dispatch(signOut()),
    })
  ),
  waitFor("me")
)(Layout)
