/* @flow */
import { includes } from "lodash"
import regexes from "./regexes"

export function extractMentions(text: string) {
  if (!text || !text.match(regexes.signs)) {
    return []
  }

  const tags = []

  text.replace(regexes.mention, (match, mention, start) => {
    const end = start + mention.length + 1

    tags.push({
      username: mention,
      indices: [start, end],
    })

    return match
  })

  return tags
}

type Config = {
  disableIn: string[],
}

export default ({ disableIn }: Config) => (contentBlock: Object, callback: Function) => {
  const text = contentBlock.getText()
  const type = contentBlock.getType()

  if (disableIn && includes(disableIn, type)) return

  const results = extractMentions(text)

  results.forEach((mention) => {
    callback(mention.indices[0], mention.indices[1])
  })
}
