// @flow
import { handleActions, combineActions } from "redux-actions"
import * as API from "api"

import { GET_ME, SIGNIN, OTP, SIGNOUT, REQUEST_PASSWORD_RESET, RESET_PASSWORD, AUTH_ERROR } from "store/actionTypes"

import type { Action, ApiResponse, ApiError, ApiErrorResponse } from "shared/store/types"

export type UserData = {
  +email: string,
  +username: string,
  +unconfirmed?: boolean,
  +avatar: string,
  +score: number,
  +rank: number,
  +created_at: number,
}

export type UpdatableUserData = {
  email?: string,
  username?: string,
  avatar?: File,
}

type State = {
  +data: ?UserData,
  +loading: boolean,
  +error: ApiError | null,
}

type ApiResult = ApiResponse<{ admin: UserData }>

const initialState: State = {
  data: undefined,
  loading: false,
  error: null,
}

export default handleActions(
  {
    [combineActions(SIGNIN.SUCCESS, GET_ME.SUCCESS, RESET_PASSWORD.SUCCESS, OTP.SUCCESS)]: (
      state: State,
      {
        payload: {
          result: {
            data: { admin },
          },
        },
      }: ApiResult
    ): State => ({ loading: false, error: null, data: admin }),

    [AUTH_ERROR]: (): State => ({ loading: false, error: null, data: null }),

    [SIGNOUT.SUCCESS]: (state: State): State => ({ ...state, data: null, loading: false }),

    [GET_ME.START]: (state: State): State => ({ ...state, loading: true, error: null }),

    [GET_ME.FAILURE]: (state: State, { payload: { error } }: ApiErrorResponse): State => ({
      ...state,
      loading: false,
      error,
    }),
  },
  initialState
)

export const getMe = () => (dispatch: (Action) => any, getState: () => any): Promise<any> => {
  const { auth_token } = getState().cookie

  if (!auth_token) {
    return dispatch({
      types: GET_ME,
      promise: Promise.resolve({ data: { admin: null } }),
    })
  }

  return dispatch({
    types: GET_ME,
    api: API.getMe(),
  })
}

export const signIn = (email: string, password: string): Action => ({
  types: SIGNIN,
  api: API.signIn(email, password),
  payload: { email, password },
})

export const submitOTP = (otp: string): Action => ({
  types:   OTP,
  api:     API.submitOTP(otp),
  payload: { otp },
})

export const signOut = (): Action => ({
  types: SIGNOUT,
  api: API.signOut(),
})

export const requestPasswordReset = (email: string): Action => ({
  types: REQUEST_PASSWORD_RESET,
  api: API.requestPasswordReset(email),
})

export const resetPassword = (token: string, password: string): Action => ({
  types: RESET_PASSWORD,
  api: API.resetPassword(token, password),
})
