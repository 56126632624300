// @flow
import * as React from "react"
import type { FullCompetitionData } from "store/reducers/fullCompetitions"
import { Field } from "redux-form/immutable"
import _parameterize from "parameterize"
import memoize from "shared/utils/memoize"

import Button from "components/Button"
import { TextField, TextFieldWithPrefix } from "components/fields"
import { getCompetitionPathFromKind } from "containers/concerns/getCompetitionKind"

const parameterize = memoize(20)(_parameterize)
const normalize = (value) => parameterize(value)

type Props = {
  competition: FullCompetitionData,
  handleSubmit: (any) => any,
  submitting: boolean,
  invalid: boolean,
  change: (string, any) => any,
}

export default class NewPageForm extends React.Component<Props> {
  onTitleChange = (_event: any, value: string) => {
    this.props.change("url_title", parameterize(value))
  }

  render() {
    const { competition, invalid, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit} style={{ minWidth: 700 }}>
        <h1>New Page</h1>
        <Field label="Title" name="title" nomargin component={TextField} autoFocus onChange={this.onTitleChange} />
        <Field
          name="url_title"
          component={TextFieldWithPrefix}
          prefix={`zindi.africa/${getCompetitionPathFromKind(competition.kind)}/${competition.url_title}/`}
          normalize={normalize}
          size="small"
          noborder
        />
        <Button type="submit" disabled={submitting || invalid} loading={submitting}>
          Create
        </Button>
      </form>
    )
  }
}
