// @flow
import * as React from "react"
import ReactAutosuggest from "react-autosuggest"
import { pick, omit, mergeWith } from "lodash"
import TextInput from "components/inputs/Text"

import c from "classnames"
import s from "./Base.scss"

const renderInputComponent = (props: {}) => <TextInput {...props} />

export const REACT_AUTOSUGGEST_PROPS = [
  "suggestions",
  "onSuggestionsFetchRequested",
  "onSuggestionsClearRequested",
  "getSuggestionValue",
  "renderSuggestion",
  "inputProps",
  "onSuggestionSelected",
  "onSuggestionHighlighted",
  "shouldRenderSuggestions",
  "alwaysRenderSuggestions",
  "highlightFirstSuggestion",
  "focusInputOnSuggestionClick",
  "multiSection",
  "renderSectionTitle",
  "getSectionSuggestions",
  "renderInputComponent",
  "renderSuggestionsContainer",
  "theme",
  "hideFullMatch",
  "showAllWhenEmpty",
]

export type Suggestion = { value: string }

type Theme = { [string]: string }

type Props = {
  id?: string,
  inputProps?: {},
  onChange: (?string) => any,
  theme?: Theme,
  renderInputComponent: ({}) => React.Node,
  onSuggestionSelected: (any, any) => any,
}

type State = {
  suggestionHighlighted: boolean,
}

const getValue = ({ value }) => value

export default class Autosuggest extends React.Component<Props, State> {
  input: ?HTMLInputElement

  static defaultProps = {
    highlightFirstSuggestion: true,
    renderInputComponent,
    renderSuggestion: getValue,
    getSuggestionValue: getValue,
  }

  setValue(value: string) {
    const { onChange } = this.props

    if (onChange) onChange(value)
  }

  onSuggestionSelected = (e: SyntheticEvent<*>, other: { suggestionValue: string, method: string }) => {
    const { suggestionValue, method } = other

    if (method === "enter") {
      e.stopPropagation()
      e.preventDefault()
    }

    this.setValue(suggestionValue)

    if (this.props.onSuggestionSelected) {
      this.props.onSuggestionSelected(e, other)
    }
  }

  onChange = (e: SyntheticEvent<*>, { newValue }: { newValue: string }) => {
    this.setValue(newValue)
  }

  setInputRef = (ref: ?HTMLInputElement) => {
    this.input = ref
  }

  renderInputComponent = (props: {}) => this.props.renderInputComponent({ ...props, ref: this.setInputRef })

  focus = () => {
    if (this.input) {
      this.input.focus()
    }
  }

  render() {
    let theme = s
    if (this.props.theme) {
      theme = { ...s }
      theme = mergeWith(theme, this.props.theme, (a, b) => c(a, b))
    }

    const suggestProps = {
      id: this.props.id,
      ...pick(this.props, REACT_AUTOSUGGEST_PROPS),
      onSuggestionSelected: this.onSuggestionSelected,
      renderInputComponent: this.renderInputComponent,
      theme,
    }

    const inputProps = {
      id: this.props.id,
      ...this.props.inputProps,
      ...omit(this.props, REACT_AUTOSUGGEST_PROPS),
      onChange: this.onChange,
    }

    return <ReactAutosuggest {...suggestProps} inputProps={inputProps} />
  }
}
