// @flow
import { signIn } from "store/reducers/me"
import { reduxForm } from "redux-form/immutable"
import SignIn from "views/SignIn"

import { compose } from "recompose"
import validate from "shared/utils/validate"
import handleFormErrors from "shared/utils/handleFormErrors"

export default compose(
  reduxForm({
    form: "signin",
    validate: validate({
      email: ["required", "email"],
      password: ["required"],
    }),
    onSubmit: (values, dispatch) =>
      dispatch(signIn(values.get("email"), values.get("password"))).catch(handleFormErrors),
    onSubmitSuccess: (result, _dispatch, props) => {
      if (result.data.admin.otp_enabled) {
        props.history.push("/auth/signin/otp")
      } else {
        props.history.push("/")
      }
    },
  })
)(SignIn)
