// @flow
import * as React from "react"
import { compose } from "recompose"
import Table from "./Table"
import withLoadingState from "./withLoadingState"

export { default as renderers } from "./renderers"
export { default as SortableHeader, bindToSortProps as bindSortableHeaderToProps } from "./SortableHeader"

export type RenderFn<T, E> = (any, T, extraProps: E) => React.Node
export type Column<T, E> = {
  key: string,
  label?: string,
  render?: RenderFn<T, E>,
  width?: number,
  flexible?: boolean,
  sortable?: boolean,
  align?: "right" | "center",
}

export type Columns<T, E> = Column<T, E>[]

export default compose(withLoadingState)(Table)
