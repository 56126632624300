// @flow
import * as React from "react"
import Editor from "draft-js-plugins-editor"
import { map } from "lodash"
import { EditorState, RichUtils } from "draft-js"

require("./styles/base.scss")

type Configuration = {
  props: {
    plugins: {}[],
  },
  components: React.Component<*>[],
}

type Props = {
  value: EditorState,
  onChange?: (EditorState) => any,
  configuration: Configuration,
  placeholder?: string,
  autofocus: boolean,
}

type State = {
  value: ?EditorState,
}

export default class RichEditor extends React.Component<Props, State> {
  editor: ?any

  state = {
    value: this.props.value,
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.state.value) {
      this.onValuePropChange(nextProps.value)
    }

    if (nextProps.autofocus !== this.props.autofocus && nextProps.autofocus) {
      this.focus()
    }
  }

  componentDidMount() {
    if (this.props.autofocus) {
      this.focus()
    }
  }

  onValuePropChange = (newValue: EditorState) => {
    this.setState(({ value }) => {
      if (!value) {
        return { value: newValue }
      }

      let valueToSet = newValue

      if (!valueToSet) {
        valueToSet = EditorState.createEmpty()
      }

      const newContentState = valueToSet.getCurrentContent()
      const newEditorState = EditorState.push(value, newContentState, "move-block")
      return { value: newEditorState }
    })
  }

  onChange = (value: ?EditorState) => {
    this.setState(() => ({ value }))

    if (this.props.onChange && value) {
      this.props.onChange(value)
    }
  }

  focus = () => {
    if (this.editor) {
      this.editor.focus()
    }
  }

  handleKeyCommand = (command: string) => {
    const { value, onChange } = this.props

    if (value) {
      const newState = RichUtils.handleKeyCommand(value, command)

      if (newState) {
        if (onChange) onChange(newState)
        return "handled"
      }
    }

    return "not-handled"
  }

  render() {
    const { value, onChange, configuration, placeholder } = this.props

    if (!value || !onChange) return null

    return (
      <React.Fragment>
        <Editor
          ref={(ref) => {
            this.editor = ref
          }}
          editorState={this.state.value}
          handleKeyCommand={this.handleKeyCommand}
          onChange={this.onChange}
          placeholder={placeholder}
          {...configuration.props}
        />
        {map(configuration.components, (PluginComponent, index) => (
          <PluginComponent key={index} />
        ))}
      </React.Fragment>
    )
  }
}
