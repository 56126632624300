// @flow
import { reduxForm } from "redux-form/immutable"
import { withPropsOnChange, compose } from "recompose"
import { connect } from "react-redux"
import { includes } from "lodash"

import { uploadImageAttachment } from "store/reducers/misc"
import { updatePage, deletePage } from "store/reducers/fullCompetitions"
import validate from "shared/utils/validate"
import handleFormErrors from "shared/utils/handleFormErrors"

import { confirm } from "views/Prompts"
import { REQUIRED_PAGES } from "config"
import { serialize, parse } from "components/inputs/RichEditor"

import Page from "views/Competition/Page"

export default compose(
  connect(
    () => ({}),
    (dispatch, props) => ({
      uploadImageAttachment: (image) => dispatch(uploadImageAttachment("CompetitionPage", props.page.id, image)),
      deletePage: () =>
        confirm("Delete page?")
          .then(() => dispatch(deletePage(props.competition.id, props.page.id)))
          .then(() => props.afterDelete && props.afterDelete()),
    })
  ),
  withPropsOnChange(["page", "contentOnly"], ({ page, contentOnly }) => {
    const pageRequired = includes(REQUIRED_PAGES, page.url_title)

    return {
      initialValues: {
        ...page,
        content: parse(page.content),
      },
      pageRequired,
      contentOnly: typeof contentOnly === "undefined" ? pageRequired : contentOnly,
      form: `competition_page_${page.id}`,
    }
  }),
  reduxForm({
    enableReinitialize: true,
    validate: validate({
      title: ["required"],
      url_title: ["required"],
      content: ["required"],
    }),
    onSubmit: (values, dispatch, props) =>
      dispatch(
        updatePage(
          props.competition.id,
          props.page.id,
          values.set("content", serialize(values.get("content"))).toJSON()
        )
      ).catch(handleFormErrors),
  })
)(Page)
