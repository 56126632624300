// @flow
import type { Action } from 'shared/store/types'
import { handleActions } from 'redux-actions'
import { loadable, loadableSelector } from 'store/enhancers/loadable'
import * as API from 'api'
import { GET_DISCUSSION } from 'store/actionTypes'

export type FullDiscussionData = {
  +id: number,
}

type Query = string

export default loadable(GET_DISCUSSION)(handleActions({}, {}))

export const getDiscussion = (query: Query): Action => ({
  types: GET_DISCUSSION,
  api: API.getDiscussion(query),
  payload: { query },
})

export const discussionSelector = loadableSelector('fullDiscussions')
