// @flow
import { handleActions, combineActions } from 'redux-actions'
import * as API from 'api'
import { map, omit, each, without, pick } from 'lodash'
import { createSelector } from 'reselect'

import {
  GET_COMPETITION,
  GET_COMPETITION_BENCHMARK_SCORES,
  UPDATE_COMPETITION,
  DELETE_COMPETITION,
  CREATE_PAGE,
  UPDATE_PAGE,
  DELETE_PAGE,
  CREATE_DATAFILE,
  UPDATE_DATAFILE,
  DELETE_DATAFILE,
  REORDER_PAGES,
  SEAL_COMPETITION,
  UPDATE_COMPETITION_BEST_SCORES,
  RESCORE_LEADERBOARD,
  PUBLISH_RESCORING_RESULTS
} from 'store/actionTypes'

import type { ApiError, Action, ApiResponse } from 'shared/store/types'

export type BasicCompetitionData = {
  +id: string,
  +kind: string,
  +title: string,
  +url_title: string,
  +subtitle: string,
  +reward: string,
  +points_reward: number,
  +start_time: number,
  +end_time: number,
  +published: boolean,
  +published_at: number,
  +deleted: boolean,
  +error_metric: string,
  +submissions_per_day_limit: ?number,
  +default_submissions_per_day_limit: number,
  +total_submissions_limit: ?number,
  +tags: string[],
  +show_submission_count: boolean,
  +secret_code_required: boolean,
  +secret_code: ?string,
  +sealed: boolean,
  +discussions_are_private: boolean,
}

export type PageData = {
  +id: string,
  +title: string,
  +url_title: string,
  +content: {},
  +position: number,
  +published: boolean,
  +created_at: number,
}

export type CreatePageData = {
  +title: string,
  +url_title: string,
}

export type UpdatePageData = {
  +title: string,
  +url_title: string,
  +content: {},
}

export type DatafileData = {
  +id: string,
  +url: string,
  +filesize: number,
  +filename: string,
  +description: string,
  +position: number,
  +created_at: number,
}

export type CreateDatafileData = {
  +file: File,
}

export type UpdateDatafileData = {
  +filename: File,
  +description: string
}

export type FullCompetitionData = BasicCompetitionData & {
  +created_at: number,
  +image: string,
  +private_reference: string,
  +public_reference: string,
  +pages: PageData[],
  +datafiles: DatafileData[],
}

export type NormalizedCompetitionData = BasicCompetitionData & {
  +created_at: number,
  +image: string,
  +pages: string[],
  +datafiles: string[],
}

export type UpdateCompetitionData = {
  +title?: string,
  +url_title?: string,
  +subtitle?: string,
  +reward?: string,
  +points_reward?: number,
  +start_time?: number,
  +end_time?: number,
  +published?: boolean,
  +published_at?: string,
  +error_metric?: string,
  +image?: File,
  +public_reference?: File,
  +private_reference?: File,
  +submissions_per_day_limit?: ?(number | string),
  +total_submissions_limit?: ?(number | string),
  +default_submissions_per_day_limit?: number,
  +tags?: string[],
}

type State = {
  competitions: {
    [string]: NormalizedCompetitionData,
  },
  pages: {
    [string]: PageData,
  },
  datafiles: {
    [string]: DatafileData,
  },
  requests: {
    [string]: {
      +data: ?(number[]),
      +loading: boolean,
      +error: ApiError | null,
    },
  },
}

type ApiCompetitionResponse = {
  ...FullCompetitionData,
  pages: PageData[],
  datafiles: DatafileData[],
}

type ApiResult = ApiResponse<ApiCompetitionResponse, {}, { id: string }>
type CreatePageResult = ApiResponse<PageData, {}, { competitionId: string }>
type CreateDatafileResult = ApiResponse<DatafileData, {}, { competitionId: string }>

export const defaultRequestData = {
  data: undefined,
  loading: false,
  error: null,
}

const initialState: State = {
  competitions: {},
  pages: {},
  datafiles: {},
  requests: {},
}

const normalize = (
  competition: ApiCompetitionResponse,
): {
  competition: NormalizedCompetitionData,
  pages: { [string]: PageData },
  datafiles: { [string]: DatafileData },
} => {
  const pages = {}
  const pageIds: string[] = []
  const datafiles = {}
  const datafileIds: string[] = []

  each(competition.pages, (page) => {
    pages[page.id] = page
    pageIds.push(page.id)
  })

  each(competition.datafiles, (datafile) => {
    datafiles[datafile.id] = datafile
    datafileIds.push(datafile.id)
  })

  return {
    competition: {
      ...omit(competition, 'pages', 'datafiles'), // to please flow
      pages: pageIds,
      datafiles: datafileIds,
    },
    pages,
    datafiles,
  }
}

export default handleActions(
  {
    [GET_COMPETITION.START]: (state: State, { payload: { id } }): State => ({
      ...state,
      requests: {
        ...state.requests,
        [id]: {
          ...defaultRequestData,
          loading: true,
        },
      },
    }),
    [GET_COMPETITION.FAILURE]: (state: State, { payload: { id, error } }): State => ({
      ...state,
      requests: {
        ...state.requests,
        [id]: {
          ...defaultRequestData,
          loading: false,
          error,
        },
      },
    }),
    [combineActions(GET_COMPETITION.SUCCESS, UPDATE_COMPETITION.SUCCESS, UPDATE_COMPETITION_BEST_SCORES.SUCCESS, RESCORE_LEADERBOARD.SUCCESS)]: (
      state: State,
      {
        payload: {
          id,
          result: { data },
        },
      }: ApiResult,
    ): State => {
      const { competition, pages, datafiles } = normalize(data)

      return {
        competitions: {
          ...state.competitions,
          [id]: competition,
        },
        pages: {
          ...state.pages,
          ...pages,
        },
        datafiles: {
          ...state.datafiles,
          ...datafiles,
        },
        requests: {
          ...state.requests,
          [id]: {
            data: id,
            loading: false,
          },
        },
      }
    },

    [GET_COMPETITION_BENCHMARK_SCORES.SUCCESS]: (
      state: State,
      {
        payload: {
          id,
          result: { data },
        },
      }: ApiResult,
    ): State => {
      const toUpdate = pick(data, [
        'benchmark_submission_public_score',
        'benchmark_submission_private_score',
        'benchmark_submission_status',
      ])

      return {
        ...state,
        competitions: {
          ...state.competitions,
          [id]: {
            ...state.competitions[id],
            ...toUpdate,
          },
        },
      }
    },

    [SEAL_COMPETITION.SUCCESS]: (state: State, { payload: { id } }: ApiResult): State => {
      return {
        ...state,
        competitions: {
          ...state.competitions,
          [id]: {
            ...state.competitions[id],
            sealed: true,
          },
        },
      }
    },

    [DELETE_COMPETITION.SUCCESS]: (state: State, { payload: { id } }: ApiResult): State => ({
      ...state,
      competitions: {
        ...state.competitions,
        [id]: {
          ...state.competitions.id,
          deleted: true,
        },
      },
    }),

    [CREATE_PAGE.SUCCESS]: (
      state: State,
      {
        payload: {
          result: { data },
          competitionId,
        },
      }: CreatePageResult,
    ): State => ({
      ...state,
      pages: {
        ...state.pages,
        [data.id]: data,
      },
      competitions: {
        ...state.competitions,
        [competitionId]: {
          ...state.competitions[competitionId],
          pages: [...state.competitions[competitionId].pages, data.id],
        },
      },
    }),

    [UPDATE_PAGE.SUCCESS]: (
      state: State,
      {
        payload: {
          result: { data },
          id,
        },
      }: { payload: { result: { data: PageData }, id: string } },
    ): State => ({
      ...state,
      pages: {
        ...state.pages,
        [id]: data,
      },
    }),

    [DELETE_PAGE.SUCCESS]: (
      state: State,
      { payload: { competitionId, id } }: { payload: { competitionId: string, id: string } },
    ): State => ({
      ...state,
      pages: {
        ...state.pages,
        [id]: undefined,
      },
      competitions: {
        ...state.competitions,
        [competitionId]: {
          ...state.competitions[competitionId],
          pages: without(state.competitions[competitionId].pages, id),
        },
      },
    }),

    [CREATE_DATAFILE.SUCCESS]: (
      state: State,
      {
        payload: {
          result: { data },
          competitionId,
        },
      }: CreateDatafileResult,
    ): State => ({
      ...state,
      datafiles: {
        ...state.datafiles,
        [data.id]: data,
      },
      competitions: {
        ...state.competitions,
        [competitionId]: {
          ...state.competitions[competitionId],
          datafiles: [...state.competitions[competitionId].datafiles, data.id],
        },
      },
    }),

    [UPDATE_DATAFILE.SUCCESS]: (
      state: State,
      {
        payload: {
          result: { data },
          id,
        },
      }: { payload: { result: { data: DatafileData }, id: string } },
    ): State => ({
      ...state,
      datafiles: {
        ...state.datafiles,
        [id]: data,
      },
    }),

    [DELETE_DATAFILE.SUCCESS]: (
      state: State,
      { payload: { competitionId, id } }: { payload: { competitionId: string, id: string } },
    ): State => ({
      ...state,
      datafiles: {
        ...state.datafiles,
        [id]: undefined,
      },
      competitions: {
        ...state.competitions,
        [competitionId]: {
          ...state.competitions[competitionId],
          datafiles: without(state.competitions[competitionId].datafiles, id),
        },
      },
    }),

    [REORDER_PAGES.SUCCESS]: (
      state: State,
      { payload: { competitionId, pageIds } }: { payload: { competitionId: string, pageIds: string[] } },
    ): State => ({
      ...state,
      competitions: {
        ...state.competitions,
        [competitionId]: {
          ...state.competitions[competitionId],
          pages: pageIds,
        },
      },
    }),
  },
  initialState,
)

export const getCompetition = (id: string): Action => ({
  types: GET_COMPETITION,
  api: API.getCompetition(id),
  payload: { id },
})

export const getCompetitionBenchmarkScores = (id: string): Action => ({
  types: GET_COMPETITION_BENCHMARK_SCORES,
  api: API.getCompetition(id),
  payload: { id },
})

export const updateCompetition = (id: string, values: UpdateCompetitionData): Action => ({
  types: UPDATE_COMPETITION,
  api: API.updateCompetition(id, values),
  payload: { id },
})

export const deleteCompetition = (id: string): Action => ({
  types: DELETE_COMPETITION,
  api: API.deleteCompetition(id),
  payload: { id },
})

export const createPage = (competitionId: string, data: CreatePageData): Action => ({
  types: CREATE_PAGE,
  api: API.createPage(competitionId, data),
  payload: { competitionId },
})

export const reorderPages = (competitionId: string, pageIds: string[]): Action => ({
  types: REORDER_PAGES,
  api: API.reorderPages(competitionId, pageIds),
  payload: { competitionId, pageIds },
})

export const updatePage = (competitionId: string, id: string, data: UpdatePageData): Action => ({
  types: UPDATE_PAGE,
  api: API.updatePage(competitionId, id, data),
  payload: { id },
})

export const deletePage = (competitionId: string, id: string): Action => ({
  types: DELETE_PAGE,
  api: API.deletePage(competitionId, id),
  payload: { competitionId, id },
})

export const createDatafile = (competitionId: string, data: CreateDatafileData): Action => ({
  types: CREATE_DATAFILE,
  api: API.createDatafile(competitionId, data),
  payload: { competitionId },
})

export const updateDatafile = (competitionId: string, id: string, data: UpdateDatafileData): Action => ({
  types: UPDATE_DATAFILE,
  api: API.updateDatafile(competitionId, id, data),
  payload: { id },
})

export const deleteDatafile = (competitionId: string, id: string): Action => ({
  types: DELETE_DATAFILE,
  api: API.deleteDatafile(competitionId, id),
  payload: { competitionId, id },
})

export const sealCompetition = (id: string): Action => ({
  types: SEAL_COMPETITION,
  api: API.sealCompetition(id),
  payload: { id },
})

export const updateCompetitionBestScores = (id: string): Action => ({
  types: UPDATE_COMPETITION_BEST_SCORES,
  api: API.updateCompetitionBestScores(id),
  payload: { id },
})
 
export const rescoreLeaderboard = (id: string): Action => ({
  types: RESCORE_LEADERBOARD,
  api: API.rescoreLeaderboard(id),
  payload: { id },
})

export const publishRescoringResults = (id: string): Action => ({
  types: PUBLISH_RESCORING_RESULTS,
  api: API.publishRescoringResults(id),
  payload: { id },
})

export const selectCompetition = createSelector(
  [
    (state) => state.fullCompetitions.requests,
    (state) => state.fullCompetitions.competitions,
    (state) => state.fullCompetitions.pages,
    (state) => state.fullCompetitions.datafiles,
    (_, { competitionId }) => competitionId,
  ],
  (
    requests,
    competitions: { [string]: NormalizedCompetitionData },
    pages,
    datafiles,
    competitionId,
  ): {
    competition: FullCompetitionData,
  } => {
    let competition = requests[competitionId] || defaultRequestData

    if (competition.data) {
      const data = { ...competitions[competition.data] }

      data.pages = map(data.pages, (page) => pages[page])
      data.datafiles = map(data.datafiles, (datafile) => datafiles[datafile])

      competition = {
        ...competition,
        data,
      }
    }

    return { competition }
  },
)
