// @flow
import { getBlogPosts, querySelector } from 'store/reducers/blogPosts'
import { updateBlogPost } from 'store/reducers/fullBlogPosts'
import { connect } from 'react-redux'
import BlogPosts from 'views/BlogPosts'
import withPagination from 'shared/decorators/withPagination'
import withSorting from 'shared/decorators/withSorting'
import withSearch from 'shared/decorators/withSearch'
import showSavingErrors from 'utils/showSavingErrors'

import { compose, withPropsOnChange } from 'recompose'
import preload from 'decorators/preload'

export default compose(
  withPagination({ per_page: 50 }),
  withSorting({ default: 'created_at' }),
  withSearch,
  connect(
    (state, props) => ({
      me: state.me.data,
      ...querySelector(state, props),
    }),
    (dispatch, props) => ({
      getBlogPosts: () =>
        dispatch(
          getBlogPosts({
            page: props.page,
            per_page: props.per_page,
            sort_by: props.sortBy,
            sort_reversed: props.sortReversed,
            search: props.search,
          }),
        ),
      setTheme: (id: string, theme: string) => dispatch(updateBlogPost(id, { theme })).catch(showSavingErrors),
    }),
  ),
  preload({
    blogPosts: {
      load: 'getBlogPosts',
      optional: true,
      replaceWithData: false,
    },
  }),
  withPropsOnChange(['blogPosts'], ({ blogPosts }) => ({
    blogPosts: blogPosts.data,
    blogPostsLoading: blogPosts.loading,
  })),
)(BlogPosts)
