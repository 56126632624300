// @flow
import * as React from "react"
import { map } from "lodash"
import type { Columns } from "./index"

import DataCell from "./DataCell"

import s from "./index.scss"

export default class Row<T: {}, E: {}> extends React.PureComponent<{
  data: T,
  columns: Columns<T, E>,
  extraProps: E,
}> {
  render() {
    const { columns, data, extraProps } = this.props

    return (
      <div className={s.row}>
        {map(columns, (column) => (
          <DataCell key={column.key} data={data} column={column} extraProps={extraProps} />
        ))}
      </div>
    )
  }
}
