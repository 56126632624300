// @flow
import { get } from "lodash"
import { loadable as rawLoadable } from "redux-loadable"

const defaultOptions = {
  getQuery: ({ payload }) => get(payload, ["query"]),
  getData: ({ payload }) => get(payload, ["result", "data"]),
  getCount: ({ payload }) => get(payload, ["result", "meta", "total_count"]),
  getError: ({ payload }) => get(payload, ["error"]),
}

export const loadable = (action, options = {}) =>
  rawLoadable(action, {
    ...defaultOptions,
    ...options,
  })
export { loadableSelector } from "redux-loadable"
