// @flow
import { type AdminData } from "store/reducers/admins"
import { type UserData as MeData } from "store/reducers/me"
import { type LocationShape } from "react-router-dom"
import * as React from "react"
import Helmet from "react-helmet"
import memoize from "shared/utils/memoize"

import Panel from "components/Panel"
import Button from "components/Button"
import { Columns, Column } from "components/Columns"
import LinkWithQuery from "components/LinkWithQuery"
import SearchInput from "components/SearchInput"
import DataTable, { bindSortableHeaderToProps, renderers } from "components/DataTable"
import Pagination from "components/Pagination"

type Props = {
  admins: ?(AdminData[]),
  adminsLoading: boolean,
  page: number,
  pages: ?number,
  getPageLink: (number) => LocationShape,
  sortBy: string,
  sortReversed: string,
  getSortLink: (string, ?boolean) => LocationShape,
  search?: string,
  setSearch: (string) => any,
  children?: React.Node,
  me: MeData,
  setBanned: (number, boolean) => Promise<*>,
}

type ExtraProps = {
  setBanned: (number, boolean) => Promise<*>,
  me: MeData,
}

class Buttons extends React.PureComponent<{
  row: AdminData,
  extraProps: ExtraProps,
}> {
  render() {
    const {
      row: { id, banned },
      extraProps: { me },
    } = this.props

    if (me.id === id) {
      return null
    }

    return (
      <React.Fragment>
        <Button size="small" kind={banned ? "primary" : "danger"} onClick={this.onClick} outline promised>
          {banned ? "Enable" : "Disable"}
        </Button>
      </React.Fragment>
    )
  }

  onClick = () => {
    const { row } = this.props
    const newBanned = !row.banned

    return this.props.extraProps.setBanned(row.id, newBanned)
  }
}

const COLUMNS = [
  {
    key: "email",
    // render: (email, { id }) => (
    //   <LinkWithQuery to={ `/admins/${id}` }>{ email }</LinkWithQuery>
    // ),
    width: 200,
    flexible: true,
  },
  {
    key: "created_at",
    render: renderers.date,
    width: 150,
  },
  {
    key: "banned",
    label: "",
    sortable: false,
    width: 100,
    align: "right",
    render: (_, row, extraProps) => <Buttons row={row} extraProps={extraProps} />,
  },
]

export default class Admins extends React.PureComponent<Props> {
  cacheExtraProps = memoize(1)((props) => props)

  render() {
    const {
      admins,
      adminsLoading,
      page,
      pages,
      getPageLink,
      sortBy,
      sortReversed,
      getSortLink,
      search,
      setSearch,
      children,
      me,
      setBanned,
    } = this.props

    const SortableHeader = bindSortableHeaderToProps({
      sortBy,
      sortReversed,
      getSortLink,
    })

    const extraProps: ExtraProps = this.cacheExtraProps({ setBanned, me })

    return (
      <div>
        <Helmet title="Admins" />
        <Columns>
          <Column>
            <Panel nopadding>
              <SearchInput value={search} setValue={setSearch} />
            </Panel>
          </Column>
          <Column rigid>
            <LinkWithQuery to="/admins/new">
              <Button>Add</Button>
            </LinkWithQuery>
          </Column>
        </Columns>
        <Panel nopadding>
          <DataTable
            keyColumn="id"
            data={admins}
            columns={COLUMNS}
            headerComponent={SortableHeader}
            extraProps={extraProps}
            loading={adminsLoading}
          />
        </Panel>
        <Pagination page={page} pages={pages} getPageLink={getPageLink} />
        {children}
      </div>
    )
  }
}
