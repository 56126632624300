// @flow
import { HeadlineOneButton, HeadlineTwoButton, OrderedListButton, UnorderedListButton } from "draft-js-buttons"
import Configuration from "../configuration"

export default () => ({ addInlineBlockButton }: Configuration) => {
  addInlineBlockButton(HeadlineOneButton)
  addInlineBlockButton(HeadlineTwoButton)
  addInlineBlockButton(OrderedListButton)
  addInlineBlockButton(UnorderedListButton)
}
