// @flow
import * as React from "react"
import type { FieldProps } from "redux-form"

import Field from "components/Field"
import TextInput from "components/inputs/Text"
import Textarea from "components/inputs/Textarea"
import Checkbox from "components/inputs/Checkbox"
import TextInputWithPrefix from "components/inputs/TextWithPrefix"
import Select from "components/inputs/Select"
import DateInput from "components/inputs/Date"
import DateTimeInput from "components/inputs/DateTime"
import RichEditor from "components/inputs/RichEditor"
import Checkboxes from "components/inputs/Checkboxes"
import SelectAutosuggest from "components/inputs/Autosuggest/Select"
import PreloadedAutosuggest from "components/inputs/Autosuggest/Preloaded"
import CountryAutosuggest from "components/inputs/Autosuggest/Country"
import ArrayAutosuggest from "components/inputs/Autosuggest/Array"

type Props = FieldProps & {
  label?: React.Node,
  nomargin?: boolean,
  noborder?: boolean,
  fullWidth?: boolean,
  children: React.Node,
  wide?: boolean,
  relative?: boolean,
  fieldClassName?: string,
}

export const inField = (Input: React.ComponentType<*>) =>
  React.forwardRef<Props, *>(
    ({ meta, input, children, wide, nomargin, relative, fieldClassName, fullWidth, ...other }: Props, ref) => {
      const id = `${meta.form}_${input.name}_input`

      const fieldProps = {
        ...meta,
        ...other,
        name: input.name,
        id,
        className: fieldClassName,
        wide,
        nomargin,
        fullWidth,
        relative,
      }
      const inputProps = {
        id,
        ...input,
        ...other,
        wide,
        invalid: meta.touched && meta.invalid,
      }

      return (
        <Field {...fieldProps}>
          <Input {...inputProps} ref={ref} />
          {children}
        </Field>
      )
    }
  )

export const TextField                 = inField(TextInput)
export const TextareaField             = inField(Textarea)
export const TextFieldWithPrefix       = inField(TextInputWithPrefix)
export const SelectField               = inField(Select)
export const DateField                 = inField(DateInput)
export const DateTimeField             = inField(DateTimeInput)
export const CheckboxField             = inField(Checkbox)
export const RichEditorField           = inField(RichEditor)
export const CheckboxesField           = inField(Checkboxes)
export const SelectAutosuggestField    = inField(SelectAutosuggest)
export const PreloadedAutosuggestField = inField(PreloadedAutosuggest)
export const CountryAutosuggestField   = inField(CountryAutosuggest)
export const ArrayAutosuggestField     = inField(ArrayAutosuggest)
