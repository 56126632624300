// @flow
import {
  getCompetition,
  selectCompetition,
  deleteCompetition,
  updateCompetition,
  sealCompetition,
} from 'store/reducers/fullCompetitions'
import { connect } from 'react-redux'
import Competition from 'views/Competition'

import { confirm } from 'components/Prompts'

import { withPropsOnChange, compose, branch, renderNothing } from 'recompose'
import { find } from 'lodash'
import preload from 'decorators/preload'
import showSavingErrors from 'utils/showSavingErrors'

export default compose(
  withPropsOnChange(['match'], ({ match: { params: { id, tab } } }) => ({
    competitionId: id,
    selectedTab: tab,
  })),
  connect(
    (state, props) => ({
      ...selectCompetition(state, props),
    }),
    (dispatch, props) => ({
      getCompetition: () => dispatch(getCompetition(props.competitionId)),
      sealCompetition: () => confirm('Seal competition?').then(() => dispatch(sealCompetition(props.competitionId))),
      deleteCompetition: () =>
        confirm(`Delete competition?`)
          .then(() => dispatch(deleteCompetition(props.competitionId)))
          .then(() => props.history.push(`/competitions`)),
      publish: () => dispatch(updateCompetition(props.competitionId, { published: true })).catch(showSavingErrors),
      unpublish: () => dispatch(updateCompetition(props.competitionId, { published: false })),
    }),
  ),
  preload({
    competition: {
      load: 'getCompetition',
      optional: false,
    },
  }),
  branch((props) => props.competition.deleted, renderNothing),
  withPropsOnChange(['competition'], ({ competition }) => ({
    dataDescriptionPage: find(competition.pages, ({ url_title }) => url_title === 'data'),
    sensitiveDataDescriptionPage: find(competition.pages, ({ url_title }) => url_title === 'data_sensitive'),
  })),
)(Competition)
