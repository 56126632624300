// @flow
import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import { createCompetition } from 'store/reducers/competitions'
import { autosuggest } from 'store/reducers/misc'
import { reduxForm, formValueSelector } from 'redux-form/immutable'
import qs from 'qs'

import validate from 'shared/utils/validate'
import handleFormErrors from 'shared/utils/handleFormErrors'

import NewCompetition from 'views/NewCompetition'

const formSelector = formValueSelector('newCompetition')

export default compose(
  withPropsOnChange(['location'], ({ location: { search } }) => {
    const initialValues = {}

    if (search) {
      const params = qs.parse(search.replace(/^\?/, ''))

      if (params.clone_from) {
        const id = params.clone_from.replace(/:(.+)?$/, '')
        const value = params.clone_from.replace(/^\d+:/, '')
        
        initialValues.kind = params.kind
        initialValues.clone_from = { id, value }
      }
    }

    return { initialValues }
  }),
  connect(
    (state) => ({
      currentKind: formSelector(state, 'kind'),
    }),
    (dispatch) => ({
      suggestCompetition: (query) => dispatch(autosuggest('competition', query)),
    }),
  ),
  reduxForm({
    form: 'newCompetition',
    validate: validate({
      title: ['required'],
      url_title: ['required'],
    }),
    onSubmit: (_values, dispatch) => {
      const values = _values.toJSON()
      values.clone_from = values.clone_from && values.clone_from.id

      return dispatch(createCompetition(values)).catch(handleFormErrors)
    },
    onSubmitSuccess: (result, _dispatch, props) => props.history.push(`/competitions/${result.data.id}`),
  }),
)(NewCompetition)
