// @flow
import * as React from 'react'
import type { FullCompetitionData, UpdateCompetitionData, PageData } from 'store/reducers/fullCompetitions'

import { H2 } from 'components/typography'
import Page from 'containers/Competition/Page'
import FileInput from 'components/inputs/File'
import Button from 'components/Button'
import { Columns, Column } from 'components/Columns'
import Hr from 'components/Hr'

import ReferenceAndErrorMetric from './ReferenceAndErrorMetric'
import Datafiles from './DataFiles'

type Props = {
  competition: FullCompetitionData,
  dataDescriptionPage: PageData,
  sensitiveDataDescriptionPage: PageData,
  uploadDatafiles: (File[]) => Promise<any>,
  updateFilename: (string, string) => Promise<any>,
  updateDescription: (string, string) => Promise<any>,
  deleteDatafile: (string) => Promise<any>,
  updateCompetition: (UpdateCompetitionData) => Promise<any>,
  fetchBenchmarkScores: () => Promise<any>,
}
type State = {
  uploading: boolean,
}
export default class Data extends React.PureComponent<Props, State> {
  state = { uploading: false }

  uploadDatafiles = (files: File[]) => {
    this.setState(() => ({ uploading: true }))

    this.props.uploadDatafiles(files).then(() => this.setState(() => ({ uploading: false })))
  }

  render() {
    const { uploading } = this.state
    const {
      competition,
      updateFilename,
      updateDescription,
      deleteDatafile,
      dataDescriptionPage,
      sensitiveDataDescriptionPage,
      updateCompetition,
      fetchBenchmarkScores,
    } = this.props
    const {
      datafiles,
      public_reference,
      private_reference,
      benchmark_submission,
      error_metric,
      submissions_per_day_limit,
      total_submissions_limit,
      default_submissions_per_day_limit,
      benchmark_submission_status,
      benchmark_submission_public_score,
      benchmark_submission_private_score,
    } = competition

    return (
      <React.Fragment>
        <h2>Reference and scoring</h2>
        <ReferenceAndErrorMetric
          public_reference={public_reference}
          private_reference={private_reference}
          benchmark_submission={benchmark_submission}
          error_metric={error_metric}
          submissions_per_day_limit={submissions_per_day_limit}
          total_submissions_limit={total_submissions_limit}
          default_submissions_per_day_limit={default_submissions_per_day_limit}
          updateCompetition={updateCompetition}
          fetchBenchmarkScores={fetchBenchmarkScores}
          benchmark_submission_status={benchmark_submission_status}
          benchmark_submission_public_score={benchmark_submission_public_score}
          benchmark_submission_private_score={benchmark_submission_private_score}
        />

        <H2>
          <Columns>
            <Column>Public files</Column>
            <Column rigid>
              <FileInput onChange={this.uploadDatafiles} multiple>
                <Button size="small" loading={uploading}>
                  Upload
                </Button>
              </FileInput>
            </Column>
          </Columns>
        </H2>
        
        <span>Remember to name the sample submissions file <strong>SampleSubmission.csv</strong></span>

        <Datafiles datafiles={datafiles} updateFilename={updateFilename} updateDescription={updateDescription} deleteDatafile={deleteDatafile} />

        <H2>Description</H2>
        <Page competition={competition} page={dataDescriptionPage} contentOnly placeholder="Data Description…" />
        {sensitiveDataDescriptionPage && (
          <>
            <Hr />
            <H2>Sensitive description (only visible when started)</H2>
            <Page
              competition={competition}
              page={sensitiveDataDescriptionPage}
              contentOnly
              placeholder="Sensitive Data Description…"
            />
          </>
        )}
      </React.Fragment>
    )
  }
}
