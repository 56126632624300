// @flow
import * as React from "react"

import { map, isPlainObject } from "lodash"
import { alert } from "views/Prompts"
import humanize from "shared/utils/humanize"

export default (e: Error) => {
  if (isPlainObject(e)) {
    alert(
      <React.Fragment>
        {map(e, (error, field) => {
          let fieldMessage = field === "base" ? "" : `${humanize(field)} `
          fieldMessage += error

          return <p key={field}>{fieldMessage}</p>
        })}
      </React.Fragment>
    )

    throw e
  }
}
