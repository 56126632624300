// @flow
import * as React from "react"
import c from "classnames"

import textS from "./Text.scss"
import s from "./TextWithPrefix.scss"

import TextInput, { type Props as TextInputProps } from "./Text"

type Props = {
  ...TextInputProps,
  prefix: React.Node,
  size: "small" | "normal",
}

type State = {
  prefixWidth: ?number,
}

export default class TextInputWithPrefix extends React.PureComponent<Props, State> {
  prefix: ?HTMLSpanElement

  static defaultProps = {
    prefix: "",
    size: "normal",
  }

  state = {
    prefixWidth: null,
  }

  componentDidMount() {
    this.measure()
  }

  measure() {
    if (this.prefix) {
      const width = this.prefix.offsetWidth

      this.setState(() => ({ prefixWidth: width }))
    }
  }

  render() {
    const { prefix, invalid, size, ...otherProps } = this.props
    const { prefixWidth } = this.state
    const measured = prefixWidth === null

    return (
      <label className={c(s.container, s[`size-${size}`], measured ? null : s.initial)}>
        <TextInput {...otherProps} className={s.input} style={{ paddingLeft: prefixWidth }} size={size} />
        <span
          className={c(textS.text, s.prefix)}
          ref={(ref) => {
            this.prefix = ref
          }}
        >
          {prefix}
        </span>
      </label>
    )
  }
}
