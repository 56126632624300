// @flow
import { resetPassword } from "store/reducers/me"
import { reduxForm } from "redux-form/immutable"
import PasswordReset from "views/PasswordReset"

import { compose } from "recompose"
import validate from "shared/utils/validate"
import handleFormErrors from "shared/utils/handleFormErrors"

export default compose(
  reduxForm({
    form: "passwordRequest",
    validate: validate({
      password: ["required"],
    }),
    onSubmit: (values, dispatch, props) =>
      dispatch(resetPassword(props.match.params.resetToken, values.get("password"))).catch(handleFormErrors),
    onSubmitSuccess: (_result, _dispatch, props) => props.history.push("/"),
  })
)(PasswordReset)
