// @flow
import { compose, withPropsOnChange } from "recompose"
import { filter } from "lodash"
import { connect } from "react-redux"
import { reorderPages } from "store/reducers/fullCompetitions"
import Pages from "views/Competition/Pages"

export default compose(
  withPropsOnChange(["pages"], ({ pages }) => ({
    pages: filter(pages, ({ url_title }) => url_title !== "data" && url_title !== "data_sensitive"),
  })),
  connect(
    () => ({}),
    (dispatch, props) => ({
      reorderPages: (pageIds) => dispatch(reorderPages(props.competition.id, pageIds)),
    })
  )
)(Pages)
