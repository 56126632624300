// @flow
import { type BlogPostData } from 'store/reducers/blogPosts'
import { THEMES } from 'store/reducers/fullBlogPosts'
import { type UserData as MeData } from 'store/reducers/me'
import { type LocationShape } from 'react-router-dom'
import * as React from 'react'
import Helmet from 'react-helmet'
import memoize from 'shared/utils/memoize'

import Panel from 'components/Panel'
import Button from 'components/Button'
import Autosaving from 'components/inputs/Autosaving'
import Select from 'components/inputs/Select'
import { Columns, Column } from 'components/Columns'
import LinkWithQuery from 'components/LinkWithQuery'
import SearchInput from 'components/SearchInput'
import DataTable, { bindSortableHeaderToProps, renderers } from 'components/DataTable'
import Pagination from 'components/Pagination'

type Props = {
  blogPosts: ?(BlogPostData[]),
  blogPostsLoading: boolean,
  page: number,
  pages: ?number,
  getPageLink: (number) => LocationShape,
  sortBy: string,
  sortReversed: string,
  getSortLink: (string, ?boolean) => LocationShape,
  search?: string,
  setSearch: (string) => any,
  children?: React.Node,
  setTheme: (number, string) => Promise<any>,
  me: MeData,
}

type ExtraProps = {
  me: MeData,
}

const COLUMNS = [
  {
    key: 'theme',
    label: 'theme',
    width: 150,
    render: (_, row, extraProps) => <ThemeSelect row={row} setTheme={extraProps.setTheme} />,
  },
  {
    key: 'title',
    render: (title, { id }) => <LinkWithQuery to={`/blog/${id}`}>{title}</LinkWithQuery>,
    width: 200,
    flexible: true,
  },
  {
    key: 'url_title',
    width: 150,
    flexible: true,
  },
  {
    key: 'published_at',
    label: 'Published',
    render: renderers.date,
    width: 200,
  },
  {
    key: 'created_at',
    label: 'Created',
    render: renderers.date,
    width: 200,
  },
]

const ThemeSelect = ({ row, setTheme }: { row: DiscussionData, setTheme: (number, string) => Promise<*> }) => {
  const save = React.useCallback((theme) => setTheme(row.id, theme), [setTheme, row.id])

  return (
    <Autosaving
      value={row.theme || ''}
      options={THEMES}
      save={save}
      saveOnChange
      saveOnBlur
      size="small"
      render={(props) => <Select {...props} />}
    />
  )
}

export default class BlogPosts extends React.PureComponent<Props> {
  cacheExtraProps = memoize(1)((props) => props)

  render() {
    const {
      blogPosts,
      blogPostsLoading,
      page,
      pages,
      getPageLink,
      sortBy,
      sortReversed,
      getSortLink,
      search,
      setSearch,
      children,
      me,
      setTheme,
    } = this.props

    const SortableHeader = bindSortableHeaderToProps({
      sortBy,
      sortReversed,
      getSortLink,
    })

    const extraProps: ExtraProps = this.cacheExtraProps({ me, setTheme })

    return (
      <div>
        <Helmet title="Admins" />
        <Columns>
          <Column>
            <Panel nopadding>
              <SearchInput value={search} setValue={setSearch} />
            </Panel>
          </Column>
          <Column rigid>
            <LinkWithQuery to="/blog/new">
              <Button>Add</Button>
            </LinkWithQuery>
          </Column>
        </Columns>
        <Panel nopadding>
          <DataTable
            keyColumn="id"
            data={blogPosts}
            columns={COLUMNS}
            headerComponent={SortableHeader}
            extraProps={extraProps}
            loading={blogPostsLoading}
          />
        </Panel>
        <Pagination page={page} pages={pages} getPageLink={getPageLink} />
        {children}
      </div>
    )
  }
}
