// @flow
import { requestPasswordReset } from "store/reducers/me"
import { reduxForm } from "redux-form/immutable"
import PasswordRequest from "views/PasswordRequest"

import { compose } from "recompose"
import validate from "shared/utils/validate"
import handleFormErrors from "shared/utils/handleFormErrors"

export default compose(
  reduxForm({
    form: "passwordRequest",
    validate: validate({
      email: ["required", "email"],
    }),
    onSubmit: (values, dispatch) => dispatch(requestPasswordReset(values.get("email"))).catch(handleFormErrors),
  })
)(PasswordRequest)
