// @flow
import { getJobFunnel, jobFunnelSelector } from 'store/reducers/fullJobFunnels'
import { connect } from 'react-redux'

import preload from 'decorators/preload'

import View from 'views/Job/Application'

import { withPropsOnChange, compose } from 'recompose'

export default compose(
  withPropsOnChange(['id', 'jobId'], ({ jobId, id }) => ({
    query: {
      jobId,
      id,
    },
  })),
  connect(
    (state, props) => ({
      jobFunnel: jobFunnelSelector(state, props.query),
    }),
    (dispatch, props) => ({
      getJobFunnel: () => dispatch(getJobFunnel(props.query)),
    }),
  ),
  preload({
    jobFunnel: {
      load: 'getJobFunnel',
      optional: false,
    },
  }),
)(View)
