// @flow
import type { LocationShape } from "react-router-dom"

import { withPropsOnChange } from "recompose"
import { compact } from "lodash"
import { parse, stringify } from "qs"
import memoize from "../utils/memoize"

export const getSortLink = memoize(1)(
  (currentQuery: {}, defaultSort?: string) => (sortBy: string, sortReversed?: boolean): LocationShape => {
    const newSortReversed = sortReversed ? "reversed" : null
    const newSortBy = sortBy !== defaultSort || newSortReversed ? sortBy : null

    let sort = compact([newSortBy, newSortReversed])
    sort = sort.length ? sort.join("-") : undefined

    return {
      search: `?${stringify({
        ...currentQuery,
        sort,
        page: undefined,
      })}`,
    }
  }
)

const getSort = (defaultSort) => ({ location }) => {
  const query = location.search ? parse(location.search.replace(/^\?/, "")) : {}
  let [sortBy, sortReversed] = (query.sort || "").split("-")

  sortReversed = !!(sortReversed && sortReversed === "reversed")
  if (!sortBy) sortBy = defaultSort

  return {
    sortBy,
    sortReversed,
    getSortLink: getSortLink(query, defaultSort),
  }
}

export default ({ default: defaultSort }: { default?: string } = {}) =>
  withPropsOnChange(["location"], getSort(defaultSort))
