// @flow
import { isEqualWith } from "lodash"

const customizer = (objectValue, otherValue, indexOrkey, object, other, stack) => {
  if (stack) {
    return objectValue === otherValue
  }
}

export default (a: {}, b: {}) => isEqualWith(a, b, customizer)
