// @flow
import React from 'react'
import { findDOMNode } from 'react-dom'
import { compose } from 'recompose'
import { DndProvider, DragSource, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { TabList as OriginalTabList, Tab as OriginalTab } from './Tabs'

const CARD_ITEM_TYPE = 'CARD'

export const TabList = (props: any) => (
  <DndProvider backend={HTML5Backend}>
    <OriginalTabList {...props} />
  </DndProvider>
)
TabList.tabsRole = 'TabList'

class TabComponent extends OriginalTab {
  render() {
    const { connectDragSource, connectDropTarget, ...props } = this.props

    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(connectDropTarget(OriginalTab.prototype.render.call(this)))
    )
  }
}
export const Tab = compose(
  DropTarget(
    CARD_ITEM_TYPE,
    {
      hover(props, monitor, component) {
        if (!component) {
          return null
        }

        const dragIndex = monitor.getItem().index
        const hoverIndex = props.index

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }

        // NB: This will fail if component if a function
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect() // eslint-disable-line react/no-find-dom-node
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
        const clientOffset = monitor.getClientOffset()
        const hoverClientX = clientOffset.x - hoverBoundingRect.left

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return
        }

        props.moveTab(dragIndex, hoverIndex)

        monitor.getItem().index = hoverIndex // eslint-disable-line no-param-reassign
      },
      drop: (props) => {
        props.onDrop()
      },
    },
    (connect) => ({
      connectDropTarget: connect.dropTarget(),
    }),
  ),
  DragSource(
    CARD_ITEM_TYPE,
    {
      beginDrag(props) {
        return {
          id: props.id,
          index: props.index,
        }
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  ),
)(TabComponent)
Tab.tabsRole = 'Tab'
