// @flow
import * as React from "react"
import c from "classnames"
import { last } from "lodash"

import Spinner from "components/Spinner"
import Svg from "components/Svg"
import fileIcon from "assets/feather/file.svg"

import FileInput from "./File"
import s from "./SingleFileInput.scss"

const getLabel = (value: ?(string | File)) => {
  if (!value) return null
  if (typeof value === "string") return last(value.split("/")).replace(/\?(.+)?$/, "")

  return value.name
}

type Props = {
  value: ?(File | string),
  onChange: (File) => any,
  invalid?: boolean,
  placeholder?: string,
  size: "normal" | "small",
  className?: string,
  loading?: boolean,
  disabled?: boolean,

  onBlur?: (any) => any,
  onClick?: (any) => any,
  onDragStart?: (any) => any,
  onDrop?: (any) => any,
  onFocus?: (any) => any,
}

export default class SingleFileInput extends React.Component<Props> {
  static defaultProps = {
    size: "normal",
  }

  onChange = (files: File[]) => {
    this.props.onChange(files[0])
  }

  render() {
    const {
      value,
      onChange,
      invalid,
      placeholder,
      className,
      size,
      disabled,
      loading,
      onBlur,
      onClick,
      onDragStart,
      onDrop,
      onFocus, // remove redux-form default events
      ...props
    } = this.props

    const label = getLabel(value) || placeholder

    return (
      <FileInput {...props} disabled={disabled} accept="*/*" onChange={this.onChange}>
        <div
          className={c(
            s.container,
            s[`container-size-${size}`],
            invalid && s.invalid,
            disabled && s.disabled,
            loading && s.loading,
            className
          )}
        >
          <div className={s.dropArea}>{label}</div>
          {loading ? <Spinner className={s.icon} color="black" /> : <Svg icon={fileIcon} className={s.icon} />}
        </div>
      </FileInput>
    )
  }
}
