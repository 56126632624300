// @flow
import * as React from "react"
import { map } from "lodash"
import c from "classnames"
import s from "./Select.scss"

export type Option = {
  value: string | number,
  label: React.Node,
}
export type Options = Option[]

export type Props = {
  value?: string,
  type?: string,
  disabled?: boolean,
  invalid?: boolean,
  autoFocus?: boolean,
  className?: string,
  style?: { [string]: ?(number | string) },
  nobox: boolean,
  nomargin?: boolean,
  size: "small" | "normal",
  noborder?: boolean,
  options: Options,
}

export default class SelectInput extends React.PureComponent<Props> {
  input: ?HTMLSelectElement

  static defaultProps = {
    type: "text",
    nobox: false,
    size: "normal",
    options: [],
  }

  componentDidMount() {
    if (this.props.autoFocus && this.input) {
      this.input.focus()
    }
  }

  render() {
    const { invalid, className, nobox, size, nomargin, noborder, options, ...otherProps } = this.props

    return (
      <select
        ref={(ref) => {
          this.input = ref
        }}
        {...otherProps}
        className={c(
          nobox ? s.nobox : s.box,
          s.text,
          s[`size-${size}`],
          s[`box-size-${size}`],
          invalid && s.invalid,
          noborder && s.noborder,
          className
        )}
      >
        {map(options, ({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    )
  }
}
