// @flow
import { getUsers, updateUser, querySelector } from "store/reducers/users"
import { connect } from "react-redux"
import Users from "views/Users"
import withPagination from "shared/decorators/withPagination"
import withSorting from "shared/decorators/withSorting"
import withSearch from "shared/decorators/withSearch"

import { compose, withPropsOnChange } from "recompose"
import preload from "decorators/preload"

export default compose(
  withPagination({ per_page: 50 }),
  withSorting({ default: "created_at" }),
  withSearch,
  connect(
    (state, props) => ({
      ...querySelector(state, props),
    }),
    (dispatch, props) => ({
      getUsers: () =>
        dispatch(
          getUsers({
            page: props.page,
            per_page: props.per_page,
            sort_by: props.sortBy,
            sort_reversed: props.sortReversed,
            search: props.search,
          })
        ),
      setBanned: (id: number, banned: boolean) => dispatch(updateUser(id, { banned })),
    })
  ),
  preload({
    users: {
      load: "getUsers",
      replaceWithData: false,
      optional: true,
    },
  }),
  withPropsOnChange(["users"], ({ users }) => ({
    usersLoading: users.loading,
    users: users.data,
  }))
)(Users)
