// @flow
import React from "react"
import format from "date-fns/format"
import { type RenderFn } from "./index"

const date: RenderFn<*, *> = (value: number | Date) => {
  if (!value) return null

  if (value) {
    value = new Date(value) // eslint-disable-line no-param-reassign
  }

  return <span title={format(value, "d MMM yyyy, HH:mm:ss")}>{format(value, "d MMM HH:mm")}</span>
}

const bool: RenderFn<*, *> = (value: boolean) => (value ? "Yes" : "")

export default {
  date,
  bool,
}
