// @flow
import * as React from "react"
import { Map } from "immutable"
import { DefaultDraftBlockRenderMap } from "draft-js"
import createBlockBreakoutPlugin from "draft-js-block-breakout-plugin"

import Configuration from "../configuration"

const BLOCK_RENDER_MAP = DefaultDraftBlockRenderMap.merge(
  Map({
    unstyled: {
      element: "div",
      aliasedElements: ["p"],
      wrapper: <div className="p" />,
    },
    "code-block": {
      element: "pre",
      wrapper: <div className="codeblock" />,
    },
    "header-one": { element: "h2" },
    "header-two": { element: "h3" },
  })
)

export default () => ({ addPlugin, addProps }: Configuration) => {
  const blockBreakoutPlugin = createBlockBreakoutPlugin()
  addPlugin(blockBreakoutPlugin)
  addProps({ blockRenderMap: BLOCK_RENDER_MAP })
}
