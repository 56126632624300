// @flow
import * as React from "react"

type Props = {
  value: any,
  save: (any) => Promise<any>,
  render: React.StatelessFunctionalComponent<*>,
  getValue: (any) => any,
  saveOnChange: boolean,
  saveOnBlur: boolean,
  saveOnEnter: boolean,
  onChange?: (any) => any,
  onBlur?: (any) => any,
  onKeyPress?: (any) => any,
}

type State = {
  saving: boolean,
  value: any,
}

export default class Autosaving extends React.Component<Props, State> {
  static defaultProps = {
    getValue: (e: SyntheticEvent<*>) => e.currentTarget.value,
    saveOnChange: true,
    saveOnBlur: false,
    saveOnEnter: false,
  }

  state = {
    saving: false,
    value: this.props.value,
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      this.setState(() => ({ value: nextProps.value }))
    }
  }

  unsetSaving = () => this.setState(() => ({ saving: false }))

  onChange = (...args: any) => {
    const value = this.props.getValue(...args)
    this.setState(() => ({ value }))

    if (this.props.saveOnChange) {
      this.save(value)
    }
    if (this.props.onChange) {
      this.props.onChange(...args)
    }
  }

  save = (value: any) => {
    this.setState(() => ({ saving: true }))
    this.props
      .save(value)
      .then(this.unsetSaving)
      .catch((e) => {
        this.setState(() => ({ saving: false, value: this.props.value }))

        if (e) {
          throw e
        }
      })
  }

  onBlur = (...args: any) => {
    if (this.props.saveOnBlur) {
      this.save(this.state.value)
    }
    if (this.props.onBlur) {
      this.props.onBlur(...args)
    }
  }

  onKeyPress = (e: KeyboardEvent, ...args: any) => {
    if (this.props.saveOnEnter && e.key === "Enter") {
      this.save(this.state.value)
    }

    if (this.props.onKeyPress) {
      this.props.onKeyPress(e, ...args)
    }
  }

  render() {
    const { getValue, render, saveOnChange, saveOnBlur, saveOnEnter, save, ...props } = this.props

    const newProps = {
      ...props,
      onChange: this.onChange,
      onBlur: this.onBlur,
      value: this.state.value,
      disabled: this.state.saving,
    }

    if (saveOnEnter) {
      newProps.onKeyPress = this.onKeyPress
    }

    return render(newProps)
  }
}
