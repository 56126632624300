// @flow
import type { DiscussionData } from 'store/reducers/discussions'
import { type LocationShape, Link } from 'react-router-dom'
import * as React from 'react'
import Helmet from 'react-helmet'
import memoize from 'shared/utils/memoize'

import Panel from 'components/Panel'
import Button from 'components/Button'
import Autosaving from 'components/inputs/Autosaving'
import Select from 'components/inputs/Select'
import User from 'components/User'
import SearchInput from 'components/SearchInput'
import DataTable, { bindSortableHeaderToProps, renderers } from 'components/DataTable'
import Pagination from 'components/Pagination'

const THEMES = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Notebooks',
    value: 'Notebooks',
  },
  {
    label: 'Help',
    value: 'Help',
  },
  {
    label: 'Data',
    value: 'Data',
  },
  {
    label: 'Platform',
    value: 'Platform',
  },
  {
    label: 'Connect',
    value: 'Connect'
  },
]

type Props = {
  discussions: ?(DiscussionData[]),
  discussionsLoading: boolean,
  page: number,
  pages: ?number,
  getPageLink: (number) => LocationShape,
  sortBy: string,
  sortReversed: string,
  getSortLink: (string, ?boolean) => LocationShape,
  search?: string,
  setSearch: (string) => any,
  children?: React.Node,
  setBanned: (number, boolean) => Promise<any>,
  setPinned: (number, boolean) => Promise<any>,
  setPublished: (number, boolean) => Promise<any>,
  setTheme: (number, string) => Promise<any>,
}

type ExtraProps = {}

const FRONTEND_URL = process.env.FRONTEND_URL || 'https://zindi.africa'

const BannedButton = ({ row, setBanned }: { row: DiscussionData, setBanned: (number, boolean) => Promise<*> }) => {
  const onClick = React.useCallback(() => {
    const newBanned = !row.banned

    return setBanned(row.id, newBanned)
  }, [row, setBanned])

  return (
    <Button size="small" kind={row.banned ? 'danger' : 'primary'} onClick={onClick} outline promised>
      {row.banned ? 'Yes' : 'No'}
    </Button>
  )
}

const PublishedButton = ({
  row,
  setPublished,
}: {
  row: DiscussionData,
  setPublished: (number, boolean) => Promise<*>,
}) => {
  const onClick = React.useCallback(() => {
    const newPublished = !row.published_at

    return setPublished(row.id, newPublished)
  }, [row.published_at, setPublished])

  return (
    <Button size="small" kind={row.published_at ? 'primary' : 'danger'} onClick={onClick} outline promised>
      {row.published_at ? 'Yes' : 'No'}
    </Button>
  )
}

const PinnedButton = ({ row, setPinned }: { row: DiscussionData, setPinned: (number, boolean) => Promise<*> }) => {
  const onClick = React.useCallback(() => {
    const newPinned = !row.pinned

    return setPinned(row.id, newPinned)
  }, [row.pinned, setPinned])

  return (
    <Button size="small" kind={row.pinned ? 'primary' : 'secondary'} onClick={onClick} outline promised>
      {row.pinned ? 'Yes' : 'No'}
    </Button>
  )
}

const ThemeSelect = ({ row, setTheme }: { row: DiscussionData, setTheme: (number, string) => Promise<*> }) => {
  const save = React.useCallback((theme) => setTheme(row.id, theme), [setTheme, row.id])

  return (
    <Autosaving
      value={row.theme || ''}
      options={THEMES}
      save={save}
      saveOnChange
      saveOnBlur
      size="small"
      render={(props) => <Select {...props} />}
    />
  )
}

const COLUMNS = [
  {
    key: 'pinned',
    label: 'pinned',
    sortable: false,
    width: 80,
    align: 'right',
    render: (_, row, extraProps) => <PinnedButton row={row} setPinned={extraProps.setPinned} />,
  },
  {
    key: 'title',
    width: 200,
    flexible: true,
    render: (title, { id }) => <Link to={`/discussions/${id}`}>{title}</Link>,
  },
  {
    key: 'user',
    render: (user) => (
      <a href={`${FRONTEND_URL}/users/${user.username}`} target="_blank" rel="noopener noreferrer">
        <User username={user.username} avatar={user.avatar} />
      </a>
    ),
    width: 100,
    flexible: true,
  },
  {
    key: 'competition',
    render: (competition) =>
      competition ? (
        <a href={`${FRONTEND_URL}/competitions/${competition.url_title}`} target="_blank" rel="noopener noreferrer">
          {competition.title}
        </a>
      ) : null,
    width: 100,
    flexible: true,
  },
  {
    key: 'theme',
    label: 'theme',
    width: 120,
    render: (_, row, extraProps) => <ThemeSelect row={row} setTheme={extraProps.setTheme} />,
  },
  {
    key: 'first_published_at',
    label: 'date',
    render: renderers.date,
    width: 150,
  },
  {
    key: 'published',
    label: 'published',
    sortable: false,
    width: 80,
    align: 'right',
    render: (_, row, extraProps) => <PublishedButton row={row} setPublished={extraProps.setPublished} />,
  },
  {
    key: 'banned',
    label: 'banned',
    sortable: false,
    width: 80,
    align: 'right',
    render: (_, row, extraProps) => <BannedButton row={row} setBanned={extraProps.setBanned} />,
  },
]

export default class Discussions extends React.PureComponent<Props> {
  cacheExtraProps = memoize(1)((props) => props)

  render() {
    const {
      discussions,
      discussionsLoading,
      page,
      pages,
      getPageLink,
      sortBy,
      sortReversed,
      getSortLink,
      search,
      setSearch,
      children,
      setBanned,
      setPublished,
      setPinned,
      setTheme,
    } = this.props

    const SortableHeader = bindSortableHeaderToProps({
      sortBy,
      sortReversed,
      getSortLink,
    })

    const extraProps: ExtraProps = this.cacheExtraProps({ setBanned, setPublished, setPinned, setTheme })

    return (
      <div>
        <Helmet title="Discussions" />
        <Panel nopadding>
          <SearchInput value={search} setValue={setSearch} />
        </Panel>
        <Panel nopadding>
          <DataTable
            keyColumn="id"
            data={discussions}
            columns={COLUMNS}
            headerComponent={SortableHeader}
            extraProps={extraProps}
            loading={discussionsLoading}
          />
        </Panel>
        <Pagination page={page} pages={pages} getPageLink={getPageLink} />
        {children}
      </div>
    )
  }
}
