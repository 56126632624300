import React, { Component } from "react"

export default class Mention extends Component {
  render() {
    const {
      className,
      /* eslint-disable no-unused-vars */
      decoratedText,
      dir,
      entityKey,
      getEditorState,
      offsetKey,
      setEditorState,
      contentState,
      blockKey,
      /* eslint-enable no-unused-vars */
      ...otherProps
    } = this.props // eslint-disable-line no-use-before-define

    return <span {...otherProps} className={className} />
  }
}
