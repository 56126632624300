// @flow
import * as React from "react"
import { Field } from "redux-form/immutable"
import Helmet from "react-helmet"
import _parameterize from "parameterize"
import memoize from "shared/utils/memoize"

import { getCompetitionPathFromKind } from "containers/concerns/getCompetitionKind"
import Button from "components/Button"
import Panel from "components/Panel"
import { TextField, TextFieldWithPrefix, SelectAutosuggestField, SelectField } from "components/fields"

import s from "./NewCompetition.scss"

const FRONTEND_URL = process.env.FRONTEND_URL || ""

const parameterize = memoize(20)(_parameterize)
const normalize = (value) => parameterize(value)

type Props = {
  handleSubmit: (any) => any,
  submitting: boolean,
  invalid: boolean,
  change: (string, any) => any,
  suggestCompetition: (string) => Promise<*>,
  currentKind: string,
}

const KIND_OPTIONS = [
  { value: "competition", label: "Competition" },
  { value: "hackathon", label: "Hackathon" },
]

export default class NewCompetition extends React.Component<Props> {
  onTitleChange = (_event: any, value: string) => {
    this.props.change("url_title", parameterize(value))
  }

  render() {
    const { invalid, handleSubmit, submitting, suggestCompetition, currentKind } = this.props

    const title = currentKind === "hackathon" ? "New Hackathon" : "New Competition"

    return (
      <form onSubmit={handleSubmit}>
        <Panel
          nopadding
          width="medium"
          title={
            <>
              New
              <Field name="kind" component={SelectField} options={KIND_OPTIONS} fieldClassName={s.kindSelect} />
            </>
          }
        >
          <Helmet title={title} />
          <Field
            label="Copy from (optional)"
            name="clone_from"
            placeholder="Start typing…"
            component={SelectAutosuggestField}
            getSuggestions={suggestCompetition}
          />
          <Field label="Title" name="title" nomargin component={TextField} autoFocus onChange={this.onTitleChange}>
            <Field
              name="url_title"
              component={TextFieldWithPrefix}
              prefix={`${FRONTEND_URL}/${getCompetitionPathFromKind(currentKind)}/`}
              normalize={normalize}
              size="small"
              noborder
            />
          </Field>
          <Button type="submit" disabled={submitting || invalid} loading={submitting}>
            Next →
          </Button>
        </Panel>
      </form>
    )
  }
}
