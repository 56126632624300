// @flow
import type { AsyncActionTypes } from 'shared/store/types'

const asyncAction = (actionName: string): AsyncActionTypes => ({
  START: actionName,
  SUCCESS: `${actionName}_SUCCESS`,
  FAILURE: `${actionName}_FAILURE`,
})

export const AUTH_ERROR             = 'AUTH_ERROR'
export const GET_ME                 = asyncAction('GET_ME')
export const SIGNIN                 = asyncAction('SIGNIN')
export const OTP                    = asyncAction('OTP')
export const SIGNUP                 = asyncAction('SIGNUP')
export const SIGNOUT                = asyncAction('SIGNOUT')
export const REQUEST_PASSWORD_RESET = asyncAction('REQUEST_PASSWORD_RESET')
export const RESET_PASSWORD         = asyncAction('RESET_PASSWORD')

export const GET_USER = asyncAction('GET_USER')
export const GET_USERS = asyncAction('GET_USERS')
export const UPDATE_USER = asyncAction('UPDATE_USER')

export const GET_ADMINS = asyncAction('GET_ADMINS')
export const CREATE_ADMIN = asyncAction('CREATE_ADMIN')
export const UPDATE_ADMIN = asyncAction('UPDATE_ADMIN')

export const GET_COMPETITIONS = asyncAction('GET_COMPETITIONS')
export const GET_COMPETITION = asyncAction('GET_COMPETITION')
export const UPDATE_COMPETITION = asyncAction('UPDATE_COMPETITION')
export const CREATE_COMPETITION = asyncAction('CREATE_COMPETITION')
export const DELETE_COMPETITION = asyncAction('DELETE_COMPETITION')
export const GET_COMPETITION_BENCHMARK_SCORES = asyncAction('GET_COMPETITION_BENCHMARK_SCORES')

export const CREATE_PAGE = asyncAction('CREATE_PAGE')
export const UPDATE_PAGE = asyncAction('UPDATE_PAGE')
export const DELETE_PAGE = asyncAction('DELETE_PAGE')
export const REORDER_PAGES = asyncAction('REORDER_PAGES')

export const CREATE_DATAFILE = asyncAction('CREATE_DATAFILE')
export const UPDATE_DATAFILE = asyncAction('UPDATE_DATAFILE')
export const DELETE_DATAFILE = asyncAction('DELETE_DATAFILE')

export const UPLOAD_IMAGE_ATTACHMENT = asyncAction('UPLOAD_IMAGE_ATTACHMENT')

export const GET_BLOG_POSTS = asyncAction('GET_BLOG_POSTS')
export const GET_BLOG_POST = asyncAction('GET_BLOG_POST')
export const UPDATE_BLOG_POST = asyncAction('UPDATE_BLOG_POST')
export const CREATE_BLOG_POST = asyncAction('CREATE_BLOG_POST')
export const DELETE_BLOG_POST = asyncAction('DELETE_BLOG_POST')

export const GET_SUBMISSIONS = asyncAction('GET_SUBMISSIONS')
export const UPDATE_SUBMISSION = asyncAction('UPDATE_SUBMISSION')

export const GET_PARTICIPATIONS = asyncAction('GET_PARTICIPATIONS')
export const UPDATE_PARTICIPATION = asyncAction('UPDATE_PARTICIPATION')
export const UPDATE_PARTICIPATION_BANNED = asyncAction('UPDATE_PARTICIPATION_BANNED')
export const AUTOSUGGEST = asyncAction('AUTOSUGGEST')

export const SEAL_COMPETITION = asyncAction('SEAL_COMPETITION')
export const UPDATE_COMPETITION_BEST_SCORES = asyncAction('UPDATE_COMPETITION_BEST_SCORES')
export const RESCORE_LEADERBOARD = asyncAction('RESCORE_LEADERBOARD')
export const PUBLISH_RESCORING_RESULTS = asyncAction('PUBLISH_RESCORING_RESULTS')

export const GET_JOBS = asyncAction('GET_JOBS')
export const GET_JOB = asyncAction('GET_JOB')
export const GET_COUNTRIES = asyncAction('GET_COUNTRIES')
export const CREATE_JOB = asyncAction('CREATE_JOB')
export const UPDATE_JOB = asyncAction('UPDATE_JOB')
export const DELETE_JOB = asyncAction('DELETE_JOB')

export const GET_JOB_FUNNELS = asyncAction('GET_JOB_FUNNELS')
export const GET_JOB_FUNNEL = asyncAction('GET_JOB_FUNNEL')
export const UPDATE_JOB_FUNNEL = asyncAction('UPDATE_JOB_FUNNEL')
export const GET_CAREER_QUESTIONNAIRE_QUESTIONS = asyncAction('GET_CAREER_QUESTIONNAIRE_QUESTIONS')

export const GET_DISCUSSIONS = asyncAction('GET_DISCUSSIONS')
export const GET_DISCUSSION = asyncAction('GET_DISCUSSION')
export const UPDATE_DISCUSSION = asyncAction('UPDATE_DISCUSSION')
export const HIDE_COMMENT = asyncAction('HIDE_COMMENT')

export const GET_REPORTS = asyncAction('GET_REPORTS')
export const UPDATE_REPORT = asyncAction('UPDATE_REPORT')
