// @flow
import * as React from "react"
import { debounce } from "lodash"
import Spinner from "components/Spinner"
import memoize from "shared/utils/memoize"

import Base, { Suggestion } from "./Base"
import s from "./Async.scss"

export type Props = {
  onChange: (?string) => any,
  value: ?string,
  getSuggestions: (query: string) => Promise<Suggestion[]>,
}

export type State = {
  loading: boolean,
  suggestions: ?(Suggestion[]),
}

const memoizeSuggestions = (getSuggestions) => getSuggestions && memoize(50)(getSuggestions)

export default class AsyncAutosuggest extends React.Component<Props, State> {
  state = {
    suggestions: [],
    loading: false,
  }

  baseRef = React.createRef()

  getSuggestions = memoizeSuggestions(this.props.getSuggestions)

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.getSuggestions !== this.props.getSuggestions) {
      this.getSuggestions = memoizeSuggestions(nextProps.getSuggestions)
    }
  }

  loadSuggestions = debounce((value: string) => {
    this.getSuggestions(value).then(this.onLoadSuccess.bind(this, value)).catch(this.onLoadError.bind(this, value))
  }, 200)

  onLoadSuccess = (requestValue: string, suggestions: Suggestion[]) => {
    if (this.props.value === requestValue) {
      this.setState({ loading: false, suggestions })
    }
  }

  onLoadError = (requestValue: string) => {
    if (this.props.value === requestValue) {
      this.setState({ loading: false, suggestions: [] })
    }
  }

  onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    if (value) {
      this.setState({ loading: true })

      this.loadSuggestions(value)
    } else {
      this.onSuggestionsClearRequested()
    }
  }

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] })
  }

  renderSuggestionsContainer = ({ containerProps, children }: any) => {
    const showLoading = !!(this.state.loading && children)

    return (
      <div {...containerProps} className={containerProps.className}>
        {children}
        {showLoading && (
          <div className={s.loading}>
            <Spinner className={s.loadingSpinner} />
          </div>
        )}
      </div>
    )
  }

  focus = () => {
    if (this.baseRef.current) {
      this.baseRef.current.focus()
    }
  }

  render() {
    const { suggestions } = this.state
    const { getSuggestions, ...props } = this.props

    return (
      <Base
        {...props}
        ref={this.baseRef}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
      />
    )
  }
}
