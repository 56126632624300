// @flow
import { type JobData } from "store/reducers/jobs"
import { type UserData as MeData } from "store/reducers/me"
import { type LocationShape } from "react-router-dom"
import * as React from "react"
import Helmet from "react-helmet"
import memoize from "shared/utils/memoize"

import Panel from "components/Panel"
import Button from "components/Button"
import { Columns, Column } from "components/Columns"
import LinkWithQuery from "components/LinkWithQuery"
import SearchInput from "components/SearchInput"
import DataTable, { bindSortableHeaderToProps, renderers } from "components/DataTable"
import Pagination from "components/Pagination"

type Props = {
  jobs: ?(JobData[]),
  jobsLoading: boolean,
  page: number,
  pages: ?number,
  getPageLink: (number) => LocationShape,
  sortBy: string,
  sortReversed: string,
  getSortLink: (string, ?boolean) => LocationShape,
  search?: string,
  setSearch: (string) => any,
  children?: React.Node,
  me: MeData,
}

type ExtraProps = {
  me: MeData,
}

const COLUMNS = [
  {
    key: "title",
    label: "Title",
    render: (title, { id }) => <LinkWithQuery to={`/jobs/${id}`}>{title}</LinkWithQuery>,
    width: 200,
    flexible: true,
  },
  {
    key: "published",
    label: "Published",
    render: (published, { publish_time }) => (
      <>
        {renderers.bool(published)}
        {publish_time ? ", " : ""}
        {renderers.date(publish_time)}
      </>
    ),
    width: 200,
  },
  {
    key: "created_at",
    label: "Created",
    render: renderers.date,
    width: 200,
  },
]

export default class Jobs extends React.PureComponent<Props> {
  cacheExtraProps = memoize(1)((props) => props)

  render() {
    const {
      jobs,
      jobsLoading,
      page,
      pages,
      getPageLink,
      sortBy,
      sortReversed,
      getSortLink,
      search,
      setSearch,
      children,
      me,
    } = this.props

    const SortableHeader = bindSortableHeaderToProps({
      sortBy,
      sortReversed,
      getSortLink,
    })

    const extraProps: ExtraProps = this.cacheExtraProps({ me })

    return (
      <div>
        <Helmet title="Admins" />
        <Columns>
          <Column>
            <Panel nopadding>
              <SearchInput value={search} setValue={setSearch} />
            </Panel>
          </Column>
          <Column rigid>
            <LinkWithQuery to="/jobs/new">
              <Button>Add</Button>
            </LinkWithQuery>
          </Column>
        </Columns>
        <Panel nopadding>
          <DataTable
            keyColumn="id"
            data={jobs}
            columns={COLUMNS}
            headerComponent={SortableHeader}
            extraProps={extraProps}
            loading={jobsLoading}
          />
        </Panel>
        <Pagination page={page} pages={pages} getPageLink={getPageLink} />
        {children}
      </div>
    )
  }
}
