// @flow
import * as API from 'api'
import type { Action } from 'shared/store/types'

import { AUTOSUGGEST, UPLOAD_IMAGE_ATTACHMENT, GET_CAREER_QUESTIONNAIRE_QUESTIONS } from 'store/actionTypes'

export const uploadImageAttachment = (parentType: string, parentId: string, image: File): Action => ({
  types: UPLOAD_IMAGE_ATTACHMENT,
  api: API.uploadImageAttachment(parentType, parentId, image),
  payload: { parentType, parentId },
})

export const autosuggest = (type: string, query: string): Action => ({
  types: AUTOSUGGEST,
  api: API.autosuggest(type, query),
})

export const getCareerQuestinnaireQuestions = (): Action => ({
  types: GET_CAREER_QUESTIONNAIRE_QUESTIONS,
  api: API.getCareerQuestinnaireQuestions(),
})
