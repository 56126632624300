// @flow
import type { Action } from 'shared/store/types'
import { handleActions } from 'redux-actions'
import { loadable, loadableSelector } from 'store/enhancers/loadable'
import * as API from 'api'
import { GET_JOB_FUNNEL } from 'store/actionTypes'

export type FullJobFunnelData = {
  +id: number,
}

type Query = {
  jobId: string,
  id: string,
}

export default loadable(GET_JOB_FUNNEL)(handleActions({}, {}))

export const getJobFunnel = (query: Query): Action => ({
  types: GET_JOB_FUNNEL,
  api: API.getJobFunnel(query),
  payload: { query },
})

export const jobFunnelSelector = loadableSelector('fullJobFunnels')
