// @flow
import * as React from "react"
import type { FullJobData } from "store/reducers/fullJobs"
import { map, each, isPlainObject } from "lodash"
import humanize from "shared/utils/humanize"

import { Field } from "redux-form/immutable"
import Button from "components/Button"
import { Columns, Column } from "components/Columns"
import {
  TextField,
  RichEditorField,
  DateTimeField,
  CheckboxField,
  CheckboxesField,
  SelectField,
  PreloadedAutosuggestField,
  CountryAutosuggestField,
  ArrayAutosuggestField,
} from "components/fields"
import PreloadedAutosuggestInput from "components/inputs/Autosuggest/Preloaded"
import FormField from "components/Field"
import ImageUploader from "components/inputs/ImageUploader"
import { alert } from "views/Prompts"

import s from "./Edit.scss"

type Props = {
  job: FullJobData,
  handleSubmit: () => Promise<any>,
  deleteJob: () => Promise<any>,
  submitting: boolean,
  invalid: boolean,
  uploadImage: (File) => Promise<*>,
  uploadImageAttachment: (File) => Promise<*>,
  publish: () => Promise<*>,
  unpublish: () => Promise<*>,
  suggestCountry: (string) => Promise<*>,
  suggestCity: (string) => Promise<*>,
  suggestIndustry: (string) => Promise<*>,
  suggestTypeOfProblem: (string) => Promise<*>,
  suggestDataType: (string) => Promise<*>,
  currentEmploymentType: ?string,
}

const COMPANY_SIZE_OPTIONS = [
  { label: "", value: "" },
  {
    label: "1-10 employees",
    value: "1-10",
  },
  {
    label: "10-50 employees",
    value: "10-50",
  },
  {
    label: "50-200 employees",
    value: "50-200",
  },
  {
    label: ">200 employees",
    value: ">200",
  },
]
const EXPERIENCE_OPTIONS = [
  { label: "", value: "" },
  {
    label: "Less than a year",
    value: "<1",
  },
  {
    label: "1-2 years",
    value: "1-2",
  },
  {
    label: "2-5 years",
    value: "2-5",
  },
  {
    label: "More than 5 years",
    value: ">5",
  },
]
const EMPLOYMENT_TYPE_OPTIONS = [
  { label: "", value: "" },
  {
    label: "Full-time",
    value: "fulltime",
  },
  {
    label: "Part-time",
    value: "parttime",
  },
  {
    label: "Contract",
    value: "contract",
  },
  {
    label: "Internship",
    value: "internship",
  },
]

const DATA_SCIENCE_FUNCTIONS = map(
  ["Data engineering", "Data modelling", "ML Application Deployment", "Business/Data Analysis", "AI infrastructure"],
  (v) => ({ value: v, label: v })
)

export default class Job extends React.Component<Props> {
  publish = () => this.props.publish().catch(this.showPublicationErrors)

  showPublicationErrors = (e: any) => {
    if (isPlainObject(e)) {
      const message = []
      each(e, (error, field) => {
        message.push(`${humanize(field)} ${error}`)
      })

      alert(message.join("\n"))
    }
  }

  render() {
    const {
      job,
      deleteJob,
      handleSubmit,
      invalid,
      submitting,
      uploadImageAttachment,
      unpublish,
      suggestCountry,
      suggestCity,
      uploadImage,
      suggestIndustry,
      suggestTypeOfProblem,
      suggestDataType,
      currentEmploymentType,
    } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Columns>
          <Column rigid>
            <FormField>
              <ImageUploader
                value={job.image}
                upload={uploadImage}
                className={s.image}
                defaultHeight={200}
                minHeight={50}
              />
            </FormField>
          </Column>
          <Column centered>
            <Field name="title" placeholder="Title" component={TextField} />
            <Field name="subtitle" placeholder="Subtitle" component={TextField} />
          </Column>
          <Column rigid>
            <Button
              type="button"
              promised
              right
              size="small"
              kind={job.published ? "danger" : "primary"}
              outline={job.published}
              onClick={job.published ? unpublish : this.publish}
            >
              {job.published ? "Unpublish" : "Publish"}
            </Button>
            {!job.published && (
              <Button type="button" size="small" kind="danger" onClick={deleteJob} outline promised>
                Delete
              </Button>
            )}
          </Column>
        </Columns>

        <Columns onlyDesktop>
          <Column>
            <Field name="company" label="Company" component={TextField} />
          </Column>
          <Column>
            <Field name="company_url" label="Company URL" component={TextField} />
          </Column>
          <Column>
            <Field name="company_size" label="Company Size" component={SelectField} options={COMPANY_SIZE_OPTIONS} />
          </Column>
          <Column>
            <Field
              name="country"
              label="Country"
              component={CountryAutosuggestField}
              getSuggestions={suggestCountry}
            />
          </Column>
          <Column>
            <Field name="city" label="City" component={PreloadedAutosuggestField} getSuggestions={suggestCity} />
          </Column>
        </Columns>

        <Columns>
          <Column>
            <Field name="experience" label="Experience" component={SelectField} options={EXPERIENCE_OPTIONS} />
          </Column>
          <Column>
            <Field
              name="employment_type"
              label="Employment Type"
              component={SelectField}
              options={EMPLOYMENT_TYPE_OPTIONS}
            >
              <Field checkbox name="remote" label="Remote" component={CheckboxField} nomargin />
            </Field>
          </Column>
          <Column>
            {(currentEmploymentType === "contract" || currentEmploymentType === "internship") && (
              <Field name="duration" label="Duration" component={TextField} />
            )}
          </Column>
        </Columns>

        <Columns onlyDesktop>
          <Column>
            <Field
              name="industry"
              label="Industry"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestIndustry}
              showAllWhenEmpty
            />
          </Column>
          <Column>
            <Field
              name="type_of_problem"
              label="Type of Problem"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestTypeOfProblem}
              showAllWhenEmpty
            />
          </Column>
        </Columns>
        <Columns onlyDesktop>
          <Column>
            <Field
              name="data_type"
              label="Data Type"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestDataType}
              showAllWhenEmpty
            />
          </Column>
          <Column>
            <Field
              name="data_science_functions"
              label="Data Science Functions"
              component={CheckboxesField}
              options={DATA_SCIENCE_FUNCTIONS}
            />
          </Column>
        </Columns>

        <Columns>
          <Column>
            <Field name="publish_time_string" label="Published at" component={DateTimeField} />
          </Column>
          <Column>
            <Field name="closing_time_string" label="Closing at" component={DateTimeField} />
          </Column>
        </Columns>

        <Field
          name="content"
          component={RichEditorField}
          uploadImageAttachment={uploadImageAttachment}
          nobox
          fullWidth
          placeholder="Content…"
        />
        <Field checkbox name="allow_online_applications" label="Allow online applications" component={CheckboxField} nomargin />
        <Button type="submit" disabled={submitting || invalid} loading={submitting}>
          Save
        </Button>
      </form>
    )
  }
}
