// @flow
import * as React from "react"
import { type UpdateCompetitionData } from "store/reducers/fullCompetitions"
import { Columns, Column } from "components/Columns"
import { map } from "lodash"
import Spinner from "components/Spinner"

import Field from "components/Field"
import Select from "components/inputs/Select"
import Text from "components/inputs/Text"
import SingleFileInput from "components/inputs/SingleFileInput"
import Autosaving from "components/inputs/Autosaving"
import { ERROR_METRICS } from "config"

import s from "./ReferenceAndErrorMetric.scss"

const ERROR_METRIC_OPTIONS = [
  { label: "Not set", value: "" },
  ...map(ERROR_METRICS, (label, value) => ({ label, value })),
].sort((a, b) => (a.label > b.label ? 1 : -1))

const getPlainValue = (value) => value

type Props = {
  getCompetition: () => Promise<any>,
  public_reference: File | string,
  private_reference: File | string,
  benchmark_submission: File | string,
  submissions_per_day_limit: ?number,
  total_submissions_limit: ?number,
  default_submissions_per_day_limit: number,
  error_metric: string,
  updateCompetition: (UpdateCompetitionData) => Promise<*>,
  benchmark_submission_status: string,
  benchmark_submission_public_score: ?number,
  benchmark_submission_private_score: ?number,
  fetchBenchmarkScores: () => Promise<*>,
}

export default class ReferenceAndErrorMetric extends React.Component<Props> {
  saveErrorMetric = (value: string) => this.props.updateCompetition({ error_metric: value })
  savePublicReference = (value: File) => this.props.updateCompetition({ public_reference: value })
  savePrivateReference = (value: File) => this.props.updateCompetition({ private_reference: value })
  saveBenchmarkSubmission = (value: File) => this.props.updateCompetition({ benchmark_submission: value })
  saveSubmissionsPerDayLimit = (value: string) => this.props.updateCompetition({ submissions_per_day_limit: value })
  saveTotalSubmissionsLimit = (value: string) => this.props.updateCompetition({ total_submissions_limit: value })

  updateCompetitionData = () => {}

  state = {
    fetchingBenchmarkScores: false,
  }

  componentDidMount() {
    this.maybeFetchBenchmarkScores()
  }

  componentDidUpdate() {
    this.maybeFetchBenchmarkScores()
  }

  maybeFetchBenchmarkScores = () => {
    if (this.props.benchmark_submission_status === "initial") {
      this.fetchBenchmarkScores()
    }
  }

  fetchBenchmarkScores = () => {
    if (this.state.fetchingBenchmarkScores) return

    this.setState({ fetchingBenchmarkScores: true })

    new Promise((resolve) => {
      setTimeout(resolve, 1000)
    })
      .then(this.props.fetchBenchmarkScores)
      .then(() => {
        this.setState({ fetchingBenchmarkScores: false })

        this.maybeFetchBenchmarkScores()
      })
  }

  render() {
    const {
      public_reference,
      private_reference,
      benchmark_submission,
      error_metric,
      submissions_per_day_limit,
      total_submissions_limit,
      default_submissions_per_day_limit,
      benchmark_submission_status,
      benchmark_submission_public_score,
      benchmark_submission_private_score,
    } = this.props

    return (
      <Columns>
        <Column>
          <Field
            label={
              <span>
                Public reference file
                {public_reference && typeof public_reference === "string" && (
                  <span>
                    &nbsp;(<a href={public_reference}>Download</a>)
                  </span>
                )}
              </span>
            }
          >
            <Autosaving
              value={public_reference}
              getValue={getPlainValue}
              placeholder="Upload…"
              save={this.savePublicReference}
              render={(props) => <SingleFileInput {...props} loading={props.disabled} />}
            />
          </Field>
          <Field
            nomargin
            label={
              <span>
                Private reference file
                {private_reference && typeof private_reference === "string" && (
                  <span>
                    &nbsp;(<a href={private_reference}>Download</a>)
                  </span>
                )}
              </span>
            }
          >
            <Autosaving
              value={private_reference}
              getValue={getPlainValue}
              placeholder="Upload…"
              save={this.savePrivateReference}
              render={(props) => <SingleFileInput {...props} loading={props.disabled} />}
            />
          </Field>
        </Column>
        <Column>
          <Field label="Error metric">
            <Autosaving
              value={error_metric}
              save={this.saveErrorMetric}
              render={(props) => <Select {...props} options={ERROR_METRIC_OPTIONS} />}
            />
          </Field>
          <Columns>
            <Column>
              <Field label="Submissions / day limit" nomargin>
                <Autosaving
                  value={submissions_per_day_limit || ""}
                  save={this.saveSubmissionsPerDayLimit}
                  saveOnChange={false}
                  saveOnBlur
                  saveOnEnter
                  render={(props) => <Text {...props} placeholder={default_submissions_per_day_limit} />}
                />
              </Field>
            </Column>
            <Column>
              <Field label="Total submissions limit" nomargin>
                <Autosaving
                  value={total_submissions_limit || ""}
                  save={this.saveTotalSubmissionsLimit}
                  saveOnChange={false}
                  saveOnBlur
                  saveOnEnter
                  render={(props) => <Text {...props} placeholder="No limit" />}
                />
              </Field>
            </Column>
          </Columns>
          <Field
            label={
              <span>
                Benchmark Submission
                {benchmark_submission && typeof benchmark_submission === "string" && (
                  <span>
                    &nbsp;(<a href={benchmark_submission}>Download</a>)
                  </span>
                )}
              </span>
            }
          >
            <Autosaving
              value={benchmark_submission}
              getValue={getPlainValue}
              placeholder="Upload…"
              save={this.saveBenchmarkSubmission}
              render={(props) => <SingleFileInput {...props} loading={props.disabled} />}
            />
            <div className={s.benchmark_submission_scores}>
              {benchmark_submission_status === "initial" && <Spinner className={s.benchmark_spinner} color="black" />}
              {benchmark_submission_status === "failure" && <div className={s.benchmark_error}>Scoring error</div>}
              {benchmark_submission_status === "successful" && (
                <div>
                  <div>public: {benchmark_submission_public_score || "n/a"}</div>
                  <div>private: {benchmark_submission_private_score || "n/a"}</div>
                </div>
              )}
            </div>
          </Field>
        </Column>
      </Columns>
    )
  }
}
