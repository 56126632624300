// @flow
import type { RouterHistory } from "react-router-dom"

import { withPropsOnChange } from "recompose"
import { parse, stringify } from "qs"

export const setSearch = (currentQuery: {}, history: RouterHistory) => (search: string) => {
  history.replace({
    search: `?${stringify({
      ...currentQuery,
      query: search || undefined,
      page: undefined,
    })}`,
  })
}

const getSearch = ({ location, history }) => {
  const query = location.search ? parse(location.search.replace(/^\?/, "")) : {}
  const search = query.query

  return {
    search,
    setSearch: setSearch(query, history),
  }
}

export default withPropsOnChange(["location"], getSearch)
