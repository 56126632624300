// @flow
import { type CompetitionData } from 'store/reducers/competitions'
import { type LocationShape } from 'react-router-dom'
import * as React from 'react'
import Helmet from 'react-helmet'

import Panel from 'components/Panel'
import Button from 'components/Button'
import { Columns, Column } from 'components/Columns'
import LinkWithQuery from 'components/LinkWithQuery'
import SearchInput from 'components/SearchInput'
import DataTable, { bindSortableHeaderToProps, renderers } from 'components/DataTable'
import Pagination from 'components/Pagination'

import s from './Competitions.scss'

type Props = {
  competitions: ?(CompetitionData[]),
  competitionsLoading: boolean,
  page: number,
  pages: ?number,
  getPageLink: (number) => LocationShape,
  sortBy: string,
  sortReversed: string,
  getSortLink: (string, ?boolean) => LocationShape,
  search?: string,
  setSearch: (string) => any,
  children?: React.Node,
}

const Title = ({ competition: { id, title, subtitle, parent } }) => (
  <LinkWithQuery to={`/competitions/${id}`} className={s.titleContainer}>
    <span className={s.title}>{title}</span>
    {parent && (
      <span className={s.parent}>
        part of <strong>{parent.title}</strong>
      </span>
    )}
    <span className={s.subtitle}>{subtitle}</span>
  </LinkWithQuery>
)

const COLUMNS = [
  {
    key: 'published',
    render: (published) => (published ? 'Yes' : 'No'),
    width: 80,
  },
  {
    key: 'title',
    render: (title, competition) => <Title competition={competition} />,
    width: 200,
    flexible: true,
  },
  {
    key: 'start_time',
    render: renderers.date,
    width: 150,
  },
  {
    key: 'end_time',
    render: renderers.date,
    width: 150,
  },
  {
    key: 'created_at',
    render: renderers.date,
    width: 150,
  },
]

const HACKATHON_COLUMNS = [
  ...COLUMNS.slice(0, 2), // insert after title
  {
    key: 'country',
    width: 150,
  },
  {
    key: 'city',
    width: 150,
  },
  {
    key: 'organization',
    width: 150,
  },
  ...COLUMNS.slice(2, 100),
]

export default class Competitions extends React.PureComponent<Props> {
  render() {
    const {
      competitions,
      competitionsLoading,
      page,
      pages,
      getPageLink,
      sortBy,
      sortReversed,
      getSortLink,
      search,
      setSearch,
      children,
    } = this.props

    const SortableHeader = bindSortableHeaderToProps({
      sortBy,
      sortReversed,
      getSortLink,
    })

    return (
      <>
        <Helmet title="Competitions" />
        <Columns>
          <Column>
            <Panel nopadding>
              <SearchInput value={search} setValue={setSearch} />
            </Panel>
          </Column>
          <Column rigid>
            <LinkWithQuery to={`/competitions/new`}>
              <Button>Add</Button>
            </LinkWithQuery>
          </Column>
        </Columns>
        <Panel nopadding>
          <DataTable
            keyColumn="id"
            data={competitions}
            columns={COLUMNS}
            headerComponent={SortableHeader}
            loading={competitionsLoading}
          />
        </Panel>
        <Pagination page={page} pages={pages} getPageLink={getPageLink} />
        {children}
      </>
    )
  }
}
