// @flow
import * as React from "react"

import type { Column } from "./index"
import Cell from "./Cell"
import s from "./index.scss"

export default class HeaderCell extends React.PureComponent<{
  column: Column<*, *>,
  component?: React.ComponentType<*>,
}> {
  renderLabel = ({ label, key }: Column<*, *>): React.Node => (label === undefined ? key : label)

  render() {
    const { column, component: HeaderComponent } = this.props

    let cell = this.renderLabel(column)
    if (HeaderComponent) {
      cell = <HeaderComponent column={column}>{cell}</HeaderComponent>
    }

    return (
      <Cell column={column} className={s.headerCell}>
        {cell}
      </Cell>
    )
  }
}
