// @flow
import * as React from 'react'
import { Field } from 'redux-form/immutable'
import _parameterize from 'parameterize'
import memoize from 'shared/utils/memoize'

import { getCompetitionPathFromKind } from 'containers/concerns/getCompetitionKind'
import { type FullCompetitionData } from 'store/reducers/fullCompetitions'
import { Columns, Column } from 'components/Columns'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import {
  TextField,
  TextFieldWithPrefix,
  DateTimeField,
  CheckboxField,
  TextareaField,
  SelectField,
  SelectAutosuggestField,
  PreloadedAutosuggestField,
  ArrayAutosuggestField,
} from 'components/fields'
import PreloadedAutosuggestInput from 'components/inputs/Autosuggest/Preloaded'
import FormField from 'components/Field'
import ImageUploader from 'components/inputs/ImageUploader'

import s from './BasicInfoForm.scss'

const FRONTEND_URL = process.env.FRONTEND_URL || ''

const parameterize = memoize(20)(_parameterize)
const normalize = (value) => parameterize(value)

const KIND_OPTIONS = [
  { value: 'competition', label: 'Competition' },
  { value: 'hackathon', label: 'Hackathon' },
]

const REWARD_TYPE_OPTIONS = [
  { value: 'prize', label: 'Prize' },
  { value: 'points', label: 'Points' },
  { value: 'knowledge', label: 'Knowledge' },
]

type Props = {
  handleSubmit: (any) => any,
  submitting: boolean,
  invalid: boolean,
  change: (string, any) => any,
  uploadImage: (File) => Promise<*>,
  competition: FullCompetitionData,
  whitelist_only: boolean,
  limit_number_of_participants: boolean,
  secret_code_required: boolean,
  suggestCompetition: (string) => Promise<*>,
  suggestCountry: (string) => Promise<*>,
  suggestCity: (string) => Promise<*>,
  suggestOrganization: (string) => Promise<*>,
  suggestIndustry: (string) => Promise<*>,
  suggestTypeOfProblem: (string) => Promise<*>,
  suggestDataType: (string) => Promise<*>,
  suggestImpact: (string) => Promise<*>,
  suggestDifficulty: (string) => Promise<*>,
  suggestSkills: (string) => Promise<*>,
  suggestTopics: (string) => Promise<*>,
  suggestHelping: (string) => Promise<*>,
  end_time_string: ?string,
}

type OrganizationSuggestion = { value: string, organization_url: ?string }

export default class BasicInfoForm extends React.Component<Props> {
  onTitleChange = (_event: any, value: string) => {
    this.props.change('url_title', parameterize(value))
  }

  renderOrganizationSuggestion = ({ value, organization_url }: OrganizationSuggestion) => (
    <span>
      {value} {organization_url && <small>({organization_url})</small>}
    </span>
  )

  onOrganizationSelected = (_e: any, { suggestion }: { suggestion: OrganizationSuggestion }) => {
    this.props.change('organization_url', suggestion.organization_url)
  }

  render() {
    const {
      invalid,
      handleSubmit,
      submitting,
      uploadImage,
      competition: { image, published_at, kind },
      whitelist_only,
      suggestCompetition,
      suggestCountry,
      suggestCity,
      suggestOrganization,
      suggestIndustry,
      suggestTypeOfProblem,
      suggestDataType,
      suggestImpact,
      suggestDifficulty,
      suggestSkills,
      suggestTopics,
      suggestHelping,
      end_time_string,
      secret_code_required,
      limit_number_of_participants,
    } = this.props

    const autoUpdateUrlTitle = !published_at

    return (
      <form onSubmit={handleSubmit}>
        <Columns>
          <Column rigid>
            <FormField>
              <ImageUploader
                value={image}
                upload={uploadImage}
                className={s.image}
                defaultHeight={200}
                minHeight={50}
              />
            </FormField>
          </Column>
          <Column>
            <Columns>
              <Column grow="2">
                <Field
                  name="title"
                  label="Title"
                  nomargin
                  component={TextField}
                  onChange={autoUpdateUrlTitle ? this.onTitleChange : undefined}
                >
                  <Field
                    name="url_title"
                    component={TextFieldWithPrefix}
                    prefix={`${FRONTEND_URL}/${getCompetitionPathFromKind(kind)}/`}
                    normalize={normalize}
                    noborder
                    size="small"
                  />
                </Field>
              </Column>
              <Column rigid>
                <Field name="kind" label="Kind" options={KIND_OPTIONS} component={SelectField} />
              </Column>
              <Column>
                <Field
                  label="Parent Competition"
                  name="parent"
                  component={SelectAutosuggestField}
                  getSuggestions={suggestCompetition}
                />
              </Column>
            </Columns>
            <Field name="subtitle" label="Subtitle" component={TextField} />
          </Column>
        </Columns>
        <Columns>
          <Column rigid>
            <Field
              name="reward_type"
              label="Reward Type"
              options={REWARD_TYPE_OPTIONS}
              component={SelectField}
              className={s.rewardTypeField}
            />
          </Column>
          <Column>
            <Field name="reward" label="Reward" component={TextField} className={s.rewardField} />
          </Column>
          <Column>
            <Field name="points_reward" label="Points Reward" component={TextField} />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Field name="visible_time_string" label="Visible at" component={DateTimeField} />
          </Column>
          <Column>
            <Field name="start_time_string" label="Starts at" component={DateTimeField} />
          </Column>
          <Column>
            <Field name="end_time_string" label="Ends at" component={DateTimeField} />
          </Column>
          <Column>
            <Field
              name="reveal_time_string"
              label="Private ranks revealed at"
              placeholder={end_time_string}
              component={DateTimeField}
            />
          </Column>
        </Columns>

        <Columns onlyDesktop>
          <Column>
            <Field
              name="country"
              label="Country"
              component={PreloadedAutosuggestField}
              getSuggestions={suggestCountry}
            />
          </Column>
          <Column>
            <Field name="city" label="City" component={PreloadedAutosuggestField} getSuggestions={suggestCity} />
          </Column>
        </Columns>

        <Columns onlyDesktop>
          <Column>
            <Field
              name="organization"
              label="Organization"
              component={PreloadedAutosuggestField}
              getSuggestions={suggestOrganization}
              renderSuggestion={this.renderOrganizationSuggestion}
              onSuggestionSelected={this.onOrganizationSelected}
            />
          </Column>
          <Column>
            <Field name="organization_url" label="Organization URL" component={TextField} />
          </Column>
        </Columns>

        <Columns onlyDesktop>
          <Column>
            <Field
              name="industry"
              label="Industry"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestIndustry}
              showAllWhenEmpty
            />
          </Column>
          <Column>
            <Field
              name="type_of_problem"
              label="Type of Problem"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestTypeOfProblem}
              showAllWhenEmpty
            />
          </Column>
        </Columns>

        <Columns onlyDesktop>
          <Column>
            <Field
              name="data_type"
              label="Data Type"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestDataType}
              showAllWhenEmpty
            />
          </Column>
          <Column>
            <Field
              name="impact"
              label="Impact"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestImpact}
              showAllWhenEmpty
            />
          </Column>
        </Columns>

        <Columns onlyDesktop>
          <Column>
            <Field
              name="difficulty"
              label="Difficulty"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestDifficulty}
              showAllWhenEmpty
            />
          </Column>
          <Column>
            <Field
              name="skills"
              label="Skills"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestSkills}
              showAllWhenEmpty
            />
          </Column>
        </Columns>

        <Columns onlyDesktop>
          <Column>
            <Field
              name="topics"
              label="Topics"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestTopics}
              showAllWhenEmpty
            />
          </Column>
          <Column>
            <Field
              name="helping"
              label="Helping"
              component={ArrayAutosuggestField}
              baseInput={PreloadedAutosuggestInput}
              getSuggestions={suggestHelping}
              showAllWhenEmpty
            />
          </Column>
        </Columns>

        <Columns>
          <Column>
            <Columns onlyDesktop>
              <Column>
                <Field checkbox name="whitelist_only" label="Whitelist only" component={CheckboxField} />
              </Column>
              <Column>
                {whitelist_only && (
                  <Field
                    checkbox
                    name="visible_to_whitelist_only"
                    label="Visible to whitelist only"
                    component={CheckboxField}
                  />
                )}
              </Column>
            </Columns>
            {whitelist_only && (
              <Field
                name="participants_whitelist"
                label="Emails whitelist"
                component={TextareaField}
                className={s.participants_whitelist}
              />
            )}
            <Field name="associated_ambassadors" label="Associated Ambassadors (optional comma separated list of emails)" component={TextField} />
          </Column>
          <Column>
            <Field checkbox name="secret_code_required" label="Require secret code" component={CheckboxField} />
            {secret_code_required && <Field name="secret_code" label="Secret Code" component={TextField} />}

            <Field
              checkbox
              name="limit_number_of_participants"
              label="Limit number of participants"
              component={CheckboxField}
            />
            {limit_number_of_participants && (
              <Field name="max_number_of_participants" label="Maximum number of participants" component={TextField} />
            )}

            <Field
              name="max_number_of_participants_per_team"
              label="Maximum number of participants per team"
              component={TextField}
              placeholder="4"
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Field checkbox name="allow_teams" label="Allow teams" component={CheckboxField} />
            <Field checkbox name="show_submission_count" label="Show submission count" component={CheckboxField} />
            <Field
              checkbox
              name="discussions_are_private"
              label="Hide discussions from non-participants"
              component={CheckboxField}
            />
          </Column>
        </Columns>

        {submitting && (
          <div className={s.submittingOverlay}>
            <Spinner className={s.submittingSpinner} />
          </div>
        )}

        <Button type="submit" disabled={submitting || invalid} loading={submitting}>
          Save
        </Button>
      </form>
    )
  }
}
