// @flow
import { getJob, jobSelector } from 'store/reducers/fullJobs'
import { connect } from 'react-redux'
import { withPropsOnChange, compose } from 'recompose'

import preload from 'decorators/preload'

import Job from 'views/Job'

export default compose(
  withPropsOnChange(['match'], ({ match: { params: { id } } }) => ({
    jobId: id,
  })),
  connect(
    (state, props) => ({
      job: jobSelector(state, props.jobId),
    }),
    (dispatch, props) => ({
      getJob: () => dispatch(getJob(props.jobId)),
    }),
  ),
  preload({
    job: {
      load: 'getJob',
      optional: false,
    },
  }),
)(Job)
