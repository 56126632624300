// @flow
import { type JobFunnelData, type UpdateData as JobFunnelUpdateData } from 'store/reducers/jobFunnels'
import * as React from 'react'
import { Link, Route, type RouterHistory } from 'react-router-dom'
import { map, groupBy, capitalize } from 'lodash'
import { DndProvider, useDrop, useDrag } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import c from 'classnames'

import Application from 'containers/Job/Application'
import Modal from 'components/Modal'
import User from 'components/User'
import s from './Applications.scss'

import DownloadSVG from '../../assets/download.svg'

// const FRONTEND_URL = process.env.FRONTEND_URL || ''
//
type UpdateJobFunnel = (id: string, data: JobFunnelUpdateData) => Promise<*>

type Props = {
  jobFunnels: ?(JobFunnelData[]),
  updateJobFunnel: UpdateJobFunnel,
  urlPrefix: string,
  history: RouterHistory,
  jobId: string,
  getCareerQuestinnaireQuestions: () => Promise<*>,
  // jobFunnelsLoading: boolean,
}

const DRAGGABLE_TYPE = 'DRAGGABLE_JOB_FUNNEL_CARD'

function Card({
  jobFunnel: {
    id,
    user: { username, avatar },
    user_full_name,
  },
  updating,
  movable,
  urlPrefix,
}: {
  jobFunnel: JobFunnelData,
  updating: ?boolean,
  movable: boolean,
  urlPrefix: string,
}) {
  const [{ isDragging }, drag] = useDrag({
    item: {
      id,
      username,
      type: DRAGGABLE_TYPE,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  return (
    <Link
      to={`${urlPrefix}/${id}`}
      className={c(s.card, movable && s.movable, updating && s.cardUpdating, isDragging && s.cardDragging)}
      ref={movable ? drag : null}
    >
      <User username={username} avatar={avatar} />
      <div className={s.fullname}>{user_full_name}</div>
    </Link>
  )
}

function Column({
  status,
  jobFunnels,
  updateJobFunnel,
  itemsUpdating,
  movable = true,
  urlPrefix,
}: {
  status: string,
  jobFunnels: ?(JobFunnelData[]),
  updateJobFunnel: UpdateJobFunnel,
  itemsUpdating: { [string]: boolean },
  movable?: boolean,
  urlPrefix: string,
}) {
  const [{ isOver }, drop] = useDrop({
    accept: DRAGGABLE_TYPE,
    drop: (item) => {
      updateJobFunnel(item.id, { status })
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  const title = React.useMemo(() => capitalize(status), [status])
  const someJobFunnelsFound = jobFunnels?.length > 0

  function saveBlob(blob, fileName) {
    // https://gist.github.com/philipstanislaus/c7de1f43b52531001412
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const download = (item) => {
    if(item == null) return

    const filename = item.url.split('/').slice(-1)[0]

    fetch(item.url, {method: "GET"})
    .then((res) => { return res.blob(); })
    .then( blob => {
      saveBlob(blob, `${item.userFullname}.${filename.split('.').slice(-1)[0]}`)
    });
  };
  
  function multiDownload(items) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, item] of items.entries()) {
      if(item.url != null){
        download(item)
      }
      
    }
  }
  const downloadAll = () => {
    const items = jobFunnels.map(j => {return {url: j.cv_url, userFullname: j.user_full_name}})
    multiDownload(items)
  }
  return (
    <div className={c(s.column, isOver && s.columnDragOver)} ref={movable ? drop : null}>
      <div className={s.columnTitle}>{title} {someJobFunnelsFound && <DownloadSVG className={s.downloadIcon} onClick={downloadAll} />} </div>

      <div className={s.cards}>
        {map(jobFunnels, (jobFunnel) => (
          <Card
            key={jobFunnel.id}
            jobFunnel={jobFunnel}
            movable={movable}
            updating={itemsUpdating[jobFunnel.id]}
            urlPrefix={urlPrefix}
          />
        ))}
      </div>
    </div>
  )
}

export default function JobFunnels({
  jobFunnels,
  updateJobFunnel: _updateJobFunnel,
  urlPrefix,
  history,
  jobId,
  getCareerQuestinnaireQuestions,
}: Props) {
  const [itemsUpdating, setItemsUpdating] = React.useState({})
  const groupedApplications = React.useMemo(() => groupBy(jobFunnels, 'status'), [jobFunnels])

  const [careerQuestionnaireQuestions, setCareerQuestionnaireQuestions] = React.useState(undefined)

  React.useEffect(() => {
    getCareerQuestinnaireQuestions().then((data) => {
      setCareerQuestionnaireQuestions(data)
    })
  }, [])

  const closeModal = React.useCallback(() => {
    history.push(urlPrefix)
  }, [history, urlPrefix])

  const updateJobFunnel = React.useCallback(
    (id, data) => {
      setItemsUpdating((ids) => ({ ...ids, [id]: true }))

      const unsetUpdating = () => setItemsUpdating((ids) => ({ ...ids, [id]: false }))

      return _updateJobFunnel(id, data).then(
        () => {
          unsetUpdating()
        },
        (e) => {
          unsetUpdating()

          if (e) {
            throw e
          }
        },
      )
    },
    [itemsUpdating, _updateJobFunnel],
  )

  return (
    <div className={s.columns}>
      <DndProvider backend={HTML5Backend}>
        <Column
          itemsUpdating={itemsUpdating}
          updateJobFunnel={updateJobFunnel}
          status="viewed"
          movable={false}
          jobFunnels={groupedApplications.viewed}
          urlPrefix={urlPrefix}
        />
        <Column
          itemsUpdating={itemsUpdating}
          updateJobFunnel={updateJobFunnel}
          status="declined"
          jobFunnels={groupedApplications.declined}
          urlPrefix={urlPrefix}
        />
        <Column
          itemsUpdating={itemsUpdating}
          updateJobFunnel={updateJobFunnel}
          status="applied"
          jobFunnels={groupedApplications.applied}
          urlPrefix={urlPrefix}
        />
        <Column
          itemsUpdating={itemsUpdating}
          updateJobFunnel={updateJobFunnel}
          status="shortlisted"
          jobFunnels={groupedApplications.shortlisted}
          urlPrefix={urlPrefix}
        />
        <Column
          itemsUpdating={itemsUpdating}
          updateJobFunnel={updateJobFunnel}
          status="interviewed"
          jobFunnels={groupedApplications.interviewed}
          urlPrefix={urlPrefix}
        />
        <Column
          itemsUpdating={itemsUpdating}
          updateJobFunnel={updateJobFunnel}
          status="hired"
          jobFunnels={groupedApplications.hired}
          urlPrefix={urlPrefix}
        />
      </DndProvider>

      <Route path={`${urlPrefix}/:id`}>
        {({ match }) => (
          <Modal isOpen={!!match} onRequestClose={closeModal}>
            {match && (
              <Application
                jobId={jobId}
                id={match.params.id}
                careerQuestionnaireQuestions={careerQuestionnaireQuestions}
              />
            )}
          </Modal>
        )}
      </Route>
    </div>
  )
}
