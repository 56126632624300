// @flow
import * as React from "react"
import Button from "components/Button"
import Base, { type Props } from "./Base"

import s from "./Alert.scss"

export default class Alert extends React.Component<Props> {
  setButtonRef = (ref) => {
    this.button = ref
  }

  componentDidMount() {
    setTimeout(this.focus, 100)
  }

  focus = () => {
    if (this.button) {
      this.button.focus()
    }
  }

  render() {
    const {
      prompt: { okLabel, content, modalProps, resolve },
      ...props
    } = this.props

    return (
      <Base {...props} {...modalProps} onRequestClose={resolve}>
        {content}
        <div className={s.buttons}>
          <Button kind="primary" onClick={resolve} ref={this.setButtonRef}>
            {okLabel || "Ok"}
          </Button>
        </div>
      </Base>
    )
  }
}
