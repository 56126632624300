// @flow
import * as React from "react"
import { Field } from "redux-form/immutable"
import Helmet from "react-helmet"

import Button from "components/Button"
import Panel from "components/Panel"
import { TextField } from "components/fields"

type Props = {
  handleSubmit: (any) => any,
  submitting: boolean,
  invalid: boolean,
}

export default class Job extends React.Component<Props> {
  render() {
    const { invalid, handleSubmit, submitting } = this.props

    return (
      <Panel nopadding title="New Job" width="medium">
        <Helmet title="New Job" />
        <form onSubmit={handleSubmit}>
          <Field label="Title" name="title" nomargin component={TextField} autoFocus />
          <Button type="submit" disabled={submitting || invalid} loading={submitting}>
            Next →
          </Button>
        </form>
      </Panel>
    )
  }
}
