// @flow
import * as React from "react"
import { map } from "lodash"
import { List } from "immutable"
import c from "classnames"
import s from "./Checkboxes.scss"

export type Option = {
  value: string | number,
  label: React.Node,
  disabled?: boolean,
}
export type Options = Option[]

export type Props = {
  name: string,
  value?: List,
  type?: string,
  disabled?: boolean,
  invalid?: boolean,
  autoFocus?: boolean,
  className?: string,
  style?: { [string]: ?(number | string) },
  onChange?: (string[]) => any,
  options: Options,
}

type State = {
  value: string[],
}

class OptionComponent extends React.PureComponent<{
  name: string,
  value: string,
  checked: boolean,
  disabled: boolean,
  label: React.Node,
  onChange?: (SyntheticEvent<*>) => any,
}> {
  input = React.createRef()

  focus = () => {
    if (this.input.current) {
      this.input.current.focus()
    }
  }

  render() {
    const { name, value, label, disabled, checked, onChange } = this.props

    return (
      <label className={s.option}>
        <span className={s.input}>
          <input
            ref={this.input}
            type="checkbox"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        </span>{" "}
        <span className={s.label}>{label}</span>
      </label>
    )
  }
}

export default class CheckboxesInput extends React.PureComponent<Props, State> {
  input = React.createRef<HTMLInputElement>()

  static defaultProps = {
    type: "text",
    options: [],
  }

  state = {
    value: this.props.value || new List(),
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.value !== this.state.value) {
      this.props.onChange(this.state.value)
    }
  }

  focus = () => {
    if (this.input.current) {
      this.input.current.focus()
    }
  }

  onChange = (e: SyntheticEvent<*>) => {
    const { checked, value: currentValue } = e.currentTarget

    this.setState(({ value }) => ({
      value: checked ? value.push(currentValue).toSet().toList() : value.filter((v) => v !== currentValue),
    }))
  }

  render() {
    const { name, invalid, className, options, onChange, ...otherProps } = this.props

    const { value: currentValue } = this.state

    return (
      <div {...otherProps} className={c(s.container, invalid && s.invalid, className)} onBlur={null} onFocus={null}>
        {map(options, ({ value, label, disabled }, index) => (
          <OptionComponent
            key={value}
            name={name}
            value={value}
            disabled={disabled}
            label={label}
            checked={currentValue.includes(value)}
            ref={value === currentValue || index === 0 ? this.input : null}
            onChange={this.onChange}
          />
        ))}
      </div>
    )
  }
}
