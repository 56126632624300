// @flow
import { updateJob, deleteJob, getCountries } from "store/reducers/fullJobs"
import { connect } from "react-redux"
import { reduxForm, formValueSelector } from "redux-form/immutable"
import { withPropsOnChange, compose } from "recompose"

import { uploadImageAttachment, autosuggest } from "store/reducers/misc"
import validate from "shared/utils/validate"
import handleFormErrors from "shared/utils/handleFormErrors"
import showSavingErrors from "utils/showSavingErrors"

import { confirm } from "views/Prompts"
import { serialize, parse } from "components/inputs/RichEditor"

import Job from "views/Job/Edit"

const formSelector = formValueSelector("job")

export default compose(
  connect(
    (state) => ({
      currentEmploymentType: formSelector(state, "employment_type"),
    }),
    (dispatch, props) => ({
      uploadImage: (file: File) => dispatch(updateJob(props.jobId, { image: file })).catch(showSavingErrors),
      deleteJob:   () =>
        confirm("Delete post?")
          .then(() => dispatch(deleteJob(props.jobId)))
          .then(() => props.history.push("/blog")),
      publish:              () => dispatch(updateJob(props.jobId, { published: true })),
      unpublish:            () => dispatch(updateJob(props.jobId, { published: false })),
      suggestCountry:       () => dispatch(getCountries()),
      suggestCity:          (query) => dispatch(autosuggest("city", query)),
      suggestIndustry:      (query) => dispatch(autosuggest("industry", query)),
      suggestTypeOfProblem: (query) => dispatch(autosuggest("type_of_problem", query)),
      suggestDataType:      (query) => dispatch(autosuggest("data_type", query)),
    })
  ),
  connect(
    () => ({}),
    (dispatch, props) => ({
      uploadImageAttachment: (image) => dispatch(uploadImageAttachment("Job", props.job.id, image)),
    })
  ),
  withPropsOnChange(["job"], ({ job }) => ({
    initialValues: {
      ...job,
      content: parse(job.content),
    },
  })),
  reduxForm({
    form: "job",
    enableReinitialize: true,
    validate: validate({
      title: ["required"],
    }),
    onSubmit: (values, dispatch, props) =>
      dispatch(updateJob(props.job.id, values.set("content", serialize(values.get("content"))).toJSON())).catch(
        handleFormErrors
      ),
  })
)(Job)
