// @flow
import * as React from 'react'
import ReactModal from 'react-modal'
import c from 'classnames'

import s from './Modal.scss'

ReactModal.setAppElement('#app')

type Props = {
  className?: string,
  overlayClassName?: string,
  children: React.Node,
  onRequestClose?: () => any,
}

class Modal extends React.Component<Props> {
  padding: ?HTMLDivElement

  onPaddingClick = (e: SyntheticEvent<HTMLDivElement>) => {
    const { onRequestClose } = this.props
    if (e.target === this.padding && onRequestClose) {
      onRequestClose()
    }
  }

  render() {
    const { className, overlayClassName, children, onRequestClose, ...props } = this.props

    return (
      <ReactModal
        {...props}
        onRequestClose={onRequestClose}
        className={c(s.modal, className, 'zindi_modal')}
        overlayClassName={c(s.overlay, overlayClassName)}
      >
        <div
          className={s.padding}
          onClick={this.onPaddingClick}
          ref={(ref) => {
            this.padding = ref
          }}
        >
          <div className={s.inner}>{children}</div>
        </div>
      </ReactModal>
    )
  }
}

export default Modal
