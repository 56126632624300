// @flow
import * as React from "react"
import { createInlineStyleButton, CodeBlockButton } from "draft-js-buttons"
import createCodeEditorPlugin from "draft-js-code-editor-plugin"
import Configuration from "../configuration"

import s from "../styles/code.scss"

export default () => ({ addPlugin, addInlineTextButton, addInlineBlockButton }: Configuration) => {
  const codeEditorPlugin = createCodeEditorPlugin()
  addPlugin(codeEditorPlugin)

  const CodeButton = createInlineStyleButton({
    style: "CODE",
    children: <span className={s.monospace}>m</span>,
  })

  addInlineTextButton(CodeButton)
  addInlineBlockButton(CodeBlockButton)
}
