// @flow
import * as React from "react"

import Spinner from "components/Spinner"

import c from "classnames"
import s from "./Button.scss"

type Props = {
  kind: "blank" | "primary" | "secondary" | "danger",
  outline: boolean,
  children?: React.Node,
  onClick?: () => any,
  disabled?: boolean,
  loading?: boolean,
  spinnerColor?: "white" | "black",
  className?: string,
  size: "normal" | "small",
  promised: boolean,
}

type State = {
  loading: ?boolean,
}

export default class Button extends React.Component<Props, State> {
  static defaultProps = {
    kind: "primary",
    size: "normal",
    promised: false,
    outline: false,
  }
  mounted: boolean

  mounted = false
  state = {
    loading: null,
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  setLoading = () => this.mounted && this.setState(() => ({ loading: true }))
  unsetLoading = () => this.mounted && this.setState(() => ({ loading: false }))

  focus = () => {
    if (this.button) {
      this.button.focus()
    }
  }
  setButtonRef = (ref) => {
    this.button = ref
  }

  onClick = (e: SyntheticEvent<*>) => {
    if (e && e.preventDefault) e.preventDefault()

    if (this.props.onClick) {
      const clicked = this.props.onClick()

      if (clicked && clicked.then && this.props.promised) {
        this.setLoading()
        clicked.then(this.unsetLoading, this.unsetLoading)
      }
    }
  }

  render() {
    const {
      children,
      onClick,
      disabled: disabledProps,
      loading: loadingProps,
      kind,
      spinnerColor,
      className,
      size,
      promised,
      outline,
      ...otherProps
    } = this.props

    const { loading: loadingState } = this.state

    const loading = loadingProps === undefined ? loadingState : loadingProps
    const disabled = disabledProps === undefined ? loading : disabledProps

    return (
      <button
        {...otherProps}
        disabled={disabled}
        className={c(
          s.base,
          loading && s.loading,
          disabled && s.disabled,
          s[`${kind}-${outline ? "outline" : "normal"}`],
          s[`size-${size}`],
          className
        )}
        onClick={onClick ? this.onClick : null}
        ref={this.setButtonRef}
      >
        <span className={s.inner}>{children}</span>
        {loading && <Spinner className={c(s.spinner, s[`spinner-${size}`])} color={spinnerColor} />}
      </button>
    )
  }
}
