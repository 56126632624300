// @flow
import * as React from "react"
import { assign } from "lodash"
import type { Plugin } from "./types"

export default class Configuration {
  inlineTextButtons: React.ComponentType<*>[]
  inlineBlockButtons: React.ComponentType<*>[]
  newBlockButtons: React.ComponentType<*>[]
  plugins: Plugin[]
  components: React.ComponentType<*>[]
  props: { [string]: any }

  constructor() {
    this.inlineTextButtons = []
    this.inlineBlockButtons = []
    this.newBlockButtons = []
    this.plugins = []
    this.components = []
    this.props = {}
  }

  addInlineTextButton = (button: React.ComponentType<*>) => this.inlineTextButtons.push(button)
  addInlineBlockButton = (button: React.ComponentType<*>) => this.inlineBlockButtons.push(button)
  addNewBlockButton = (button: React.ComponentType<*>) => this.newBlockButtons.push(button)
  addPlugin = (plugin: Plugin) => this.plugins.push(plugin)
  addComponent = (component: React.ComponentType<*>) => this.components.push(component)
  addProps = (props: {}) => {
    assign(this.props, props)
  }

  toJS() {
    return {
      props: {
        ...this.props,
        plugins: this.plugins,
      },
      components: this.components,
    }
  }
}
