// @flow
import * as React from "react"
import { Link, type LocationShape } from "react-router-dom"
import c from "classnames"
import memoize from "shared/utils/memoize"

import { type Column } from "./index"
import s from "./SortableHeader.scss"

export default class SortableHeader extends React.Component<{
  sortBy: string,
  sortReversed: string,
  getSortLink: (string, ?boolean) => LocationShape,
  column: Column<*, *>,
  children?: React.Node,
}> {
  render() {
    const {
      children,
      sortBy,
      sortReversed,
      getSortLink,
      column: { key, sortable },
    } = this.props

    if (sortable === false) {
      return children
    }

    const active = key === sortBy

    return (
      <Link to={getSortLink(key, active ? !sortReversed : false)} className={c(s.link, active && s.active)}>
        {children}
      </Link>
    )
  }
}

export const bindToSortProps = memoize(1)(({ sortBy, sortReversed, getSortLink }): React.StatelessFunctionalComponent<
  *
> => (props) => <SortableHeader sortBy={sortBy} sortReversed={sortReversed} getSortLink={getSortLink} {...props} />)
