// @flow
import { getCompetitions, querySelector } from 'store/reducers/competitions'
import { connect } from 'react-redux'
import Competitions from 'views/Competitions'
import withPagination from 'shared/decorators/withPagination'
import withSorting from 'shared/decorators/withSorting'
import withSearch from 'shared/decorators/withSearch'

import { compose, withPropsOnChange } from 'recompose'
import preload from 'decorators/preload'
import getCompetitionKind from './concerns/getCompetitionKind'

export default compose(
  withPagination({ per_page: 50 }),
  withSorting({ default: 'published' }),
  withSearch,
  connect(
    (state, props) => ({
      ...querySelector(state, props),
    }),
    (dispatch, props) => ({
      getCompetitions: () =>
        dispatch(
          getCompetitions({
            page: props.page,
            per_page: props.per_page,
            sort_by: props.sortBy,
            sort_reversed: props.sortReversed,
            search: props.search,
          }),
        ),
    }),
  ),
  preload({
    competitions: {
      load: 'getCompetitions',
      optional: true,
      replaceWithData: false,
    },
  }),
  withPropsOnChange(['competitions'], ({ competitions }) => ({
    competitions: competitions.data,
    competitionsLoading: competitions.loading,
  })),
)(Competitions)
