// @flow
import * as React from "react"
import { pick } from "lodash"
import Base, { REACT_AUTOSUGGEST_PROPS } from "components/inputs/Autosuggest/Base"
import TextArrayInput from "components/inputs/TextArray"

import c from "classnames"
import s from "./Array.scss"

const theme = {
  container: s.input,
  suggestionsContainer: s.suggestions,
}

type Props = {
  getSuggestions?: (any) => Promise<*>,
  baseInput: React.ComponentType<*>,
}
type InputProps = {
  addTag: (string) => void,
  onChange: ({ target: { value: string } }) => void,
  ref: (any) => void,
}

const renderInputComponent = (props) => <input {...props} className={c(props.className, "react-tagsinput-input")} />

export default class ArrayInput extends React.Component<Props> {
  static defaultProps = {
    baseInput: Base,
  }

  renderInput = ({ addTag, ...props }: InputProps) => {
    const { getSuggestions, baseInput: BaseInput } = this.props
    const autosuggestProps = pick(this.props, REACT_AUTOSUGGEST_PROPS)

    const onChange = (value: string) => {
      props.onChange({ target: { value } })
    }

    const onSuggestionSelected = (e, { suggestionValue, method }) => {
      if (method === "enter") {
        e.preventDefault()
      }

      addTag(suggestionValue)
    }

    return (
      <BaseInput
        ref={props.ref}
        {...autosuggestProps}
        {...props}
        hideFullMatch={false}
        theme={theme}
        onChange={onChange}
        getSuggestions={getSuggestions}
        onSuggestionSelected={onSuggestionSelected}
        renderInputComponent={renderInputComponent}
      />
    )
  }

  render() {
    return <TextArrayInput {...this.props} renderInput={this.renderInput} />
  }
}
