// @flow
import { handleActions } from 'redux-actions'
import * as API from 'api'
import type { Action } from 'shared/store/types'
import { loadable, loadableSelector } from 'store/enhancers/loadable'
import { get, pick } from 'lodash'
import { GET_JOB_FUNNELS, UPDATE_JOB_FUNNEL } from 'store/actionTypes'

export type JobFunnelData = {
  id: string,
  user: {
    username: string,
    avatar: ?string,
  },
}

export type UpdateData = {
  status?: string,
}

export type Query = {
  jobId: ?string,
  page: number,
  per_page: number,
  sort_by: string,
  sort_reversed: boolean,
  search: ?string,
}

export default loadable(GET_JOB_FUNNELS)(
  handleActions(
    {
      [UPDATE_JOB_FUNNEL.START]: (state, { payload: { id, data } }) => ({
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            ...data,
          },
        },
      }),
      [UPDATE_JOB_FUNNEL.FAILURE]: (state, { payload: { id, dataBeforeUpdate } }) => ({
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            ...dataBeforeUpdate,
          },
        },
      }),
    },
    {},
  ),
)

export const getJobFunnels = (query: Query): Action => ({
  types: GET_JOB_FUNNELS,
  api: API.getJobFunnels(query),
  payload: { query },
})

export const updateJobFunnel = (jobId: string, id: string, data: UpdateData) => (
  dispatch: (Action) => any,
  getState: () => any,
) => {
  const dataBeforeUpdate = pick(get(getState(), ['jobFunnels', 'data', id]), ['status'])

  return dispatch({
    types: UPDATE_JOB_FUNNEL,
    api: API.updateJobFunnel(jobId, id, data),
    payload: { jobId, id, data, dataBeforeUpdate },
  })
}

export const jobFunnelsSelector = loadableSelector('jobFunnels')
export const jobFunnelSelector = (state: any, id: string) => state.jobFunnels.data[id]
