// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'

import ModalRoute from 'components/ModalRoute'

import App from 'containers/App'
import Layout from 'containers/Layout'
import GuestLayout from 'views/GuestLayout'

import OTP from 'containers/OTP'
import SignIn from 'containers/SignIn'
import PasswordRequest from 'containers/PasswordRequest'
import PasswordReset from 'containers/PasswordReset'

import Users from 'containers/Users'
import UserForm from 'containers/UserForm'
import EditUserForm from 'components/Forms/EditUserForm'

import Admins from 'containers/Admins'
import AdminForm from 'containers/AdminForm'
import NewAdminForm from 'containers/NewAdminForm'

import Competitions from 'containers/Competitions'
import Competition from 'containers/Competition'
import NewCompetition from 'containers/NewCompetition'

import BlogPosts from 'containers/BlogPosts'
import NewBlogPost from 'containers/NewBlogPost'
import BlogPost from 'containers/BlogPost'

import Jobs from 'containers/Jobs'
import NewJob from 'containers/NewJob'
import Job from 'containers/Job'

import Discussions from 'containers/Discussions'
import DiscussionPreview from 'containers/DiscussionPreview'

import Reports from 'containers/Reports'

const PrivateRoutes = connect(({ cookie }) => ({
  signedIn: !!cookie.signed_in && cookie.auth_token,
}))(({ children, signedIn, ...rest }) => (
  <Route {...rest} render={() => (signedIn ? children : <Redirect to="/auth/signin" />)} />
))

const GuestRoutes = connect(({ cookie }) => ({
  signedIn: !!cookie.signed_in && cookie.auth_token,
}))(({ children, signedIn, ...rest }) => (
  <Route {...rest} render={() => (!signedIn ? children : <Redirect to="/" />)} />
))

export default () => (
  <App>
    <Switch>
      <Route path="/" exact>
        <Redirect to="/competitions" />
      </Route>
      <GuestRoutes path="/auth">
        <GuestLayout>
          <Switch>
            <Route path="/auth/signin/otp" component={OTP} />
            <Route path="/auth/signin" component={SignIn} />
            <Route path="/auth/passwords/request" component={PasswordRequest} />
            <Route path="/auth/passwords/reset/:resetToken" component={PasswordReset} />
          </Switch>
        </GuestLayout>
      </GuestRoutes>

      <PrivateRoutes>
        <Layout>
          <Route
            path="/admins"
            render={(adminsProps) => (
              <>
                <Admins {...adminsProps} />
                <Switch>
                  <ModalRoute path="/admins/new" component={NewAdminForm} closeLink="/admins" />
                  <ModalRoute path="/admins/:id" component={AdminForm} closeLink="/admins" />
                </Switch>
              </>
            )}
          />
          <Route
            path="/users"
            render={(props) => (
              <Switch>
                <Route path="/users/:username/edit" component={EditUserForm} />
                <>
                  <Users {...props} />
                  <ModalRoute path="/users/:username" component={UserForm} closeLink="/users" />
                </>
              </Switch>
            )}
          />
          <Route
            path="/discussions"
            render={(props) => (
              <>
                <Discussions {...props} />
                <ModalRoute path="/discussions/:id" component={DiscussionPreview} closeLink="/discussions" />
              </>
            )}
          />
          <Route
            path="/jobs"
            render={() => (
              <Switch>
                <Route exact path={['/jobs', '/jobs/new']}>
                  <>
                    <Route path="/jobs" component={Jobs} />
                    <ModalRoute path="/jobs/new" component={NewJob} closeLink="/jobs" />
                  </>
                </Route>
                <Route path="/jobs/:id" component={Job} />
              </Switch>
            )}
          />
          <Route path="/reports" render={(props) => <Reports {...props} />} />
          <Switch>
            <Route exact path="/competitions" component={Competitions} />
            <Route path="/competitions/new" component={NewCompetition} />
            <Route path="/competitions/:id/:tab?" component={Competition} />
          </Switch>
          <Switch>
            <Route exact path="/blog" component={BlogPosts} />
            <Route path="/blog/new" component={NewBlogPost} />
            <Route path="/blog/:id" component={BlogPost} />
          </Switch>
        </Layout>
      </PrivateRoutes>
    </Switch>
  </App>
)
