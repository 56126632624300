// @flow
import { handleActions } from 'redux-actions'
import * as API from 'api'
import type { Action } from 'shared/store/types'
import { loadable, loadableSelector } from 'store/enhancers/loadable'
import { GET_REPORTS, UPDATE_REPORT, HIDE_COMMENT } from 'store/actionTypes'

export type ReportData = {
  id: string,
  reasons: string,
  reportable_type: string,
  report_link: string,
  user: {
    username: string,
    avatar: ?string,
  },
}

export type UpdateData = {
  resolved?: boolean
}

export type Query = {
  page: number,
  per_page: number,
  sort_by: string,
  sort_reversed: boolean,
  search: ?string,
}

export default loadable(GET_REPORTS)(
  handleActions(
    {
      [UPDATE_REPORT.SUCCESS]: (
        state,
        {
          payload: {
            id,
            result: { data },
          },
        },
      ) => ({
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            ...data,
          },
        },
      }),
    },
    {},
  ),
)

export const getReports = (query: Query): Action => ({
  types: GET_REPORTS,
  api: API.getReports(query),
  payload: { query },
})

export const updateReport = (id: string, data: UpdateData) => ({
  types: UPDATE_REPORT,
  api: API.updateReport(id, data),
  payload: { id, data },
})
export const hideComment = (id: string) => ({
  types: HIDE_COMMENT,
  api: API.hideComment(id),
  payload: { id },
})
export const querySelector = loadableSelector('reports')
