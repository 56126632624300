import Routes from "routes"

let _ClientRoot = Routes

if (module.hot) {
  const { hot } = require("react-hot-loader/root") // eslint-disable-line global-require

  _ClientRoot = hot(_ClientRoot)
}

const ClientRoot = _ClientRoot

export default ClientRoot
