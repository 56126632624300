// @flow
import createAnchorPlugin from "draft-js-anchor-plugin"
import Configuration from "../configuration"

import defaultStyles from "../styles/link.scss"
import mergeStyles from "../utils/mergeStyles"

type Config = {
  styles: {},
}

export default (config: Config) => ({ addPlugin, addInlineTextButton }: Configuration) => {
  const styles = mergeStyles(defaultStyles, config.styles)

  const linkPlugin = createAnchorPlugin({
    theme: styles,
    placeholder: "http://…",
  })

  addPlugin(linkPlugin)
  addInlineTextButton(linkPlugin.LinkButton)
}
