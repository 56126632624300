// @flow
import { handleActions } from 'redux-actions'
import * as API from 'api'
import type { Action } from 'shared/store/types'
import { loadable, loadableSelector } from 'store/enhancers/loadable'
import { GET_DISCUSSIONS, UPDATE_DISCUSSION } from 'store/actionTypes'

export type DiscussionData = {
  id: string,
  title: string,
  user: {
    username: string,
    avatar: ?string,
  },
}

export type UpdateData = {
  published?: boolean,
  banned?: boolean,
}

export type Query = {
  page: number,
  per_page: number,
  sort_by: string,
  sort_reversed: boolean,
  search: ?string,
}

export default loadable(GET_DISCUSSIONS)(
  handleActions(
    {
      [UPDATE_DISCUSSION.SUCCESS]: (
        state,
        {
          payload: {
            id,
            result: { data },
          },
        },
      ) => ({
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            ...data,
          },
        },
      }),
    },
    {},
  ),
)

export const getDiscussions = (query: Query): Action => ({
  types: GET_DISCUSSIONS,
  api: API.getDiscussions(query),
  payload: { query },
})

export const updateDiscussion = (id: string, data: UpdateData) => ({
  types: UPDATE_DISCUSSION,
  api: API.updateDiscussion(id, data),
  payload: { id, data },
})

export const querySelector = loadableSelector('discussions')
