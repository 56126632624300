// @flow
import * as React from "react"

import Svg from "components/Svg"
import Button from "components/Button"

import s from "./LoadingError.scss"
import icon from "../assets/feather/wifi-off.svg"

type Props = {
  error: string | { [string]: string },
  retry: () => any,
}

export default class LoadingError extends React.PureComponent<Props> {
  renderMessage() {
    const { error } = this.props
    if (typeof error === "string") {
      return error
    } else if (error.message) {
      return error.message
    }
    return "Can't load stuff"
  }

  render() {
    const { retry } = this.props

    return (
      <div className={s.container}>
        <Svg icon={icon} className={s.icon} />
        <div className={s.message}>{this.renderMessage()}</div>
        <div className={s.retry}>
          <Button kind="primary" size="small" onClick={retry}>
            Retry
          </Button>
        </div>
      </div>
    )
  }
}
