// @flow
import * as React from "react"
import { type FullCompetitionData } from "store/reducers/fullCompetitions"
import { Field } from "redux-form/immutable"
import _parameterize from "parameterize"
import memoize from "shared/utils/memoize"

import { getCompetitionPathFromKind } from "containers/concerns/getCompetitionKind"
import { Columns, Column } from "components/Columns"
import Button from "components/Button"
import { TextField, TextFieldWithPrefix, RichEditorField } from "components/fields"

const FRONTEND_URL = process.env.FRONTEND_URL || ""

const parameterize = memoize(20)(_parameterize)
const normalize = (value) => parameterize(value)

type Props = {
  competition: FullCompetitionData,
  handleSubmit: (any) => any,
  deletePage: () => Promise<*>,
  submitting: boolean,
  invalid: boolean,
  change: (string, any) => any,
  uploadImageAttachment: (image: File) => Promise<*>,
  pageRequired: boolean,
  contentOnly: boolean,
  placeholder?: string,
}

type State = {
  deleting: boolean,
}

export default class Page extends React.PureComponent<Props, State> {
  static defaultProps = {
    placeholder: "Content…",
  }

  state = {
    deleting: false,
  }

  onTitleChange = (_event: any, value: string) => {
    this.props.change("url_title", parameterize(value))
  }

  onDelete = () => {
    this.setState(() => ({ deleting: true }))

    this.props.deletePage().catch((e) => {
      this.setState(() => ({ deleting: false }))

      if (e) {
        throw e
      }
    })
  }

  render() {
    const {
      invalid,
      handleSubmit,
      submitting,
      uploadImageAttachment,
      competition: { url_title },
      pageRequired,
      contentOnly,
      placeholder,
      competition,
    } = this.props

    const { deleting } = this.state

    return (
      <form onSubmit={handleSubmit}>
        {contentOnly || (
          <Field name="title" nomargin component={TextField} onChange={this.onTitleChange}>
            <Field
              name="url_title"
              component={TextFieldWithPrefix}
              prefix={`${FRONTEND_URL}/${getCompetitionPathFromKind(competition.kind)}/${url_title}/`}
              normalize={normalize}
              size="small"
              noborder
            />
          </Field>
        )}
        <Field
          name="content"
          component={RichEditorField}
          uploadImageAttachment={uploadImageAttachment}
          nobox
          fullWidth
          placeholder={placeholder}
        />
        <Columns>
          <Column>
            <Button type="submit" disabled={submitting || invalid} loading={submitting}>
              Save
            </Button>
          </Column>
          <Column rigid>
            {!pageRequired && !contentOnly && (
              <Button
                kind="danger"
                size="small"
                onClick={this.onDelete}
                tabIndex={-1}
                disabled={deleting}
                loading={deleting}
              >
                Delete
              </Button>
            )}
          </Column>
        </Columns>
      </form>
    )
  }
}
