// @flow
import * as React from 'react'
import type { RouterHistory, LocationShape } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { compact, invert } from 'lodash'
import type { FullCompetitionData, PageData } from 'store/reducers/fullCompetitions'

import Helmet from 'react-helmet'

import Button from 'components/Button'
import Panel from 'components/Panel'
import { Columns, Column } from 'components/Columns'
import { Tabs, TabList, TabPanel, Tab, TabButton } from 'components/Tabs'

import BasicInfoForm from 'containers/Competition/BasicInfoForm'
import Pages from 'containers/Competition/Pages'
import Data from 'containers/Competition/Data'
import Submissions from 'containers/Competition/Submissions'
import Leaderboard from 'containers/Competition/Leaderboard'
import Misc from 'containers/Competition/Misc'

import s from './Competition.scss'

const TABS = {
  pages: 1,
  data: 2,
  submissions: 3,
  leaderboard: 4,
  misc: 5,
}
const INVERTED_TABS = invert(TABS)

type Props = {
  competition: FullCompetitionData,
  deleteCompetition: () => Promise<any>,
  sealCompetition: () => Promise<any>,
  selectedTab: ?string,
  history: RouterHistory,
  location: LocationShape,
  dataDescriptionPage: PageData,
  sensitiveDataDescriptionPage: PageData,
  publish: () => Promise<*>,
  unpublish: () => Promise<*>,
}
type State = {
  newPageOpen: boolean,
}
export default class Competition extends React.Component<Props, State> {
  getSelectedTab = () => {
    const { selectedTab } = this.props

    return (selectedTab && TABS[selectedTab]) || 0
  }

  onTabSelect = (index: number) => {
    const { history, competition } = this.props
    const tab = INVERTED_TABS[index] || null

    history.push(compact([`/competitions/${competition.id}`, tab]).join('/'))
  }

  render() {
    const {
      competition,
      deleteCompetition,
      dataDescriptionPage,
      sensitiveDataDescriptionPage,
      unpublish,
      publish,
      location,
      history,
      sealCompetition,
    } = this.props

    const { published, kind } = competition

    return (
      <div>
        <Helmet title={competition.title} />
        <Panel nopadding>
          <Columns>
            <Column centered>
              <div className={s.title}>{competition.title}</div>
            </Column>
            <Column rigid className={s.buttons}>
              <Link to={`/competitions/new?clone_from=${competition.id}:${competition.title}&kind=${competition.kind}`}>
                <Button size="small" kind="secondary" outline>
                  Clone competition
                </Button>
              </Link>
              <Button size="small" outline promised onClick={sealCompetition} disabled={competition.sealed}>
                {competition.sealed ? 'Sealed' : `Seal competition`}
              </Button>
              <Button size="small" kind="danger" onClick={deleteCompetition} promised outline>
                Delete competition
              </Button>
            </Column>
          </Columns>
        </Panel>
        <Panel>
          <Tabs onSelect={this.onTabSelect} selectedIndex={this.getSelectedTab()} kind="panel">
            <TabList>
              <Tab>Basic</Tab>
              <Tab>Pages</Tab>
              <Tab>Data</Tab>
              <Tab>Submissions</Tab>
              <Tab>Leaderboard</Tab>
              <Tab>Misc</Tab>
              <TabButton
                promised
                right
                size="small"
                kind={published ? 'danger' : 'primary'}
                outline={published}
                onClick={published ? unpublish : publish}
              >
                {published ? 'Unpublish' : 'Publish'}
              </TabButton>
            </TabList>
            <TabPanel>
              <BasicInfoForm competition={competition} />
            </TabPanel>
            <TabPanel>
              <Pages competition={competition} pages={competition.pages} />
            </TabPanel>
            <TabPanel>
              <Data
                competition={competition}
                datafiles={competition.datafiles}
                dataDescriptionPage={dataDescriptionPage}
                sensitiveDataDescriptionPage={sensitiveDataDescriptionPage}
              />
            </TabPanel>
            <TabPanel>
              <Submissions
                competition={competition}
                competitionId={competition.id}
                history={history}
                location={location}
              />
            </TabPanel>
            <TabPanel>
              <Leaderboard
                competition={competition}
                competitionId={competition.id}
                history={history}
                location={location}
              />
            </TabPanel>
            <TabPanel>
              <Misc competition={competition} />
            </TabPanel>
          </Tabs>
        </Panel>
      </div>
    )
  }
}
