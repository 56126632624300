export const REQUIRED_PAGES = ["data", "description", "rules"]
export const ERROR_METRICS  = { // NOTE: This should not be hardcoded, but read from the API instead.
  null:                           "No scoring",
  mean_absolute_error:            "Mean Absolute Error",
  root_mean_squared_error:        "Root Mean Squared Error",
  log_loss:                       "Log Loss",
  hamming_loss:                   "Hamming Loss",
  area_under_curve:               "Area Under Curve (AUC)",
  f1:                             "F1 Micro",
  ibm_offboard:                   "RL BMI Malaria",
  indaba_grand:                   "RL Indaba Grand Challenge",
  uber_ambulance:                 "Uber Nairobi Ambulance Perambulation",
  dsn_ai_bootcamp_qualification:  "DSC Academy - Hard hat",
  sea_turtles:                    "Intersection Over Union Turtles Bounding Box",
  straight_accuracy:              "Straight Accuracy",
  bleu:                           "Rouge F-measure",
  ruby_bleu:                      "BLEU (Ruby, deprecated)",
  word_error_rate:                "Word Error Rate (WER)",
  mae:                            "Mean Absolute Error (Lacuna)",
  mean_absolute_precision3:       "Mean Absolute Precision @ 3",
  mean_absolute_precision5:       "Mean Absolute Precision @ 5",
  map_at_intersection_over_union: "MAP at Intersection Over Union",
  intersection_over_union:        "Intersection Over Union",
  recall_score:                   "Recall",
  reinforcement_learning:         "Reinforcement learning",
  wmape:                          "Weighted Mean Absolute Percentage Error",
}
