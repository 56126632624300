// @flow
import React from "react"
import Configuration from "../../configuration"
import Mention from "./Mention"
import strategy from "./strategy"

type Config = {
  className: string,
  disableIn: string[],
}

export default ({ className, disableIn }: Config) => ({ addPlugin }: Configuration) => {
  addPlugin({
    decorators: [
      {
        strategy: strategy({ disableIn }),
        component: (props) => <Mention {...props} className={className} />,
      },
    ],
  })
}
