// @flow
import * as React from "react"
import Button from "components/Button"
import Base, { type Props } from "./Base"

import s from "./Confirm.scss"

export default class Confirm extends React.Component<Props> {
  setButtonRef = (ref) => {
    this.button = ref
  }

  componentDidMount() {
    setTimeout(this.focus, 100)
  }

  focus = () => {
    if (this.button) {
      this.button.focus()
    }
  }

  render() {
    const {
      prompt: { okLabel, rejectLabel, content, modalProps, resolve, reject },
      ...props
    } = this.props

    return (
      <Base {...props} {...modalProps} onRequestClose={reject}>
        {content}
        <div className={s.buttons}>
          <div className={s.ok}>
            <Button kind="primary" onClick={resolve} ref={this.setButtonRef} className={s.ok}>
              {okLabel || "Ok"}
            </Button>
          </div>
          <div className={s.reject}>
            <Button kind="blank" onClick={reject} className={s.cancel} size="small">
              {rejectLabel || "No"}
            </Button>
          </div>
        </div>
      </Base>
    )
  }
}
