// @flow
import { handleActions } from "redux-actions"
import { includes } from "lodash"
import * as API from "api"
import type { Action } from "shared/store/types"
import { loadable, loadableSelector } from "store/enhancers/loadable"
import { GET_SUBMISSIONS, UPDATE_SUBMISSION } from "store/actionTypes"

export type SubmissionData = {
  +id: number,
  +username: string,
  +public_score: number,
  +private_score: number,
  +rescoring_public_score: number,
  +rescoring_private_score: number,
  +file: string,
  +created_at: number,
  +banned: boolean,
}

export type UpdateSubmissionData = {
  banned?: boolean,
}

export type Query = {
  competitionId: ?string,
  page: number,
  per_page: number,
  sort_by: string,
  sort_reversed: boolean,
  search: ?string,
}

const CLEAR_ON = [UPDATE_SUBMISSION.SUCCESS]

export default loadable(GET_SUBMISSIONS, {
  clearOn: (action) => includes(CLEAR_ON, action.type),
})(handleActions({}, {}))

export const getSubmissions = (query: Query): Action => ({
  types: GET_SUBMISSIONS,
  api: API.getSubmissions(query),
  payload: { query },
})

export const updateSubmission = (id: string, data: UpdateSubmissionData): Action => ({
  types: UPDATE_SUBMISSION,
  api: API.updateSubmission(id, data),
  payload: { id },
})

export const submissionsSelector = loadableSelector("submissions")
