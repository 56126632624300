// @flow
import type { FullJobData } from 'store/reducers/fullJobs'
import * as React from 'react'
import Helmet from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import { RouterTabs } from 'components/RouterTabs'

import Panel from 'components/Panel'

import Edit from 'containers/Job/Edit'
import Applications from 'containers/Job/Applications'

import s from './index.scss'

const TABS = [
  { to: '', exact: true, label: 'Info' },
  { to: '/applications', label: 'Applications' },
]

type Props = {
  job: FullJobData,
  jobId: string,
}

export default function JobIndex(props: Props) {
  const { job, jobId } = props

  const urlPrefix = `/jobs/${jobId}`

  return (
    <div className={s.container}>
      <Helmet title={job.title} />
      <Panel nopadding className={s.title}>
        {job.title}
      </Panel>
      <Panel className={s.content}>
        <RouterTabs kind="panel" urlPrefix={urlPrefix} items={TABS} />

        <Switch>
          <Route path={`${urlPrefix}`} exact render={() => <Edit {...props} />} />
          <Route
            path={`${urlPrefix}/applications`}
            render={() => <Applications {...props} urlPrefix={`${urlPrefix}/applications`} />}
          />
        </Switch>
      </Panel>
    </div>
  )
}
