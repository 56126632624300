// @flow
import { getAdmins, updateAdmin, querySelector } from "store/reducers/admins"
import { connect } from "react-redux"
import Admins from "views/Admins"
import withPagination from "shared/decorators/withPagination"
import withSorting from "shared/decorators/withSorting"
import withSearch from "shared/decorators/withSearch"

import { compose, withPropsOnChange } from "recompose"
import preload from "decorators/preload"

export default compose(
  withPagination({ per_page: 50 }),
  withSorting({ default: "created_at" }),
  withSearch,
  connect(
    (state, props) => ({
      me: state.me.data,
      ...querySelector(state, props),
    }),
    (dispatch, props) => ({
      getAdmins: () =>
        dispatch(
          getAdmins({
            page: props.page,
            per_page: props.per_page,
            sort_by: props.sortBy,
            sort_reversed: props.sortReversed,
            search: props.search,
          })
        ),
      setBanned: (id: number, banned: boolean) => dispatch(updateAdmin(id, { banned })),
    })
  ),
  preload({
    admins: {
      load: "getAdmins",
      optional: true,
      replaceWithData: false,
    },
  }),
  withPropsOnChange(["admins"], ({ admins }) => ({
    admins: admins.data,
    adminsLoading: admins.loading,
  }))
)(Admins)
