import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import updateUser from '../../mutations/UpdateUser'

import useCountriesQuery from '../../queries/UseCountriesQuery'
import useUserQuery, { queryKey } from '../../queries/UseUserQuery'

import User from '../User'
import Button from '../Button'

import s from "./EditUserForm.scss"

const FRONTEND_URL = process.env.FRONTEND_URL || 'https://zindi.africa'

export default function EditUserForm(props) {
  const [message, setMessage] = useState(undefined)
  const queryClient = useQueryClient()

  const userQuery          = useUserQuery(props.match.params.username)
  const countriesQuery     = useCountriesQuery()
  const updateUserMutation = useMutation((attributes) => updateUser(userQuery.data?.hashid, attributes), {
    onSuccess: (data) => {
      if (data === undefined) return

      queryClient.invalidateQueries(
        queryKey(data.username),
        (oldUser) => ({
          ...oldUser,
          ...data
        })
      )

      setMessage('Success!')
    },
    onError: (error) => {
      setMessage(error)
    }
  });

  if (userQuery.isLoading || countriesQuery.isLoading) return 'Loading...'

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    updateUserMutation.mutate(formProps)
  }

  return (
    <div>
      <h1 className={s.heading}>
        Edit:
        <a href={`${FRONTEND_URL}/users/${userQuery.data.username}`} target="_blank" rel="noopener noreferrer">
          <User username={userQuery.data.username} avatar={userQuery.data.avatar} />
        </a>
      </h1>

      <br />

      {message && <div className={message === 'Success!' ? s.success : s.failure}>{message}</div>}

      <form onSubmit={handleSubmit}>
        <div className={s.fieldGroup}>
          <label htmlFor="country">Country:</label>

          <select className={s.select} name="country" id="country" defaultValue={userQuery.data.country || "select"}>
            <option value="select" disabled>Choose a country</option>
            {countriesQuery.data.map(c => <option key={c} value={c}>{c}</option>)}
          </select>
        </div>

        <div className={s.submit}>
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </div>
  )
}
