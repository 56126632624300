// @flow
import * as React from "react"
import { Tabs as RawTabs, TabList as RawTabList, Tab as RawTab } from "react-tabs"
import Button from "components/Button"
import c from "classnames"
import s from "./Tabs.scss"

export { TabPanel } from "react-tabs"

export type Kind = "default" | "panel" | "horizontal" | "panel-secondary"

type TabsProps = {
  selectedClassName?: string,
  className?: string,
  kind: Kind,
}
export const Tabs = ({ selectedClassName, className, kind, ...props }: TabsProps) => (
  <RawTabs
    {...props}
    className={c(s.tabs, s[`kind-${kind}`], className)}
    selectedTabClassName={c(s.selectedTab, selectedClassName)}
  />
)
Tabs.tabsRole = "Tabs"

type TabListProps = {
  className?: string,
}
export const TabList = ({ className, ...props }: TabListProps) => (
  <RawTabList {...props} className={c(s.tabList, className)} />
)
TabList.defaultProps = {
  kind: "default",
}
TabList.tabsRole = "TabList"

type TabProps = {
  className?: string,
  right?: boolean,
  invisible?: boolean,
}
export class Tab extends RawTab {}
// export const Tab = React.forwardRef(({
//   className, right, invisible, ...props
// }: TabProps, ref) => (
//   <RawTab
//     { ...props }
//     tabRef={ ref }
//     className={ c(
//       s.tab,
//       right && s.tabRight,
//       invisible && s.tabInvisible,
//       className
//     ) }
//   />
// ))
// TODO breaks SortableTabs
Tab.tabsRole = "Tab"
Tab.defaultProps = {
  className: s.tab,
}

export const TabButton = ({ right, className, ...props }: { right?: boolean, className?: string }) => (
  <Button {...props} className={c(s.tabButton, right && s.tabButtonRight, className)} />
)
