// @flow
import * as React from 'react'
import type { DatafileData } from 'store/reducers/fullCompetitions'

import { VALIDATIONS } from 'shared/utils/validate'
import DataTable from 'components/DataTable'
import Autosaving from 'components/inputs/Autosaving'
import Button from 'components/Button'
import Text from 'components/inputs/Text'

import formatBytes from 'shared/utils/formatBytes'

const validate = (value) => !VALIDATIONS.required(value)

class FilenameInput extends React.PureComponent<{
  id: string,
  value: string,
  updateFilename: (string, string) => Promise<any>,
}> {
  save = (value) => {
    const { id, updateFilename, value: currentValue } = this.props

    if (value === currentValue) {
      return Promise.resolve()
    }

    if (!validate(value)) {
      return Promise.reject()
    }

    return updateFilename(id, value)
  }

  render() {
    const { value } = this.props

    return (
      <Autosaving
        value={value}
        save={this.save}
        saveOnChange={false}
        saveOnBlur
        render={(props) => <Text {...props} />}
      />
    )
  }
}

class DescriptionInput extends React.PureComponent<{
  id: string,
  value: string,
  updateDescription: (string, string) => Promise<any>,
}> {
  save = (value) => {
    const { id, updateDescription, value: currentValue } = this.props

    if (value === currentValue) {
      return Promise.resolve()
    }

    return updateDescription(id, value)
  }

  render() {
    const { value } = this.props

    return (
      <Autosaving
        value={value}
        save={this.save}
        saveOnChange={false}
        saveOnBlur
        render={(props) => <Text {...props} />}
      />
    )
  }
}

class DeleteButton extends React.PureComponent<{
  id: string,
  delete: (string) => any,
}> {
  render() {
    return (
      <Button onClick={this.delete} kind="danger" size="small" outline promised>
        Delete
      </Button>
    )
  }

  delete = () => this.props.delete(this.props.id)
}

const COLUMNS = [
  {
    key: 'filename',
    label: 'Filename',
    width: 100,
    flexible: true,
    render: (filename, { id }, { updateFilename }) => (
      <FilenameInput value={filename} id={id} updateFilename={updateFilename} />
    ),
  },
  {
    key: 'description',
    label: 'Description',
    width: 100,
    flexible: true,
    render: (description, { id }, { updateDescription }) => (
      <DescriptionInput value={description} id={id} updateDescription={updateDescription} />
    ),
  },
  {
    key: 'filesize',
    label: 'Size',
    render: (size) => formatBytes(size),
    width: 100,
    align: 'right',
  },
  {
    key: 'url',
    label: '',
    align: 'right',
    render: (url, { id }, { deleteDatafile }) => (
      <React.Fragment>
        <a href={url}>
          <Button size="small" outline tabIndex={-1}>
            Download
          </Button>
        </a>
        <DeleteButton id={id} delete={deleteDatafile} />
      </React.Fragment>
    ),
    width: 200,
  },
]

type Props = {
  datafiles: DatafileData[],
  updateFilename: (string, string) => Promise<any>,
  updateDescription: (string, string) => Promise<any>,
  deleteDatafile: (string) => Promise<any>,
}
export default class Data extends React.PureComponent<Props> {
  render() {
    const { datafiles, updateFilename, updateDescription, deleteDatafile } = this.props

    return (
      <DataTable
        keyColumn="id"
        columns={COLUMNS}
        data={datafiles}
        renderHeader={false}
        extraProps={{
          updateFilename,
          updateDescription,
          deleteDatafile,
        }}
      />
    )
  }
}
