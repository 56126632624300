// @flow
import { getJobFunnels, updateJobFunnel, jobFunnelsSelector } from 'store/reducers/jobFunnels'
import { getCareerQuestinnaireQuestions } from 'store/reducers/misc'
import { connect } from 'react-redux'
import withPagination from 'shared/decorators/withPagination'
import withSorting from 'shared/decorators/withSorting'
import withSearch from 'shared/decorators/withSearch'

import View from 'views/Job/Applications'

import { compose, withPropsOnChange } from 'recompose'
import preload from 'decorators/preload'

export default compose(
  withPropsOnChange(['jobId'], ({ jobId }) => ({
    query: {
      jobId,
      page: 0,
      per_page: 1000,
    },
  })),
  connect(
    (state, props) => ({
      jobFunnels: jobFunnelsSelector(state, props.query),
    }),
    (dispatch, props) => ({
      getJobFunnels: () => dispatch(getJobFunnels(props.query)),
      updateJobFunnel: (id, data) => dispatch(updateJobFunnel(props.jobId, id, data)),
      getCareerQuestinnaireQuestions: () => dispatch(getCareerQuestinnaireQuestions()),
    }),
  ),
  preload({
    jobFunnels: {
      load: 'getJobFunnels',
      optional: true,
      replaceWithData: false,
    },
  }),
  withPropsOnChange(['jobFunnels'], ({ jobFunnels }) => ({
    jobFunnels: jobFunnels.data,
    pages: jobFunnels.pages,
    jobFunnelsLoading: jobFunnels.loading,
  })),
)(View)
