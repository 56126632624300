// @flow
import { get } from 'lodash'

import {
  methodShortcuts,
  withReduxStore,
  logger,
  dedup,
  inlineParameters,
  createConnection,
} from 'redux-connection/lib/connection'

import { AUTH_ERROR } from 'store/actionTypes'
import type { Query as UserQuery, UpdateUserData } from 'store/reducers/users'
import type { Query as AdminQuery, NewAdminData, UpdateAdminData } from 'store/reducers/admins'
import type { Query as SubmissionsQuery, UpdateSubmissionData } from 'store/reducers/submissions'
import type { Query as ParticipationsQuery, UpdateParticipationData } from 'store/reducers/participations'
import type { Query as CompetitionQuery, NewCompetitionData } from 'store/reducers/competitions'
import type { Query as JobFunnelsQuery, UpdateData as JobFunnelUpdateData } from 'store/reducers/jobFunnels'
import type { Query as DiscussionsQuery, UpdateData as DiscussionUpdateData } from 'store/reducers/discussions'
import type { Query as ReportsQuery, UpdateData as ReportUpdateData } from 'store/reducers/reports'
import type {
  UpdateCompetitionData,
  CreatePageData,
  UpdatePageData,
  CreateDatafileData,
  UpdateDatafileData,
} from 'store/reducers/fullCompetitions'

import type { Query as JobQuery } from 'store/reducers/jobs'
import type { NewJobData, UpdateJobData } from 'store/reducers/fullJobs'

import type { Query as BlogPostQuery, CreateBlogPostData } from 'store/reducers/blogPosts'
import type { UpdateBlogPostData } from 'store/reducers/fullBlogPosts'

const PUBLIC_API_ENDPOINT = process.env.PUBLIC_API_ENDPOINT || ''
const API_ENDPOINT = process.env.API_ENDPOINT || ''
const HTTP_AUTH_ENABLED = process.env.HTTP_AUTH_ENABLED || false

const { GET, POST, DELETE, PATCH } = methodShortcuts(
  withReduxStore({
    handleResponseError: (error, store) => {
      if (error && error.message === 'not authorized') {
        store.dispatch({ type: AUTH_ERROR })
      }
    },
    enhanceRequest: (store) => {
      const auth_token = get(store.getState(), ['cookie', 'auth_token'])

      if (auth_token) {
        return { headers: { 'Auth-Token': auth_token } }
      }

      return {}
    },
  })(
    logger(process.env.VERBOSE)(
      dedup(
        inlineParameters(
          createConnection({
            endpoint: API_ENDPOINT,
            credentials: HTTP_AUTH_ENABLED ? 'include' : 'omit',
            extractErrorResponse: (error) => error.data.errors,
            paramsStringificationOptions: { arrayFormat: 'brackets' },
          }),
        ),
      ),
    ),
  ),
)

const PUBLIC_API = methodShortcuts(
  withReduxStore({})(
    logger(process.env.VERBOSE)(
      dedup(
        inlineParameters(
          createConnection({
            endpoint: PUBLIC_API_ENDPOINT,
            extractErrorResponse: (error) => error.data.errors,
            paramsStringificationOptions: { arrayFormat: 'brackets' },
          }),
        ),
      ),
    ),
  ),
)

export const getMe = () => GET('/me')
export const signIn = (email: string, password: string) => POST('/auth/signin', { email, password })
export const submitOTP = (otp: string) => POST('/auth/otp', { otp })
export const signOut = () => DELETE('/auth/signout')
export const requestPasswordReset = (email: string) => POST('/auth/passwords/request', { email })
export const resetPassword = (token: string, password: string) => POST('/auth/passwords/reset', { token, password })

export const getUser = (username: string) => GET('/users/:username', { username })
export const getUsers = (query: UserQuery) => GET('/users', query)
export const updateUser = (id: number, data: UpdateUserData) => PATCH('/users/:id', { ...data, id })

export const getAdmins = (query: AdminQuery) => GET('/admins', query)
export const createAdmin = (data: NewAdminData) => POST('/admins', { ...data })
export const updateAdmin = (id: number, data: UpdateAdminData) => PATCH('/admins/:id', { ...data, id })

export const getCompetitions = (query: CompetitionQuery) => GET('/competitions', query)
export const getCompetition = (id: string) => GET('/competitions/:id', { id })
export const updateCompetition = (id: string, data: UpdateCompetitionData) =>
  PATCH('/competitions/:id', { ...data, id })
export const createCompetition = (data: NewCompetitionData) => POST('/competitions', { ...data })
export const deleteCompetition = (id: string) => DELETE('/competitions/:id', { id })
export const sealCompetition = (id: string) => PATCH('/competitions/:id/seal', { id })
export const updateCompetitionBestScores = (id: string) => PATCH('/competitions/:id/update_best_scores', { id })
export const rescoreLeaderboard = (id: string) => PATCH('/competitions/:id/rescore_leaderboard', { id })
export const publishRescoringResults = (id: string) => PATCH('/competitions/:id/publish_rescoring_results', { id })

export const getSubmissions = (query: SubmissionsQuery) => GET('/competitions/:competitionId/submissions', query)
export const updateSubmission = (id: string, data: UpdateSubmissionData) => PATCH('/submissions/:id', { ...data, id })

export const getParticipations = (query: ParticipationsQuery) =>
  GET('/competitions/:competitionId/participations', query)
export const updateParticipation = (id: string, data: UpdateParticipationData) =>
  PATCH('/participations/:id', { ...data, id })

export const createPage = (id: string, data: CreatePageData) => POST('/competitions/:id/pages', { ...data, id })
export const updatePage = (competitionId: string, id: string, data: UpdatePageData) =>
  PATCH('/competitions/:competitionId/pages/:id', { ...data, competitionId, id })
export const deletePage = (competitionId: string, id: string) =>
  DELETE('/competitions/:competitionId/pages/:id', { competitionId, id })
export const reorderPages = (competitionId: string, pageIds: string[]) =>
  POST('/competitions/:competitionId/pages/reorder', { competitionId, page_ids: pageIds })

export const createDatafile = (id: string, data: CreateDatafileData) =>
  POST('/competitions/:id/datafiles', { ...data, id })
export const updateDatafile = (competitionId: string, id: string, data: UpdateDatafileData) =>
  PATCH('/competitions/:competitionId/datafiles/:id', { ...data, competitionId, id })
export const deleteDatafile = (competitionId: string, id: string) =>
  DELETE('/competitions/:competitionId/datafiles/:id', { competitionId, id })

export const uploadImageAttachment = (parentType: string, parentId: string, image: File) =>
  POST('/image_attachments', { parent_type: parentType, parent_id: parentId, image })

export const getBlogPosts = (query: BlogPostQuery) => GET('/blog_posts', query)
export const getBlogPost = (id: string) => GET('/blog_posts/:id', { id })
export const updateBlogPost = (id: string, data: UpdateBlogPostData) => PATCH('/blog_posts/:id', { ...data, id })
export const createBlogPost = (data: CreateBlogPostData) => POST('/blog_posts', { ...data })
export const deleteBlogPost = (id: string) => DELETE('/blog_posts/:id', { id })
export const autosuggest = (type: string, query?: string) =>
  GET('/autosuggest/:type', { type, query }, ({ data }) => data)

export const getJobs   = (query: JobQuery) => GET('/jobs', query)
export const getJob    = (id: string) => GET('/jobs/:id', { id })
export const createJob = (data: NewJobData) => POST('/jobs', { ...data })
export const updateJob = (id: string, data: UpdateJobData) => PATCH('/jobs/:id', { ...data, id })
export const deleteJob = (id: string) => DELETE('/jobs/:id', { id })

export const getCountries = () => GET('/countries', ({ data }) => data)

export const getJobFunnels = (query: JobFunnelsQuery) => GET('/jobs/:jobId/funnels', { ...query })
export const getJobFunnel = ({ jobId, id }: { jobId: string, id: string }) =>
  GET('/jobs/:jobId/funnels/:id', { jobId, id })
export const updateJobFunnel = (jobId: string, id: string, data: JobFunnelUpdateData) =>
  PATCH('/jobs/:jobId/funnels/:id', { jobId, id, ...data })

export const getCareerQuestinnaireQuestions = () =>
  PUBLIC_API.GET('/career_questionnaire/questions', {}, ({ data }) => data)

export const getDiscussions = (query: DiscussionsQuery) => GET('/discussions', { ...query })
export const getDiscussion = (id: string) => GET('/discussions/:id', { id })
export const updateDiscussion = (id: string, data: DiscussionUpdateData) => PATCH('/discussions/:id', { id, ...data })

export const hideComment = (id: string) => DELETE('/comments/:id', { id })

export const getReports = (query: ReportsQuery) => GET('/reports', { ...query })
export const updateReport = (id: string, data: ReportUpdateData) => PATCH('/reports/:id', { id, ...data })
