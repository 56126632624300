// @flow
/* eslint-disable import/first */
/* eslint-disable import/newline-after-import */
require("isomorphic-fetch")
import * as React from "react"
import * as ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/es/integration/react"
import { BrowserRouter as Router } from "react-router-dom"
import { type ReduxStore } from "./store/types"
import { createPreloads, Provider as PreloadsProvider } from "./preloads"
/* eslint-enable import/newline-after-import */
/* eslint-enable import/first */

export default ({
  createStore,
  initialState,
}: {
  createStore: ({ initialState: {} }) => { store: ReduxStore, persistor: any },
  initialState: {},
}) => {
  const { store, persistor } = createStore({ initialState })
  const preloads = createPreloads()
  window.store = store

  let renderApp = (Component) => (
    <PreloadsProvider value={preloads}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <Component />
          </Router>
        </PersistGate>
      </Provider>
    </PreloadsProvider>
  )

  if (process.env.HOT) {
    const { AppContainer } = require("react-hot-loader") // eslint-disable-line global-require
    const _renderApp = renderApp

    renderApp = (Component) => {
      return <AppContainer>{_renderApp(Component)}</AppContainer>
    }
  }

  const render = (Component: React.ComponentType<*>) => {
    const node = document.getElementById("app")
    if (!node) throw new Error("Cant' find #app to render React")

    window.__ZINDI_REACT_ROOT_NODE__ = node

    ReactDOM.render(renderApp(Component), node)
  }

  return render
}
