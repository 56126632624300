// @flow
import * as React from "react"
import Button from "components/Button"

export default class SignOut extends React.PureComponent<
  {
    signOut: () => Promise<any>,
    className?: string,
  },
  { signingOut: boolean }
> {
  state = {
    signingOut: false,
  }

  onSignoutError = () => this.setState(() => ({ signingOut: false }))

  signOut = () => {
    this.setState(() => ({ signingOut: true }))

    this.props.signOut().catch(this.onSignoutError)
  }

  render() {
    const { className } = this.props
    const { signingOut } = this.state

    return (
      <Button
        className={className}
        kind="blank"
        onClick={this.signOut}
        disabled={signingOut}
        loading={signingOut}
        spinnerColor="black"
      >
        Sign out
      </Button>
    )
  }
}
