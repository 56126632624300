// @flow
import { createAdmin } from "store/reducers/admins"
import { reduxForm } from "redux-form/immutable"
import NewAdminForm from "views/NewAdminForm"

import { compose } from "recompose"
import validate from "shared/utils/validate"
import handleFormErrors from "shared/utils/handleFormErrors"

export default compose(
  reduxForm({
    form: "newAdmin",
    validate: validate({
      email: ["required", "email"],
    }),
    onSubmit: (values, dispatch) => dispatch(createAdmin({ email: values.get("email") })).catch(handleFormErrors),
    onSubmitSuccess: (_result, _dispatch, props) => props.history.push("/admins"),
  })
)(NewAdminForm)
