// @flow
import { EditorState, convertToRaw, convertFromRaw } from "draft-js"
import type { RawDraftContentState } from "draft-js/lib/RawDraftContentState"

export { default } from "./Base"
export { default as configure } from "./configure"

/*
 * TODO
 * draft-js-plugins is a pile of crap and should be replaced
 */

export const serialize = (editorState: EditorState): RawDraftContentState =>
  convertToRaw(editorState.getCurrentContent())

export const parse = (raw: ?RawDraftContentState): EditorState => {
  if (raw) {
    try {
      return EditorState.createWithContent(convertFromRaw(raw))
    } catch (e) {
      /* return blank if can't be parsed */
    }
  }

  return EditorState.createEmpty()
}
