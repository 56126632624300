// @flow
import * as React from "react"
import c from "classnames"

import type { Column } from "./index"
import s from "./index.scss"

export default class Cell<T: {}> extends React.PureComponent<{
  column: Column<T, *>,
  children: React.Node,
  className?: string,
}> {
  render() {
    const {
      column: { width, flexible, align },
      className,
      children,
    } = this.props

    return (
      <div
        className={c(s.cell, !!flexible && s.flexibleCell, align && s[`align-${align}`], className)}
        style={{ width }}
      >
        {children}
      </div>
    )
  }
}
