// @flow
import { getSubmissions, submissionsSelector, updateSubmission } from "store/reducers/submissions"
import { connect } from "react-redux"
import Submissions from "views/Competition/Submissions"
import withPagination from "shared/decorators/withPagination"
import withSorting from "shared/decorators/withSorting"
import withSearch from "shared/decorators/withSearch"

import { compose, withPropsOnChange } from "recompose"
import preload from "decorators/preload"

export default compose(
  withPagination({ per_page: 50 }),
  withSorting({ default: "date" }),
  withSearch,
  withPropsOnChange(
    ["competitionId", "page", "per_page", "sortBy", "sortReversed", "search"],
    ({ competitionId, page, per_page, sortBy, sortReversed, search }) => ({
      query: {
        competitionId,
        page,
        per_page,
        search,
        sort_by: sortBy,
        sort_reversed: sortReversed,
      },
    })
  ),
  connect(
    (state, props) => ({
      submissions: submissionsSelector(state, props.query),
    }),
    (dispatch, props) => ({
      getSubmissions: () => dispatch(getSubmissions(props.query)),
      setBanned: (id: string, banned: boolean) => dispatch(updateSubmission(id, { banned })),
    })
  ),
  preload({
    submissions: {
      load: "getSubmissions",
      optional: true,
      replaceWithData: false,
    },
  }),
  withPropsOnChange(["submissions"], ({ submissions }) => ({
    submissions: submissions.data,
    pages: submissions.pages,
    submissionsLoading: submissions.loading,
  }))
)(Submissions)
