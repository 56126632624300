// @flow
import * as React from "react"
import c from "classnames"
import s from "./Hr.scss"

type Props = {
  className?: string,
}

export default ({ className }: Props) => <hr className={c(s.hr, className)} />
