// @flow
import * as React from "react"
import { Map } from "immutable"

import Async, { type Props as AsyncProps } from "./Async"

import s from "./Select.scss"

type State = {
  label: ?string,
}

type Value = { value: string, id: string }
type ImmutableValue = Map<Value>

type Props = {
  ...$Exact<AsyncProps>,
  value: ?ImmutableValue,
  onChange: (?Value) => any,
  onBlur: (any) => any,
  onSuggestionSelected: (any, { suggestion: Value }) => any,
}

export default class SelectAutosuggest extends React.Component<Props, State> {
  state = {
    label: this.props.value && this.props.value.get("value"),
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        label: nextProps.value && nextProps.value.get("value"),
      })
    }
  }

  onSuggestionSelected = (_event: any, { suggestion }: { suggestion: Value }) => {
    this.props.onChange(new Map(suggestion))
  }

  onChange = (label: ?string) => {
    this.setState({ label })
  }

  onBlur = () => {
    const { label } = this.state
    const value = this.props.value && this.props.value.get("value")

    if (!value && label) {
      this.setState({ label: "" })
    } else if (value && !label) {
      this.props.onChange(null)
    } else if (value && label && label !== value) {
      this.setState({ label: value })
    }
  }

  onKeyPress = (e: KeyboardEvent) => {
    if (e.charCode === 13) {
      this.onBlur()
    }
  }

  render() {
    const { label } = this.state
    const { value, onChange, onBlur, onSuggestionSelected, ...props } = this.props

    return (
      <Async
        {...props}
        value={label}
        onSuggestionSelected={this.onSuggestionSelected}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onKeyPress={this.onKeyPress}
        className={s.input}
      />
    )
  }
}
