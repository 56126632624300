// @flow
import { SubmissionError } from "redux-form/immutable"
import type { ErrorResponse } from "../api/types"

export default (error: ErrorResponse) => {
  if (error) {
    throw new SubmissionError({
      ...error,
      _error: error.message || error.base,
    })
  } else {
    throw error
  }
}
