// @flow
import * as React from "react"
import c from "classnames"
import s from "./Text.scss"

export type Props = {
  value?: string,
  type?: string,
  disabled?: boolean,
  invalid?: boolean,
  autoFocus?: boolean,
  className?: string,
  style?: { [string]: ?(number | string) },
  nobox: boolean,
  nomargin?: boolean,
  size: "small" | "normal",
  noborder?: boolean,
  component: React.ComponentType<*> | string,
}

export default class TextInput extends React.PureComponent<Props> {
  input: ?any

  static defaultProps = {
    type: "text",
    nobox: false,
    size: "normal",
    component: "input",
  }

  componentDidMount() {
    if (this.props.autoFocus && this.input && this.input.focus) {
      this.input.focus()
    }
  }

  render() {
    const { invalid, className, nobox, size, nomargin, noborder, component: Component, ...otherProps } = this.props

    return (
      <Component
        ref={(ref) => {
          this.input = ref
        }}
        {...otherProps}
        className={c(
          nobox ? s.nobox : s.box,
          s.text,
          s[`size-${size}`],
          s[`box-size-${size}`],
          invalid && s.invalid,
          noborder && s.noborder,
          className
        )}
      />
    )
  }
}
