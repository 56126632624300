// @flow
import * as React from 'react'
import c from 'classnames'
import s from './typography.scss'

export const H2 = ({ className, children, ...props }: { className?: string, children?: React.Node }) => (
  <h2 className={c(s.h2, className)} {...props}>
    {children}
  </h2>
)
