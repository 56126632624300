// @flow
import * as React from "react"
import MaskedInput from "react-text-mask"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"

import Text from "./Text"

const DATE_MASK = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]

type DateToFormat = number | Date

export const formatDate = (date: ?DateToFormat) => {
  if (!date) return ""

  /* eslint-disable no-param-reassign */
  if (typeof date === "number") {
    date = new Date(date * 1000)
  }

  if (typeof date === "string") {
    date = parseISO(date)
  }
  /* eslint-enable no-param-reassign */

  return format(date, "dd/MM/yyyy")
}

export default (props: {}) => <Text placeholder="__/__/____" {...props} component={MaskedInput} mask={DATE_MASK} />
