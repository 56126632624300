// @flow
import * as React from "react"
import c from "classnames"
import { pick } from "lodash"

import Base, { configure } from "shared/RichEditor"
import wordStyling from "shared/RichEditor/includes/wordStyling"
import headers from "shared/RichEditor/includes/headers"
import links from "shared/RichEditor/includes/links"
import quotes from "shared/RichEditor/includes/quotes"
import code from "shared/RichEditor/includes/code"
import images from "shared/RichEditor/includes/images"
import videos from "shared/RichEditor/includes/videos"
import inlineToolbar from "shared/RichEditor/includes/inlineToolbar"
import newBlockToolbar from "shared/RichEditor/includes/newBlockToolbar"
import misc from "shared/RichEditor/includes/misc"
import mentions from "shared/RichEditor/includes/mentions"

import Svg from "components/Svg"
import imageIcon from "../../../assets/feather/image.svg"
import videoIcon from "../../../assets/feather/video.svg"
import s from "./index.scss"

import inlineToolbarStyles from "./inlineToolbar.scss"
import newBlockToolbarStyles from "./newBlockToolbar.scss"

export { serialize, parse } from "shared/RichEditor"

const Input = configure((props) => ({
  include: [
    wordStyling(),
    headers(),
    links({
      styles: { link: s.link },
    }),
    quotes(),
    code(),
    images({
      button: <Svg icon={imageIcon} />,
      styles: pick(s, "image", "focused", "unfocused"),
      upload: props.uploadImageAttachment,
    }),
    videos({
      button: <Svg icon={videoIcon} />,
      getVideoUrl: () =>
        new Promise((resolve) => {
          const url = global.prompt("Enter video url")

          resolve(url)
        }),
    }),
    inlineToolbar({
      styles: inlineToolbarStyles,
    }),
    newBlockToolbar({
      styles: newBlockToolbarStyles,
    }),
    misc(),
    mentions({
      className: s.mention,
      disableIn: ["atomic", "code-block"],
    }),
  ],
}))(Base)

export default class RichEditor extends React.Component<{
  nobox?: boolean,
}> {
  render() {
    const { nobox, ...props } = this.props

    return (
      <div className={c(s.container, nobox && s.nobox)}>
        <Input {...props} />
      </div>
    )
  }
}
