// @flow
import { type ParticipationData } from 'store/reducers/participations'
import type { FullCompetitionData } from 'store/reducers/fullCompetitions'
import { Link, type LocationShape } from 'react-router-dom'
import { isNil } from 'lodash'
import * as React from 'react'
import Helmet from 'react-helmet'
import c from 'classnames'
import memoize from 'shared/utils/memoize'

import Button from 'components/Button'
import Panel from 'components/Panel'
import SearchInput from 'components/SearchInput'
import DataTable, { bindSortableHeaderToProps, renderers } from 'components/DataTable'
import Pagination from 'components/Pagination'

import TextInput from 'components/inputs/Text'
import Autosaving from 'components/inputs/Autosaving'
import s from './Leaderboard.scss'

const FRONTEND_URL = process.env.FRONTEND_URL || ''

type Props = {
  competition: FullCompetitionData,
  competitionId: number,
  participations: ?(ParticipationData[]),
  participationsLoading: boolean,
  page: number,
  pages: ?number,
  getPageLink: (number) => LocationShape,
  sortBy: string,
  sortReversed: string,
  getSortLink: (string, ?boolean) => LocationShape,
  search?: string,
  setSearch: (string) => any,
  children?: React.Node,
  setBanned: (string, boolean) => Promise<*>,
  updateComment: (string, ?string) => Promise<*>,
  updatePublicScoreManualOverride: (string, ?number) => Promise<*>,
  updatePrivateScoreManualOverride: (string, ?number) => Promise<*>,
}

type ExtraProps = {
  competitionId: number,
  setBanned: (number, boolean) => Promise<*>,
}

function renderSubmissionScoreElement(score, submissionId, competitionId, overriden = false) {
  if (!score) return null

  let element = <span title={score}>{score}</span>
  if (submissionId) {
    element = (
      <Link
        to={`/competitions/${competitionId}/submissions?query=${submissionId}`}
        target="_blank"
        rel="noopener noreferrer"
        className={overriden ? s.overridenScore : null}
      >
        {element}
      </Link>
    )
  }

  return element
}

class Buttons extends React.PureComponent<{
  row: ParticipationData,
  extraProps: ExtraProps,
}> {
  render() {
    const {
      row: { banned },
    } = this.props

    return (
      <React.Fragment>
        <Button size="small" kind={banned ? 'primary' : 'danger'} onClick={this.onClick} outline promised>
          {banned ? 'Restore' : 'Disqualify'}
        </Button>
      </React.Fragment>
    )
  }

  onClick = () => {
    const { row } = this.props
    const newBanned = !row.banned

    return this.props.extraProps.setBanned(row.id, newBanned)
  }
}

class EditableInput extends React.PureComponent<{
  value: ?string,
  id: string,
  right: boolean,
  save: (string, string) => Promise<any>,
}> {
  save = (value: string) => this.props.save(this.props.id, value)

  render() {
    return (
      <Autosaving
        value={this.props.value || ''}
        save={this.save}
        render={(props) => <TextInput {...props} />}
        saveOnChange={false}
        saveOnBlur
        saveOnEnter
        className={c(s.editableInput, this.props.right && s.editableInputRight)}
      />
    )
  }
}

const COLUMNS = [
  {
    key: 'id',
    width: 50,
  },
  {
    key: 'private_rank',
    label: 'private',
    width: 50,
  },
  {
    key: 'public_rank',
    label: 'public',
    width: 50,
  },
  {
    key: 'username',
    label: 'user',
    width: 200,
    flexible: true,
    sortable: false,
    render: (username, row, extraProps) => {
      if (row.participant_type === 'User') {
        return (
          <a href={`${FRONTEND_URL}/users/${username}`} target="_blank" rel="noopener noreferrer">
            {row.user.username}
          </a>
        )
      }

      if (row.participant_type === 'Team') {
        return (
          <a
            href={[
              `${FRONTEND_URL}`,
              `competitions`,
              `${extraProps.competitionUrlTitle}`,
              'leaderboard/teams',
              `${row.team.url_title}`,
            ].join('/')}
            target="_blank"
            rel="noopener noreferrer"
          >
            TEAM {row.team.title}
          </a>
        )
      }

      return username
    },
  },
  {
    key: 'best_public_score',
    label: 'public score',
    width: 100,
    render: (public_score, row, extraProps) =>
      renderSubmissionScoreElement(
        public_score,
        row.best_public_submission_id,
        extraProps.competitionKind,
        extraProps.competitionId,
        !isNil(row.best_public_score_manual_override),
      ),
  },
  {
    key: 'best_private_score',
    label: 'private score',
    width: 100,
    render: (private_score, row, extraProps) =>
      renderSubmissionScoreElement(
        private_score,
        row.best_private_submission_id,
        extraProps.competitionKind,
        extraProps.competitionId,
        !isNil(row.best_private_score_manual_override),
      ),
  },
  {
    key: 'successful_submission_count',
    label: (
      <span>
        successful <br />
        submissions
      </span>
    ),
    width: 100,
    align: 'right',
    render: (successful_submission_count) => (
      <span title={successful_submission_count}>{successful_submission_count}</span>
    ),
  },
  {
    key: 'created_at',
    label: 'joined',
    render: renderers.date,
    width: 150,
  },
  {
    key: 'admin_comment',
    label: 'comment',
    width: 150,
    render: (comment, row, extraProps) => <EditableInput id={row.id} value={comment} save={extraProps.updateComment} />,
  },
  {
    key: 'best_public_score_manual_override',
    label: (
      <span>
        public <br />
        score override
      </span>
    ),
    width: 150,
    align: 'right',
    render: (value, row, extraProps) => (
      <EditableInput id={row.id} value={value} right save={extraProps.updatePublicScoreManualOverride} />
    ),
  },
  {
    key: 'best_private_score_manual_override',
    label: (
      <span>
        private <br />
        score override
      </span>
    ),
    width: 150,
    align: 'right',
    render: (value, row, extraProps) => (
      <EditableInput id={row.id} value={value} right save={extraProps.updatePrivateScoreManualOverride} />
    ),
  },
  {
    key: 'banned',
    label: 'disqualified',
    width: 150,
    render: (_, row, extraProps) => <Buttons row={row} extraProps={extraProps} />,
  },
]

export default class Participations extends React.PureComponent<Props> {
  cacheExtraProps = memoize(1)((props) => props)

  render() {
    const {
      participations,
      participationsLoading,
      page,
      pages,
      getPageLink,
      sortBy,
      sortReversed,
      getSortLink,
      search,
      setSearch,
      children,
      setBanned,
      competitionId,
      competitionKind,
      competition,
      updateComment,
      updatePublicScoreManualOverride,
      updatePrivateScoreManualOverride,
    } = this.props

    const SortableHeader = bindSortableHeaderToProps({
      sortBy,
      sortReversed,
      getSortLink,
    })

    const extraProps: ExtraProps = this.cacheExtraProps({
      setBanned,
      competitionId,
      competitionKind,
      competitionUrlTitle: competition.url_title,
      updateComment,
      updatePublicScoreManualOverride,
      updatePrivateScoreManualOverride,
    })

    return (
      <div>
        <Helmet title="Competitions" />
        <Panel nopadding>
          <SearchInput value={search} setValue={setSearch} />
        </Panel>
        <Panel nopadding>
          <DataTable
            keyColumn="id"
            data={participations}
            columns={COLUMNS}
            headerComponent={SortableHeader}
            loading={participationsLoading}
            extraProps={extraProps}
            bodyClassName={s.body}
          />
        </Panel>
        <Pagination page={page} pages={pages} getPageLink={getPageLink} />
        {children}
      </div>
    )
  }
}
