// @flow
import * as React from "react"
import { debounce } from "lodash"
import Svg from "components/Svg"
import TextField from "components/inputs/Text"

import searchIcon from "../assets/feather/search.svg"

import s from "./SearchInput.scss"

type Props = {
  value?: string,
  setValue: (string) => any,
  disabled?: boolean,
}

type State = {
  value: string,
}

export default class SearchInput extends React.PureComponent<Props, State> {
  input: ?HTMLInputElement

  state = {
    value: this.props.value || "",
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value || "" })
    }
  }

  onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget

    this.setState({ value })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.value !== this.state.value && this.state.value !== prevProps.value) {
      this.setValue(this.state.value)
    }
  }

  setValue = debounce((value) => {
    this.props.setValue(value)
  }, 300)

  render() {
    const { setValue, value, ...otherProps } = this.props

    return (
      <label className={s.container}>
        <Svg icon={searchIcon} className={s.icon} />
        <TextField {...otherProps} className={s.input} onChange={this.onChange} value={this.state.value} />
      </label>
    )
  }
}
