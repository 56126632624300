// @flow
import * as React from "react"
import { NavLink } from "react-router-dom"
import { map, compact } from "lodash"
import c from "classnames"

import type { Kind } from "components/Tabs"
import Button from "components/Button"

import s from "./Tabs.scss"

export type Item = {
  to: string,
  activeClassName?: string,
  label: React.Node,
  right?: boolean,
  invisible?: boolean,
}

export const RouterTabs = ({
  className,
  urlPrefix: globalUrlPrefix,
  items,
  kind = "subheader",
}: {
  kind: Kind,
  className?: string,
  urlPrefix: string,
  items: Item[],
}) => (
  <div className={c(s.tabs, s[`kind-${kind}`], className)}>
    <div className={s.tabList}>
      {map(items, ({ to, key, label, invisible, right, exact, button, urlPrefix: itemUrlPrefix, ...props }) => {
        const tabProps = {
          key: key || to,
          className: c(s.tab, right && s.tabRight, button && s.tabButton, invisible && s.tabInvisible, className),
          ...props,
        }
        let children = label
        const urlPrefix = itemUrlPrefix === undefined ? globalUrlPrefix : itemUrlPrefix

        if (button) {
          children = (
            <Button kind="secondary" size="small" className={s.tabButtonInner}>
              {children}
            </Button>
          )
        }

        if (typeof to !== "undefined") {
          return (
            <NavLink {...tabProps} exact={exact} activeClassName={s.selectedTab} to={compact([urlPrefix, to]).join("")}>
              {children}
            </NavLink>
          )
        }

        return (
          <span {...tabProps} to={compact([urlPrefix, to]).join("")}>
            {children}
          </span>
        )
      })}
    </div>
  </div>
)
