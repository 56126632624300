// @flow
import * as React from "react"
import c from "classnames"
import s from "./Shiny.scss"

type Props = {
  children: React.Node,
  className?: string,
  shine: ?boolean,
}

type State = {
  shining: boolean,
}

export default class Shiny extends React.Component<Props, State> {
  state = { shining: false }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.shine !== this.props.shine && nextProps.shine) {
      this.shine()
    }
  }

  shine() {
    this.setState(
      () => ({ shining: false }),
      () => setTimeout(() => this.setState(() => ({ shining: true })), 10)
    )
  }

  render() {
    const { shine, children, className, ...props } = this.props
    const { shining } = this.state

    return (
      <div {...props} className={c(s.container, className)}>
        {children}
        <div className={c(s.shine, shining && s.shining)} />
      </div>
    )
  }
}
