// @flow
import * as React from "react"
import createVideoPlugin from "draft-js-video-plugin"
import Configuration from "../configuration"
import { buttonWithProps } from "../plugins/newBlockToolbar/components/Button"

import defaultStyles from "../styles/video.scss"
import mergeStyles from "../utils/mergeStyles"

const validateVideoUrl = (url: string) => {
  if (!url) {
    throw new Error("Invalid video url")
  }

  return url
}

type Config = {
  button: React.Node,
  getVideoUrl: () => Promise<string>,
  styles?: {},
}

export default (config: Config) => ({ addPlugin, addNewBlockButton }: Configuration) => {
  const styles = mergeStyles(defaultStyles, config.styles)

  const videoPlugin = createVideoPlugin({
    theme: styles,
  })

  const onClick = ({ getEditorState, setEditorState }) =>
    config
      .getVideoUrl()
      .then(validateVideoUrl)
      .then((url) => {
        let editorState = getEditorState()
        editorState = videoPlugin.addVideo(editorState, { src: url })
        setEditorState(editorState)
      })
      .catch(() => {
        global.alert("Invalid video url")
      })

  addPlugin(videoPlugin)
  addNewBlockButton(
    buttonWithProps({
      children: config.button,
      onClick,
    })
  )
}
