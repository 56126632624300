// @flow
import * as React from "react"
import { Link } from "react-router-dom"
import { Field } from "redux-form/immutable"
import Helmet from "react-helmet"

import Button from "components/Button"
import { TextField } from "components/fields"

type Props = {
  handleSubmit: (any) => any,
  submitting: boolean,
  invalid: boolean,
}

export default class PasswordRequestForm extends React.Component<Props> {
  render() {
    const { invalid, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Helmet title="Forgot password?" />
        <h1>Forgot password?</h1>
        <Field label="Email" name="email" component={TextField} autoFocus />
        <p>
          <Link to="/auth/signin">Sign In</Link>
        </p>
        <Button type="submit" disabled={submitting || invalid} loading={submitting}>
          Send a reset link
        </Button>
      </form>
    )
  }
}
