// @flow
import { handleActions } from "redux-actions"
import * as API from "api"
import { map, each } from "lodash"
import { createSelector } from "reselect"

import { GET_ADMINS, UPDATE_ADMIN, CREATE_ADMIN } from "store/actionTypes"

import type { ApiError, Action, ApiResponse } from "shared/store/types"

export type AdminData = {
  +id: number,
  +email: string,
  +created_at: number,
  +banned: boolean,
}

export type NewAdminData = {
  +email: string,
}

export type UpdateAdminData = {
  +banned: boolean,
}

type State = {
  admins: {
    [string]: AdminData,
  },
  queries: {
    [string]: {
      +data: ?(number[]),
      +loading: boolean,
      +error: ApiError | null,
    },
  },
  totalCount?: number,
}

export type Query = {
  page: number,
  per_page: number,
  sort_by: string,
  sort_reversed: boolean,
  search: ?string,
}

type ApiResult = ApiResponse<AdminData[], { total_count: number }, { query: Query }>

export const defaultPageData = {
  data: undefined,
  loading: false,
  error: null,
}

const initialState: State = {
  admins: {},
  queries: {},
  totalCount: undefined,
}

const normalize = (admins: AdminData[]): { ids: string[], data: { [string]: AdminData } } => {
  const ids = []
  const data = {}

  each(admins, (admin) => {
    ids.push(admin.email)
    data[admin.email] = admin
  })

  return { ids, data }
}

const queryHash = ({ page, per_page, sort_by, sort_reversed, search }: Query) =>
  [page, per_page, sort_by, sort_reversed.toString(), search].join("%!%")

export default handleActions(
  {
    [GET_ADMINS.START]: (state: State, { payload: { query } }): State => ({
      ...state,
      queries: {
        ...state.queries,
        [queryHash(query)]: {
          ...defaultPageData,
          loading: true,
        },
      },
    }),
    [GET_ADMINS.FAILURE]: (state: State, { payload: { query, error } }): State => ({
      ...state,
      queries: {
        ...state.queries,
        [queryHash(query)]: {
          loading: false,
          error,
        },
      },
    }),
    [GET_ADMINS.SUCCESS]: (
      state: State,
      {
        payload: {
          query,
          result: {
            data: admins,
            meta: { total_count },
          },
        },
      }: ApiResult
    ): State => {
      const { ids, data } = normalize(admins)

      return {
        admins: {
          ...state.admins,
          ...data,
        },
        queries: {
          ...state.queries,
          [queryHash(query)]: {
            data: ids,
            loading: false,
          },
        },
        totalCount: total_count,
      }
    },

    [CREATE_ADMIN.SUCCESS]: (
      state: State,
      {
        payload: {
          result: { data },
        },
      }: { payload: { result: { data: AdminData } } }
    ): State => ({
      admins: {
        [data.email]: data,
      },
      queries: {},
      totalCount: state.totalCount + 1,
    }),

    [UPDATE_ADMIN.SUCCESS]: (
      state: State,
      {
        payload: {
          result: { data },
        },
      }: { payload: { result: { data: AdminData } } }
    ): State => ({
      ...state,
      admins: {
        ...state.admins,
        [data.email]: data,
      },
    }),
  },
  initialState
)

export const getAdmins = (query: Query): Action => ({
  types: GET_ADMINS,
  api: API.getAdmins(query),
  payload: { query },
})

export const createAdmin = (data: NewAdminData): Action => ({
  types: CREATE_ADMIN,
  api: API.createAdmin(data),
  payload: { data },
})

export const updateAdmin = (id: number, data: UpdateAdminData): Action => ({
  types: UPDATE_ADMIN,
  api: API.updateAdmin(id, data),
  payload: { id, data },
})

export const querySelector = createSelector(
  [
    (state) => state.admins,
    (_, { page, per_page, sortBy, sortReversed, search }) => ({
      page,
      per_page,
      sort_by: sortBy,
      sort_reversed: sortReversed,
      search,
    }),
  ],
  ({ admins, queries, totalCount }, query) => {
    const hash = queryHash(query)
    let pageData = queries[hash] || defaultPageData

    if (pageData.data) {
      pageData = {
        ...pageData,
        data: map(pageData.data, (id) => admins[id]),
      }
    }

    return {
      pages: totalCount === undefined ? undefined : Math.ceil(totalCount / query.per_page),
      admins: pageData,
    }
  }
)
