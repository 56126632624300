// @flow
import * as React from "react"
import { compact, map } from "lodash"
// TODO
// ↓ until https://github.com/draft-js-plugins/draft-js-plugins/pull/1209 is merged
import createInlineToolbarPlugin from "../plugins/draft-js-inline-toolbar-plugin"
import Configuration from "../configuration"

import defaultStyles from "../styles/inlineToolbar.scss"
import mergeStyles from "../utils/mergeStyles"

type Config = {
  styles?: {},
}

export default (config?: Config = {}) => ({
  addPlugin,
  addComponent,
  inlineTextButtons,
  inlineBlockButtons,
}: Configuration) => {
  const styles = mergeStyles(defaultStyles, config.styles)
  const Separator = () => <div className={styles.separator} />

  const inlineButtons = compact([
    ...inlineTextButtons,
    inlineTextButtons.length && inlineBlockButtons.length ? Separator : null,
    ...inlineBlockButtons,
  ])

  const children = (externalProps) => (
    <React.Fragment>
      {map(inlineButtons, (Component, index) => (
        <Component key={index} {...externalProps} />
      ))}
    </React.Fragment>
  )

  const inlineToolbar = createInlineToolbarPlugin({
    theme: {
      buttonStyles: styles,
      toolbarStyles: styles,
    },
    structure: inlineButtons,
  })
  const Toolbar = (props) => <inlineToolbar.InlineToolbar {...props}>{children}</inlineToolbar.InlineToolbar>
  addPlugin(inlineToolbar)
  addComponent(Toolbar)
}
