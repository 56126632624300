// @flow
import * as React from "react"
import Helmet from "react-helmet"
import c from "classnames"

import s from "./GuestLayout.scss"

require("../global.scss")

type Props = {|
  children: React.Node,
|}

export default class GuestLayout extends React.PureComponent<Props> {
  render() {
    const { children } = this.props

    return (
      <div className={s.container}>
        <Helmet defaultTitle="Zindi Admin" titleTemplate="%s — Zindi Admin" />

        <div className={c(s.contained, s.content)}>{children}</div>
      </div>
    )
  }
}
