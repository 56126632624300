// @flow
import * as React from "react"
import { map } from "lodash"
import type { Column, Columns } from "components/DataTable"
import c from "classnames"

import { CONTENT_ID } from "views/Layout"
import Sticky from "shared/components/Sticky"
import HeaderCell from "./HeaderCell"
import Row from "./Row"

import s from "./index.scss"

export type Props<T, E> = {
  data: T[],
  columns: Columns<T, E>,
  keyColumn: string,
  onHeaderClick?: (Column<T, E>) => any,
  headerComponent?: React.ComponentType<*>,
  extraProps: E,
  renderHeader: boolean,
  bodyClassName?: string,
}
export default class DataTable<T: {}, E: {}> extends React.PureComponent<Props<T, E>> {
  static defaultProps = {
    extraProps: {},
    renderHeader: true,
  }

  render() {
    const {
      data,
      keyColumn,
      columns,
      headerComponent,
      onHeaderClick,
      extraProps,
      renderHeader,
      bodyClassName,
    } = this.props

    return (
      <div className={s.table}>
        {renderHeader && (
          <Sticky containerId={CONTENT_ID}>
            <div className={c(s.row, s.headerRow)}>
              {map(columns, (column: Column<T, E>) => (
                <HeaderCell column={column} component={headerComponent} onClick={onHeaderClick} key={column.key} />
              ))}
            </div>
          </Sticky>
        )}
        <Body data={data} columns={columns} keyColumn={keyColumn} extraProps={extraProps} className={bodyClassName} />
      </div>
    )
  }
}

type BodyProps<T, E> = {
  data: T[],
  columns: Columns<T, E>,
  keyColumn: string,
  extraProps: E,
  className?: string,
}
class Body<T: {}, E: {}> extends React.PureComponent<BodyProps<T, E>> {
  render() {
    const { data, columns, keyColumn, extraProps, className } = this.props

    return (
      <div className={c(s.body, className)}>
        {map(data, (row) => (
          <Row key={row[keyColumn]} data={row} columns={columns} extraProps={extraProps} />
        ))}
      </div>
    )
  }
}
