// @flow
import * as React from "react"
import { Field } from "redux-form/immutable"
import Helmet from "react-helmet"

import Panel from "components/Panel"
import Button from "components/Button"
import { TextField } from "components/fields"

type Props = {
  handleSubmit: (any) => any,
  submitting: boolean,
  invalid: boolean,
}

export default class NewAdminForm extends React.Component<Props> {
  render() {
    const { invalid, handleSubmit, submitting } = this.props

    return (
      <Panel nopadding title="New Admin" width="medium">
        <form onSubmit={handleSubmit}>
          <Helmet title="New Admin" />
          <Field label="email" name="email" component={TextField} autoFocus />
          <Button type="submit" disabled={submitting || invalid} loading={submitting}>
            Create
          </Button>
        </form>
      </Panel>
    )
  }
}
