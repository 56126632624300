// @flow
import * as React from "react"
import { pull } from "lodash"

export type { Config } from "./decorator"
export { default as decorator } from "./decorator"

type Args = {
  onError: (?Error) => any,
}

const noop = (promise: Promise<any>) => {}

export const { Consumer, Provider } = React.createContext({ track: noop })
export const createPreloads = ({ onError }: Args = {}) => {
  const promises = []

  const createRemovePromise = (promise) => () => {
    pull(promises, promise)
  }
  const createRemovePromiseAndRethrow = (promise) => (e) => {
    pull(promises, promise)

    if (onError) {
      return onError(e)
    }
  }

  const track = (promise: Promise<*>) => {
    promise.then(createRemovePromise(promise), createRemovePromiseAndRethrow(promise))

    promises.push(promise)
  }

  const wait = (): Promise<*> => Promise.all(promises)
  const isPending = (): boolean => !!promises.length

  return {
    track,
    wait,
    isPending,
  }
}
