// @flow
import * as React from "react"
import { Field } from "redux-form/immutable"
import Helmet from "react-helmet"

import Panel from "components/Panel"
import Button from "components/Button"
import { TextField } from "components/fields"

export default class OTPForm extends React.Component<Props> {
  render() {
    const { invalid, handleSubmit, submitting, error } = this.props

    return (
      <Panel width="small">
        <form onSubmit={handleSubmit}>
          <Helmet title="Enter an OTP" />
          <h1>Enter an OTP</h1>
          <Field label="OTP" name="otp" component={TextField} autoFocus />
          <small>{error}</small>
          <Button type="submit" disabled={submitting || invalid} loading={submitting}>
            Submit
          </Button>
        </form>
      </Panel>
    )
  }
}
