// @flow
import * as React from "react"
import FileInput from "./File"
import s from "./ImageUploader.scss"
import Svg from "../Svg"
import Spinner from "../Spinner"
import Shiny from "../Shiny"
import hoverIcon from "../../assets/feather/upload.svg"

type Props = {
  value: ?string,
  upload: (file: File) => any,
  className?: string,
  defaultHeight?: number,
  minHeight?: number,
}

type State = {
  uploading: boolean,
  imageLoading: boolean,
  value: ?string,
}

export default class ImageUploader extends React.Component<Props, State> {
  static defaultProps = {
    minHeight: 0,
  }

  image: ?Image

  state = {
    uploading: false,
    imageLoading: false,
    value: this.props.value,
  }

  uploadComplete = () => this.setState(() => ({ uploading: false }))

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value && nextProps.value !== this.props.value) {
      this.preloadImage(nextProps.value)
    }
  }

  preloadImage(src: string) {
    this.setState(() => ({ imageLoading: true }))

    if (this.image) {
      this.image.onload = () => {}
    }

    this.image = new Image()
    this.image.onload = () => this.setState(() => ({ imageLoading: false, value: src }))
    this.image.src = src
  }

  onChange = (files: File[]) => {
    const file = files[0]

    if (!file) return

    this.setState(() => ({ uploading: true }))

    this.props.upload(files[0]).then(this.uploadComplete, this.uploadComplete)
  }

  render() {
    const { upload, value: propValue, className, defaultHeight, minHeight, ...props } = this.props
    const { value, imageLoading, uploading } = this.state

    return (
      <FileInput {...props} accept="image/*" onChange={this.onChange} className={className}>
        <div className={s.container} style={{ height: value ? null : defaultHeight }}>
          <Shiny shine={!(imageLoading || uploading)} className={s.shiny}>
            {value && <img src={value} style={{ minHeight }} alt="" />}
          </Shiny>
          {!uploading && (
            <div className={s.hover}>
              <Svg className={s.hoverIcon} icon={hoverIcon} />
            </div>
          )}
          {(uploading || imageLoading) && (
            <div className={s.loading}>
              <Spinner className={s.loadingIcon} />
            </div>
          )}
        </div>
      </FileInput>
    )
  }
}
