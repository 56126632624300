import { useQuery } from 'react-query'

const ORIGIN          = (new URL(process.env.API_ENDPOINT)).origin
const PUBLIC_BASE_URL = `${ORIGIN}/v1`

export const queryKey  = (username) => ['users', username]
export const fetchUser = (username) => fetch(`${PUBLIC_BASE_URL}/${queryKey(username).join('/')}`).then(res => res.json()).then(res => res.data)

export default function useUserQuery(username) {
  return useQuery(queryKey(username), () => fetchUser(username))
}
