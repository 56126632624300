// @flow
import * as React from "react"
import { EditorState } from "draft-js"
import decorateComponentWithProps from "decorate-component-with-props"

type GetEditorState = () => EditorState
type SetEditorState = (EditorState) => void

type DirectProps = {
  onClick: ({ getEditorState: GetEditorState, setEditorState: SetEditorState }) => any,
  className?: string,
  children?: React.Node,
}

type Props = DirectProps & {
  getEditorState: GetEditorState,
  setEditorState: SetEditorState,
}

export default class Button extends React.Component<Props> {
  onClick = (e: SyntheticEvent<*>) => {
    e.preventDefault()

    const { getEditorState, setEditorState } = this.props

    this.props.onClick({
      getEditorState,
      setEditorState,
    })
  }

  render() {
    const { className, children } = this.props

    return (
      <button className={className} onClick={this.onClick}>
        {children}
      </button>
    )
  }
}

export const buttonWithProps = (props: DirectProps) => decorateComponentWithProps(Button, props)
