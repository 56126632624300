// @flow
import * as React from 'react'
import type { FullCompetitionData } from 'store/reducers/fullCompetitions'
import { THEMES } from 'store/reducers/fullBlogPosts'
import { each, isPlainObject } from 'lodash'
import humanize from 'shared/utils/humanize'

import Helmet from 'react-helmet'

import { Field } from 'redux-form/immutable'
import _parameterize from 'parameterize'
import memoize from 'shared/utils/memoize'
import Button from 'components/Button'
import Panel from 'components/Panel'
import { Columns, Column } from 'components/Columns'
import Hr from 'components/Hr'
import { TextField, SelectField, TextFieldWithPrefix, RichEditorField } from 'components/fields'
import ImageUploader from 'components/inputs/ImageUploader'
import FormField from 'components/Field'
import { alert } from 'views/Prompts'

import s from './BlogPost.scss'

const FRONTEND_URL = process.env.FRONTEND_URL || ''

const parameterize = memoize(20)(_parameterize)
const normalize = (value) => parameterize(value)

type Props = {
  blogPost: FullCompetitionData,
  handleSubmit: () => Promise<any>,
  deleteBlogPost: () => Promise<any>,
  submitting: boolean,
  invalid: boolean,
  change: (string, any) => any,
  uploadImageAttachment: (File) => Promise<*>,
  publish: () => Promise<*>,
  unpublish: () => Promise<*>,
  uploadImage: (File) => Promise<*>,
}
export default class BlogPost extends React.Component<Props> {
  onTitleChange = (_event: any, value: string) => {
    this.props.change('url_title', parameterize(value))
  }

  publish = () => this.props.publish().catch(this.showPublicationErrors)

  showPublicationErrors = (e: any) => {
    if (isPlainObject(e)) {
      const message = []
      each(e, (error, field) => {
        message.push(`${humanize(field)} ${error}`)
      })

      alert(message.join('\n'))
    }
  }

  render() {
    const {
      blogPost,
      deleteBlogPost,
      handleSubmit,
      invalid,
      submitting,
      uploadImageAttachment,
      unpublish,
      uploadImage,
    } = this.props
    const { image } = blogPost

    return (
      <div>
        <Helmet title={blogPost.title} />
        <Panel>
          <form onSubmit={handleSubmit}>
            <Columns>
              <Column rigid>
                <Field name="theme" component={SelectField} options={THEMES} />
              </Column>
              <Column centered>
                <Field name="title" nomargin component={TextField} onChange={this.onTitleChange} />
                <Field
                  name="url_title"
                  component={TextFieldWithPrefix}
                  prefix={`${FRONTEND_URL}/blog/`}
                  normalize={normalize}
                  noborder
                  size="small"
                />
              </Column>
              <Column rigid>
                <Button
                  type="button"
                  promised
                  right
                  size="small"
                  kind={blogPost.published ? 'danger' : 'primary'}
                  outline={blogPost.published}
                  onClick={blogPost.published ? unpublish : this.publish}
                >
                  {blogPost.published ? 'Unpublish' : 'Publish'}
                </Button>
                {!blogPost.published && (
                  <Button type="button" size="small" kind="danger" onClick={deleteBlogPost} outline promised>
                    Delete
                  </Button>
                )}
              </Column>
            </Columns>
            <FormField label="Image">
              <ImageUploader
                value={image}
                upload={uploadImage}
                className={s.image}
                defaultHeight={200}
                minHeight={50}
              />
            </FormField>
            <Field
              name="intro"
              component={RichEditorField}
              uploadImageAttachment={uploadImageAttachment}
              nobox
              fullWidth
              nomargin
              placeholder="Intro…"
            />
            <Hr />
            <Field
              name="content"
              component={RichEditorField}
              uploadImageAttachment={uploadImageAttachment}
              nobox
              fullWidth
              placeholder="Content…"
            />
            <Button type="submit" disabled={submitting || invalid} loading={submitting}>
              Save
            </Button>
          </form>
        </Panel>
      </div>
    )
  }
}
