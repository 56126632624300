// @flow
import { handleActions, combineActions } from "redux-actions"
import * as API from "api"
import { createSelector } from "reselect"

import { GET_BLOG_POST, UPDATE_BLOG_POST, DELETE_BLOG_POST } from "store/actionTypes"

import type { ApiError, Action, ApiResponse } from "shared/store/types"

export const THEMES = [
  {
    label: 'Theme…',
    value: ''
  },
  {
    label: 'Career',
    value: 'Career',
  },
  {
    label: 'Getting started',
    value: 'Getting started',
  },
  {
    label: 'Data skills',
    value: 'Data skills',
  },
  {
    label: 'Meet the winners',
    value: 'Meet the winners',
  },
  {
    label: 'Technical',
    value: 'Technical',
  },
]


export type FullBlogPostData = {
  +id: string,
  +title: string,
  +url_title: string,
  +intro: {},
  +content: {},
  +published_at: number,
  +published: boolean,
  +created_at: number,
}

export type UpdateBlogPostData = {
  +title?: string,
  +url_title?: string,
  +intro?: {},
  +content?: {},
  +published?: boolean,
}

type State = {
  blogPosts: {
    [string]: FullBlogPostData,
  },
  requests: {
    [string]: {
      +data: ?(number[]),
      +loading: boolean,
      +error: ApiError | null,
    },
  },
}

type ApiBlogPostResponse = {
  ...FullBlogPostData,
}

type ApiResult = ApiResponse<ApiBlogPostResponse, {}, { id: string }>

export const defaultRequestData = {
  data: undefined,
  loading: false,
  error: null,
}

const initialState: State = {
  blogPosts: {},
  requests: {},
}

export default handleActions(
  {
    [GET_BLOG_POST.START]: (state: State, { payload: { id } }): State => ({
      ...state,
      requests: {
        ...state.requests,
        [id]: {
          ...defaultRequestData,
          loading: true,
        },
      },
    }),
    [GET_BLOG_POST.FAILURE]: (state: State, { payload: { id, error } }): State => ({
      ...state,
      requests: {
        ...state.requests,
        [id]: {
          ...defaultRequestData,
          loading: false,
          error,
        },
      },
    }),

    [combineActions(GET_BLOG_POST.SUCCESS, UPDATE_BLOG_POST.SUCCESS)]: (
      state: State,
      {
        payload: {
          id,
          result: { data },
        },
      }: ApiResult
    ): State => ({
      blogPosts: {
        ...state.blogPosts,
        [id]: data,
      },
      requests: {
        ...state.requests,
        [id]: {
          data: id,
          loading: false,
        },
      },
    }),

    [DELETE_BLOG_POST.SUCCESS]: (state: State, { payload: { id } }: ApiResult): State => ({
      ...state,
      blogPosts: {
        ...state.blogPosts,
        [id]: {
          ...state.blogPosts.id,
          deleted: true,
        },
      },
    }),
  },
  initialState
)

export const getBlogPost = (id: string): Action => ({
  types: GET_BLOG_POST,
  api: API.getBlogPost(id),
  payload: { id },
})

export const updateBlogPost = (id: string, values: UpdateBlogPostData): Action => ({
  types: UPDATE_BLOG_POST,
  api: API.updateBlogPost(id, values),
  payload: { id },
})

export const deleteBlogPost = (id: string): Action => ({
  types: DELETE_BLOG_POST,
  api: API.deleteBlogPost(id),
  payload: { id },
})

export const selectBlogPost = createSelector(
  [
    (state) => state.fullBlogPosts.requests,
    (state) => state.fullBlogPosts.blogPosts,
    (_, { blogPostId }) => blogPostId,
  ],
  (
    requests,
    blogPosts: { [string]: FullBlogPostData },
    blogPostId
  ): {
    blogPost: FullBlogPostData,
  } => {
    let blogPost = requests[blogPostId] || defaultRequestData

    if (blogPost.data) {
      const data = blogPosts[blogPost.data]

      blogPost = {
        ...blogPost,
        data,
      }
    }

    return { blogPost }
  }
)
