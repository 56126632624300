// @flow
import { map, isArray, find, reduce } from "lodash"

const EMAIL_REGEX = /^.+@.+$/

export const VALIDATIONS = {
  required: (value: string) => !value || value.toString().replace(/\s/g, "").length === 0,
  email: (value: string) => !EMAIL_REGEX.test(value),
  date: (value: string) => {
    if (!value) return false
    const [day, month, year] = map(value.split("/"), (s) => global.parseInt(s))

    if (Number.isNaN(day) || day < 0 || day > 31) return true
    if (Number.isNaN(month) || month < 0 || month > 12) return true
    if (Number.isNaN(year) || year < 1900 || year > 2100) return true

    return false
  },
}

export const MESSAGES = {
  required: () => "is required",
  email: () => "doesn't look like email",
  date: () => "doesn't look like date, format is dd/mm/yyyy",
}

export const validateField = (validation, field) => {
  if (isArray(validation)) {
    const [_validation, ...args] = validation

    if (VALIDATIONS[_validation](field, ...args)) {
      return MESSAGES[_validation](...args)
    }
  } else if (VALIDATIONS[validation](field)) {
    return MESSAGES[validation]()
  }

  return false
}

type Validations = {
  [string]: string[],
}

type Values = {
  [string]: any,
}

export default (validations: Validations) => (values: Values) =>
  reduce(
    validations,
    (errors, fieldValidations, field) => {
      let error = null
      find(fieldValidations, (validation) => {
        error = validateField(validation, values.get(field))
        return error
      })

      if (error) {
        errors[field] = error // eslint-disable-line no-param-reassign
      }

      return errors
    },
    {}
  )
