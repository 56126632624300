// @flow
import * as React from "react"
import c from "classnames"

import s from "./Panel.scss"

const Panel = ({
  className,
  nopadding,
  title,
  width,
  ...props
}: {
  className?: string,
  title?: React.Node,
  nopadding?: boolean,
  width: "full" | "medium" | "small",
}) => (
  <div className={c(s.container, s[`width-${width}`], className)}>
    {title && <div className={s.title}>{title}</div>}
    <div {...props} className={c(s.content, nopadding ? null : s.padding)} />
  </div>
)
Panel.defaultProps = {
  width: "full",
}

export default Panel
