// @flow
import * as React from "react"
import type { FullCompetitionData, PageData } from "store/reducers/fullCompetitions"

import Modal from "components/Modal"
import Button from "components/Button"
import { Tabs, TabPanel, TabButton } from "components/Tabs"
import { TabList, Tab } from "components/SortableTabs"
import { range, findIndex, map } from "lodash"
import { Columns, Column } from "components/Columns"

import Page from "containers/Competition/Page"
import NewPageForm from "containers/NewPageForm"

import s from "./Pages.scss"

type Props = {
  competition: FullCompetitionData,
  pages: PageData[],
  reorderPages: (string[]) => Promise<*>,
}
type State = {
  selectedTab: number,
  newPageOpen: boolean,
  orderedPages: string[],
}
export default class Pages extends React.PureComponent<Props, State> {
  getOrderedPages = (props?: Props) => range(0, (props || this.props).pages.length)

  state = {
    selectedTab: 0,
    newPageOpen: false,
    orderedPages: this.getOrderedPages(),
  }

  // TODO will be deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pages !== this.props.pages) {
      this.setState(() => ({ orderedPages: this.getOrderedPages(nextProps) }))
    }
  }

  openNewPage = () => this.setState(() => ({ newPageOpen: true }))
  closeNewPage = (id: ?number) => {
    this.setState(() => ({ newPageOpen: false }))

    if (id) {
      const index = findIndex(this.props.pages, (page) => page.id === id)

      if (index !== -1) {
        this.selectTab(index)
      }
    }
  }

  afterDelete = () => this.selectTab(0)

  selectTab = (index: number) => this.setState(() => ({ selectedTab: index }))

  renderTitle() {
    return (
      <Columns>
        <Column centered>Pages</Column>
        <Column rigid>
          <Button size="small" kind="primary" onClick={this.openNewPage}>
            Add
          </Button>
        </Column>
      </Columns>
    )
  }

  movePage = (from, to) => {
    this.setState(({ selectedTab, orderedPages }) => {
      const newPages = [...orderedPages]
      let newSelectedTab = selectedTab
      const temp = newPages[from]

      newPages[from] = newPages[to]
      newPages[to] = temp

      if (selectedTab === to) {
        newSelectedTab = from
      } else if (selectedTab === from) {
        newSelectedTab = to
      }

      return {
        orderedPages: newPages,
        selectedTab: newSelectedTab,
      }
    })
  }

  saveTabsOrder = () => {
    const { orderedPages } = this.state
    const { pages, reorderPages } = this.props
    const newPageIds = map(orderedPages, (pageIndex) => pages[pageIndex].id)
    reorderPages(newPageIds).then(() => this.setState(() => ({ orderedPages: this.getOrderedPages() })))
  }

  render() {
    const { competition, pages } = this.props
    const { orderedPages, selectedTab, newPageOpen } = this.state

    return (
      <div className={s.container}>
        <Tabs selectedIndex={selectedTab} onSelect={this.selectTab} kind="panel-secondary">
          <TabList>
            {map(orderedPages, (pageId, index) => (
              <Tab key={pages[pageId].id} moveTab={this.movePage} onDrop={this.saveTabsOrder} index={index}>
                {pages[pageId].title}
              </Tab>
            ))}
            <TabButton right size="small" onClick={this.openNewPage}>
              Add Page
            </TabButton>
          </TabList>
          <div className={s.pageForm}>
            {map(orderedPages, (pageId) => (
              <TabPanel key={pages[pageId].id}>
                <Page competition={competition} page={pages[pageId]} afterDelete={this.afterDelete} />
              </TabPanel>
            ))}
          </div>
        </Tabs>
        <Modal onRequestClose={this.closeNewPage} isOpen={newPageOpen}>
          <NewPageForm competition={competition} close={this.closeNewPage} />
        </Modal>
      </div>
    )
  }
}
