// @flow
import type { UserData } from 'store/reducers/users'
import { Link, type LocationShape } from 'react-router-dom'
import { compact } from 'lodash'
import * as React from 'react'
import Helmet from 'react-helmet'
import memoize from 'shared/utils/memoize'

import Panel from 'components/Panel'
import Button from 'components/Button'
import User from 'components/User'
import SearchInput from 'components/SearchInput'
import DataTable, { bindSortableHeaderToProps, renderers } from 'components/DataTable'
import Pagination from 'components/Pagination'

type Props = {
  users: ?(UserData[]),
  usersLoading: boolean,
  page: number,
  pages: ?number,
  getPageLink: (number) => LocationShape,
  sortBy: string,
  sortReversed: string,
  getSortLink: (string, ?boolean) => LocationShape,
  search?: string,
  setSearch: (string) => any,
  children?: React.Node,
  setBanned: (number, boolean) => Promise<any>,
}

type ExtraProps = {
  setBanned: (number, boolean) => Promise<*>,
}

const FRONTEND_URL = process.env.FRONTEND_URL || 'https://zindi.africa'

class Buttons extends React.PureComponent<{
  row: UserData,
  extraProps: ExtraProps,
}> {
  render() {
    const {
      row: { banned },
    } = this.props

    return (
      <React.Fragment>
        <Button size="small" kind={banned ? 'primary' : 'danger'} onClick={this.onClick} outline promised>
          {banned ? 'Enable' : 'Disable'}
        </Button>
      </React.Fragment>
    )
  }

  onClick = () => {
    const { row } = this.props
    const newBanned = !row.banned

    return this.props.extraProps.setBanned(row.id, newBanned)
  }
}

const COLUMNS = [
  {
    key: 'username',
    render: (username, { avatar }: UserData) => (
      <a href={`${FRONTEND_URL}/users/${username}`} target="_blank" rel="noopener noreferrer">
        <User username={username} avatar={avatar} />
      </a>
    ),
    width: 100,
    flexible: true,
  },
  {
    key: 'last_name',
    label: 'Name',
    width: 200,
    flexible: true,
    render: (last_name, { first_name }: UserData) => {
      return compact([first_name, last_name]).join(' ')
    },
  },
  {
    key: 'email',
    width: 200,
    flexible: true,
  },
  {
    key: 'score',
    render: (score, { rank }) => `${score} (#${rank})`,
    width: 100,
  },
  {
    key: 'created_at',
    render: renderers.date,
    width: 150,
  },
  {
    key: 'email_confirmed_at',
    label: 'Confirmed',
    render: renderers.bool,
    width: 60,
  },
  {
    key: 'banned',
    label: 'Banned',
    sortable: false,
    width: 80,
    align: 'right',
    render: (_, row, extraProps) => <Buttons row={row} extraProps={extraProps} />,
  },
  {
    key: 'edit',
    label: '',
    width: 60,
    render: (_, row) => <Link to={`/users/${row.username}/edit`}>Edit</Link>
  },
]

export default class Users extends React.PureComponent<Props> {
  cacheExtraProps = memoize(1)((props) => props)

  render() {
    const {
      users,
      usersLoading,
      page,
      pages,
      getPageLink,
      sortBy,
      sortReversed,
      getSortLink,
      search,
      setSearch,
      children,
      setBanned,
    } = this.props

    const SortableHeader = bindSortableHeaderToProps({
      sortBy,
      sortReversed,
      getSortLink,
    })

    const extraProps: ExtraProps = this.cacheExtraProps({ setBanned })

    return (
      <div>
        <Helmet title="Users" />
        <Panel nopadding>
          <SearchInput value={search} setValue={setSearch} />
        </Panel>
        <Panel nopadding>
          <DataTable
            keyColumn="id"
            data={users}
            columns={COLUMNS}
            headerComponent={SortableHeader}
            extraProps={extraProps}
            loading={usersLoading}
          />
        </Panel>
        <Pagination page={page} pages={pages} getPageLink={getPageLink} />
        {children}
      </div>
    )
  }
}
