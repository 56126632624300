import { capitalize } from "lodash"

const BASE_URL = process.env.API_ENDPOINT

const extractAuthToken = () => {
  const raw_cookie = localStorage.getItem('persist:primary')
  const cookie     = JSON.parse(raw_cookie).cookie

  return JSON.parse(cookie).auth_token
}

export default function updateUser(userId, attributes) {
  return fetch(`${BASE_URL}/users/${userId}`, {
    method: 'PATCH',
    headers: {
      'Auth-Token': extractAuthToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(attributes)
  }).then(res => res.json())
    .then(res => {
      const payload = res.data

      if (res.ok || payload.errors === undefined) return payload

      throw Object.entries(payload.errors).reduce((acc, e, index, arr) => {
        const error = e.join(' ')

        if (acc === '')                      return error
        if (e[0] !== arr[arr.length - 1][0]) return capitalize(`${acc}, ${error}`)

        return capitalize(`${acc} and ${error}`)
      }, '')
    })
}
