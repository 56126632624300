// @flow
import { reduxForm, formValueSelector } from 'redux-form/immutable'
import { pick, isNil } from 'lodash'
import { fromJS } from 'immutable'
import { connect } from 'react-redux'

import { autosuggest } from 'store/reducers/misc'
import { updateCompetition } from 'store/reducers/fullCompetitions'
import { withPropsOnChange, compose } from 'recompose'
import validate from 'shared/utils/validate'
import handleFormErrors from 'shared/utils/handleFormErrors'
import showSavingErrors from 'utils/showSavingErrors'

import BasicInfoForm from 'views/Competition/BasicInfoForm'

const selector = formValueSelector('competition')

export default compose(
  connect(
    (state) => ({
      whitelist_only: selector(state, 'whitelist_only'),
      limit_number_of_participants: selector(state, 'limit_number_of_participants'),
      secret_code_required: selector(state, 'secret_code_required'),
      end_time_string: selector(state, 'end_time_string'),
    }),
    (dispatch, props) => ({
      uploadImage: (file: File) =>
        dispatch(updateCompetition(props.competition.id, { image: file })).catch(showSavingErrors),
      suggestCompetition: (query) => dispatch(autosuggest('competition', query)),
      suggestCountry: (query) => dispatch(autosuggest('country', query)),
      suggestCity: (query) => dispatch(autosuggest('city', query)),
      suggestOrganization: (query) => dispatch(autosuggest('organization', query)),
      suggestIndustry: (query) => dispatch(autosuggest('industry', query)),
      suggestTypeOfProblem: (query) => dispatch(autosuggest('type_of_problem', query)),
      suggestDataType: (query) => dispatch(autosuggest('data_type', query)),
      suggestImpact: (query) => dispatch(autosuggest('impact', query)),
      suggestDifficulty: (query) => dispatch(autosuggest('difficulty', query)),
      suggestSkills: (query) => dispatch(autosuggest('skills', query)),
      suggestTopics: (query) => dispatch(autosuggest('topics', query)),
      suggestHelping: (query) => dispatch(autosuggest('helping', query)),
    }),
  ),
  withPropsOnChange(['competition'], ({ competition }) => ({
    initialValues: fromJS({
      ...pick(competition, [
        'kind',
        'title',
        'url_title',
        'subtitle',
        'reward',
        'error_metric',
        'points_reward',
        'reward_type',
        'allow_teams',
        'whitelist_only',
        'visible_to_whitelist_only',
        'start_minutes_string',
        'end_minutes_string',
        'reveal_minutes_string',
        'reveal_minutes_string',
        'country',
        'city',
        'organization',
        'organization_url',
        'visible_time_string',
        'start_time_string',
        'end_time_string',
        'reveal_time_string',
        'show_submission_count',
        'industry',
        'type_of_problem',
        'data_type',
        'impact',
        'difficulty',
        'skills',
        'topics',
        'helping',
        'secret_code',
        'secret_code_required',
        'discussions_are_private',
        'max_number_of_participants',
        'max_number_of_participants_per_team',
        'associated_ambassadors'
      ]),
      limit_number_of_participants: !isNil(competition.max_number_of_participants),
      participants_whitelist: competition.participants_whitelist.join('\n'), // eslint-disable-line quotes
      parent: competition.parent && {
        id: competition.parent.id,
        value: competition.parent.title,
      },
    }),
  })),
  reduxForm({
    form: 'competition',
    enableReinitialize: true,
    validate: validate({
      title: ['required'],
      url_title: ['required'],
      // TODO validate
      // start_time_string: ['datetime'],
      // end_time_string: ['datetime'],
      // end_time_string: ['datetime']
    }),
    onSubmit: (_values, dispatch, props) => {
      const values = _values.toJSON()
      values.parent_id = values.parent && values.parent.id
      delete values.parent

      if (!values.limit_number_of_participants) {
        values.max_number_of_participants = ''
      }

      if (!values.whitelist_only) {
        values.visible_to_whitelist_only = false
      }

      const request = dispatch(updateCompetition(props.competition.id, values))

      // TODO
      if (props.competition.published) {
        return request.catch(showSavingErrors)
      }

      return request.catch(handleFormErrors)
    },
  }),
)(BasicInfoForm)
