// @flow
import { createJob } from "store/reducers/fullJobs"
import { reduxForm } from "redux-form/immutable"

import { compose } from "recompose"
import validate from "shared/utils/validate"
import handleFormErrors from "shared/utils/handleFormErrors"

import NewJob from "views/NewJob"

export default compose(
  reduxForm({
    form: "newJob",
    validate: validate({
      title: ["required"],
      url_title: ["required"],
    }),
    onSubmit: (values, dispatch) => dispatch(createJob(values.toJSON())).catch(handleFormErrors),
    onSubmitSuccess: (result, _dispatch, props) => props.history.push(`/jobs/${result.data.id}`),
  })
)(NewJob)
