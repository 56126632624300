// @flow
import type { UserData } from 'store/reducers/me'
import * as React from 'react'
import Helmet from 'react-helmet'

import Header from 'views/Header'

import s from './Layout.scss'

export const CONTENT_ID = 'zindi-content'

require('../global.scss')

type Props = {|
  children: React.Node,
  me: UserData,
  signOut: () => Promise<any>,
|}

export default class Layout extends React.PureComponent<Props> {
  render() {
    const { me, children, signOut } = this.props

    return (
      <div className={s.container}>
        <Helmet defaultTitle="Zindi Admin" titleTemplate="%s — Zindi Admin" />
        <div className={s.sidebar}>
          <div className={s.sidebarInner}>
            <Header me={me} signOut={signOut} />
          </div>
        </div>
        <div id={CONTENT_ID} className={s.content}>
          {children}
        </div>
      </div>
    )
  }
}
