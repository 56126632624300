// @flow
import createNewBlockToolbar from "../plugins/newBlockToolbar"
import Configuration from "../configuration"

import defaultStyles from "../styles/newBlockToolbar.scss"
import mergeStyles from "../utils/mergeStyles"

type Config = {
  styles?: {},
}

export default (config: Config) => ({ addPlugin, addComponent, newBlockButtons }: Configuration) => {
  const styles = mergeStyles(defaultStyles, config.styles)

  const newBlockToolbar = createNewBlockToolbar({
    structure: newBlockButtons,
    theme: styles,
  })
  addPlugin(newBlockToolbar)
  addComponent(newBlockToolbar.NewBlockToolbar)
}
