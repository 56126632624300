// @flow
import { getBlogPost, updateBlogPost, selectBlogPost, deleteBlogPost } from "store/reducers/fullBlogPosts"
import { connect } from "react-redux"
import { reduxForm } from "redux-form/immutable"
import { withPropsOnChange, compose, branch, renderNothing } from "recompose"

import { uploadImageAttachment } from "store/reducers/misc"
import validate from "shared/utils/validate"
import handleFormErrors from "shared/utils/handleFormErrors"
import preload from "decorators/preload"

import { confirm } from "views/Prompts"
import { serialize, parse } from "components/inputs/RichEditor"

import BlogPost from "views/BlogPost"

export default compose(
  withPropsOnChange(["match"], ({ match: { params: { id, tab } } }) => ({
    blogPostId: id,
    selectedTab: tab,
  })),
  connect(
    (state, props) => ({
      ...selectBlogPost(state, props),
    }),
    (dispatch, props) => ({
      getBlogPost: () => dispatch(getBlogPost(props.blogPostId)),
      deleteBlogPost: () =>
        confirm("Delete post?")
          .then(() => dispatch(deleteBlogPost(props.blogPostId)))
          .then(() => props.history.push("/blog")),
      publish: () => dispatch(updateBlogPost(props.blogPostId, { published: true })),
      unpublish: () => dispatch(updateBlogPost(props.blogPostId, { published: false })),
      uploadImage: (file: File) => dispatch(updateBlogPost(props.blogPostId, { image: file })),
    })
  ),
  preload({
    blogPost: {
      load: "getBlogPost",
      optional: false,
    },
  }),
  branch((props) => props.blogPost.deleted, renderNothing),
  connect(
    () => ({}),
    (dispatch, props) => ({
      uploadImageAttachment: (image) => dispatch(uploadImageAttachment("BlogPost", props.blogPost.id, image)),
    })
  ),
  withPropsOnChange(["blogPost"], ({ blogPost }) => ({
    initialValues: {
      ...blogPost,
      intro: parse(blogPost.intro),
      content: parse(blogPost.content),
    },
  })),
  reduxForm({
    form: "blogPost",
    enableReinitialize: true,
    validate: validate({
      title: ["required"],
      url_title: ["required"],
    }),
    onSubmit: (values, dispatch, props) =>
      dispatch(
        updateBlogPost(
          props.blogPost.id,
          values
            .set("intro", serialize(values.get("intro")))
            .set("content", serialize(values.get("content")))
            .toJSON()
        )
      ).catch(handleFormErrors),
  })
)(BlogPost)
